import { useStyletron } from 'baseui';
import { Block } from 'baseui/block';
import { DisplayMedium, LabelLarge } from 'baseui/typography';
import React from 'react';
import { Statistics } from '../../../types/about-us';

declare type StatisticsSectionProps = {
  data: Statistics[];
};

const StatisticsSection: React.FC<StatisticsSectionProps> = ({ data }) => {
  const [css, theme] = useStyletron();

  return (
    <Block
      display="flex"
      flexDirection={['column', 'column', 'column', 'row']}
      className={css({
        fontFamily: 'Raleway',
      })}
      marginTop="scale1200"
    >
      {data.map((item, index) => (
        <Block
          key={index}
          marginLeft="scale1200"
          marginRight="scale1200"
          marginBottom={['scale650', 'scale650', 'scale650', '0']}
          alignItems="center"
          justifyContent="center"
          display="flex"
          flexDirection="column"
          className={css({
            fontFamily: 'Raleway',
            fontFeatureSettings: `'pnum' on, 'lnum' on`,
          })}
        >
          <DisplayMedium
            color={theme.colors.accent}
            className={css({
              fontFamily: 'Raleway',
              fontFeatureSettings: `'pnum' on, 'lnum' on`,
            })}
          >
            {item.Value}
          </DisplayMedium>
          <LabelLarge
            className={css({
              fontFamily: 'Raleway',
              fontFeatureSettings: `'pnum' on, 'lnum' on`,
            })}
          >
            {item.Name}
          </LabelLarge>
        </Block>
      ))}
    </Block>
  );
};

export default StatisticsSection;

