import { useStyletron } from 'baseui';
import { Block } from 'baseui/block';
import { MonoHeadingXXLarge, ParagraphLarge } from 'baseui/typography';
import React from 'react';
import {
  mainHeadingDesktopStyles,
  mainHeadingMobileStyles,
  secondHeadingMobileStyles,
} from '../../utils/typpography-utils';
import BitterMonoHeadingXLarge from '../typography/bitter-mono-heading-x-large';
import MonoHeadingXlargeMobile from '../typography/mono-heading-x-large-mobile';
import { GetGuideSectionProps } from './get-guide-section';

const Headings: React.FC<GetGuideSectionProps> = ({ data }) => {
  const [css, theme] = useStyletron();

  return (
    <>
      <Block
        flex={0}
        alignItems="center"
        justifyContent="center"
        className={css({ textAlign: 'center' })}
        display={['none', 'none', 'none', 'flex']}
        flexDirection="column"
      >
        <MonoHeadingXXLarge
          marginTop={['scale300', 'scale600', 'scale1400']}
          marginBottom={['scale300', 'scale600']}
          color={theme.colors.contentInversePrimary}
          overrides={{ ...mainHeadingDesktopStyles }}
        >
          {data.Heading.toUpperCase()}
        </MonoHeadingXXLarge>
        <BitterMonoHeadingXLarge
          marginTop={['scale300', 'scale300', 'scale600']}
          marginBottom={['scale300', 'scale300', 'scale600']}
          color={theme.colors.contentInversePrimary}
        >
          {data.H1}
        </BitterMonoHeadingXLarge>
        <ParagraphLarge
          overrides={{ ...mainHeadingDesktopStyles }}
          marginTop={0}
          width={['100%', '100%', '100%', '60%']}
          color={theme.colors.contentInversePrimary}
        >
          {data.H2}
        </ParagraphLarge>
      </Block>

      <Block
        flex={0}
        className={css({ textAlign: 'center' })}
        display={['block', 'block', 'block', 'none']}
      >
        <MonoHeadingXXLarge
          marginTop={['scale300', 'scale600', 'scale1400']}
          marginBottom={['scale300', 'scale600']}
          overrides={{ ...mainHeadingMobileStyles }}
          color={theme.colors.contentInversePrimary}
        >
          {data.Heading}
        </MonoHeadingXXLarge>
        <MonoHeadingXlargeMobile
          overrides={{ ...secondHeadingMobileStyles }}
          color={theme.colors.contentInversePrimary}
        >
          {data.H1}
        </MonoHeadingXlargeMobile>
      </Block>
    </>
  );
};

export default Headings;
