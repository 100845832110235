import { useStyletron } from 'baseui';
import { Block } from 'baseui/block';
import React, { useState } from 'react';
import { useWindow } from '../../context/use-window';
import theme from '../../page-layouts/theme';
import { Reviews as ReviewsData } from '../../types/projects';
import { fullScreenCarouselResponsiveBreakPoints } from '../../utils/carousel-util';
import { getMediaUrl } from '../../utils/get-media-url';
import { ReactMultiCarousel } from '../carousel/react-multi-carousel';
import Headings from './headings';
import './styles/styles.css';
type ReviewsProps = {
  data: ReviewsData;
};

const Reviews: React.FC<ReviewsProps> = ({ data }) => {
  const [css] = useStyletron();
  const [currentSlide, setCurrentSlide] = useState(0);
  const { deviceType } = useWindow();
  const isDesktop = deviceType === 'desktop';

  const handleSlideChange = (previousSlide: number, state: any) => {
    if (!isDesktop) return;
    const { currentSlide } = state || {};
    setCurrentSlide(currentSlide - 2);
  };

  const getCardScale = (index: number) => {
    if (!isDesktop) return 0.95;
    return index === currentSlide ? 1 : 0.8;
  };

  const CustomDot = ({ index, onClick, active }: any) => {
    return (
      <Block
        position={'relative'}
        onClick={(e: any) => {
          onClick();
          e.preventDefault();
        }}
      >
        <Block
          margin={'scale0'}
          height={'scale200'}
          width={'scale600'}
          backgroundColor={active ? theme.colors.black : theme.colors.mono600}
        />
      </Block>
    );
  };

  return (
    <Block display={'flex'} flexDirection={'column'} alignItems={'center'}>
      <Headings h1={data.H1} h2={data.H2} />
      <Block
        width="80%"
        overflow={'visible'}
        height={['250px', '250px', '300px', '700px']}
        marginTop={'scale1600'}
      >
        <ReactMultiCarousel
          arrows={true}
          infinite
          showDots
          centerMode={isDesktop}
          customDot={<CustomDot />}
          afterChange={handleSlideChange}
          responsive={fullScreenCarouselResponsiveBreakPoints}
          className="review-carousel"
        >
          {data.Reviews.data.map((item, index) => (
            <Block marginBottom="scale800">
              <img
                width="100%"
                height={'100%'}
                src={getMediaUrl(item.attributes.url)}
                className={css({
                  pointerEvents: 'none',
                  transform: `scale(${getCardScale(index)})`,
                  maxWidth: '650px',
                })}
                alt="reviewCard"
              />
            </Block>
          ))}
        </ReactMultiCarousel>
      </Block>
    </Block>
  );
};

export default Reviews;
