import * as React from 'react';
import { SEO } from '../components';
import PageLayout from '../page-layouts';
import NavBar from '../components/nav-bar/nav-bar';
import { Homepage } from '../types/homepage';
import { HomePageServiceImpl } from '../services/page/home-page.service';
import { ReferralPage } from '../types/referral';
import { ReferralPageBanner } from '../components/banner/referral-page-banner/referral-page-banner';
import FooterSection from '../components/footer';
import { Block } from 'baseui/block';
import theme from '../page-layouts/theme';
import ReferralPageContent from '../components/referral-page';
import { ReferralPageServiceImpl } from '../services/page/referral-page.service';

declare type IndexPageProps = {
  serverData: any;
};

const Referrals: React.FC<IndexPageProps> = ({ serverData }) => {
  const homepage = serverData.homePageData as Homepage;
  const referralPageData = serverData.referralPageData as ReferralPage;

  return (
    <PageLayout>
      <NavBar position={'fixed'} data={homepage.data.attributes.Header} />
      {referralPageData.data.attributes.seo && <SEO data={referralPageData.data.attributes.seo} />}
      <ReferralPageBanner banner={referralPageData.data.attributes.Banner} />
      <Block backgroundColor={theme.colors.backgroundPrimary} width="100%">
        <ReferralPageContent
          description={referralPageData.data.attributes.Description}
          Images={referralPageData.data.attributes.Illustrations}
        />
      </Block>
      <FooterSection data={homepage.data.attributes.Footer} />
    </PageLayout>
  );
};

export const getServerData = async () => {
  const homeServiceObject = new HomePageServiceImpl();
  const referralPageServiceObject = new ReferralPageServiceImpl();
  const res = await Promise.all([
    referralPageServiceObject.getreferralPage(),
    homeServiceObject.getCommonData(),
  ]);

  return {
    props: {
      referralPageData: res[0].data,
      homePageData: res[1].data,
    },
  };
};

export default Referrals;
