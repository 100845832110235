import React, { useEffect } from 'react';
import { useQueryParam } from '../../context/use-query-string';
import { useWindow } from '../../context/use-window';
import { Hero } from '../banner/home-page-banner';
import FooterSection from '../footer';
import { GetGuideSection } from '../get-guide-section/get-guide-section';
import { GoSolarNow } from '../go-solar-now/go-solar-now';
import NavBar from '../nav-bar/nav-bar';
import QuotationDesktop from '../quotation/desktop/quotation-desktop';
import ReviewsAndAwards from '../reviews-and-awards/reviews-and-awards';
import { WhySunnyEnergy } from '../why-sunny-energy/why-sunny-energy';
import { HomepageProps } from './homepage';
import DiscountOfferPopup from '../discount-offer-popup/discount-offer-popup';

const DesktopHomepage: React.FC<HomepageProps> = ({ homepage }) => {
  const scrollTo = useQueryParam('scrollTo');

  const { currentWindow } = useWindow();
  const { windowDimensions } = useWindow();

  const isDesktop = windowDimensions?.width >= 425;

  const handleScrollToView = () => {
    var element = document.getElementById(scrollTo);
    currentWindow?.scrollTo(0, currentWindow.innerHeight + 100);
    if (element && scrollTo === 'quotation') {
      console.log(document.querySelectorAll(`#main-view > div`));
      document.querySelectorAll(`#main-view > div`)?.[1]?.scrollIntoView({
        behavior: 'smooth',
      });
    }
  };

  useEffect(() => {
    if (currentWindow) {
      if (scrollTo) {
        handleScrollToView();
      }
    }
  }, [currentWindow]);

  return (
    <>
      {isDesktop && (
        <DiscountOfferPopup
          data={homepage.data.attributes.InformativeBanner || []}
        />
      )}
      <NavBar data={homepage.data.attributes.Header} isHome={true} />
      <Hero data={homepage.data.attributes.Banners} />

      <QuotationDesktop data={homepage.data.attributes.Quote} />

      <WhySunnyEnergy data={homepage.data.attributes.LearnMoreSection} />

      <ReviewsAndAwards
        awards={homepage.data.attributes.Awards}
        reviews={homepage.data.attributes.Reviews}
      />

      <GoSolarNow data={homepage.data.attributes.GoSolarNowSection} />

      {/* Temperory removal of the animation sections - should add once approved. */}
      {/* {homepage.data.attributes.Animations.map(animation => (
        <SectionSnapY display={'none'} key={animation.id}>
          <VideoAnimation data={animation} key={`${animation.id}`} />
        </SectionSnapY>
      ))} */}

      <GetGuideSection data={homepage.data.attributes.GetSolarGuideSection} />
      <FooterSection data={homepage.data.attributes.Footer} />
    </>
  );
};

export default DesktopHomepage;
