import { AspectRatioBox, AspectRatioBoxBody } from 'baseui/aspect-ratio-box';
import { Block } from 'baseui/block';
import React from 'react';
import { Logo } from '../../../types/about-us';
import { LogoCarouselResponsiveBreakPoints } from '../../../utils/carousel-util';
import { getMediaUrl } from '../../../utils/get-media-url';
import Carousel from '../../carousel/carousel';

declare type PartnerCarouselProps = {
  logos: Logo[];
};

const PartnerCarousel: React.FC<PartnerCarouselProps> = ({ logos }) => {
  return (
    <Carousel
      arrows={false}
      autoPlay={true}
      autoPlaySpeed={3000}
      responsive={LogoCarouselResponsiveBreakPoints}
      infinite={true}
    >
      {logos.map(item => (
        <Block
          key={item.id}
          maxHeight={'180px'}
          maxWidth={'180px'}
          marginLeft="scale1200"
          marginRight="scale1200"
        >
          <AspectRatioBox aspectRatio={1 / 1}>
            <AspectRatioBoxBody
              as="img"
              alt="carousel-logo"
              src={getMediaUrl(item.Logo.data.attributes.url)}
              width={['auto']}
              overrides={{
                Block: {
                  style: {
                    height: '100%',
                    width: '100%',
                    objectFit: 'contain',
                  },
                },
              }}
            />
          </AspectRatioBox>
        </Block>
      ))}
    </Carousel>
  );
};

export default PartnerCarousel;

