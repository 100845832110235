import { useStyletron } from 'baseui';
import { Block } from 'baseui/block';
import React from 'react';
import { useWindow } from '../../context/use-window';
import Markdown from '../mark-down/mark-down';

declare type PrivacyPolicyContentProps = {
  data: string;
};

const PrivacyPolicyContent: React.FC<PrivacyPolicyContentProps> = ({
  data,
}) => {
  const { deviceType } = useWindow();
  const [, theme] = useStyletron();
  return (
    <Block
      width="100%"
      backgroundColor={theme.colors.backgroundPrimary}
      display="flex"
      alignContent="center"
      justifyContent={'center'}
      paddingTop="scale2400"
      paddingBottom="scale2400"
    >
      <Block width={['90%', '90%', '70%', '50%']}>
        <Markdown
          data={data}
          lineHeight={
            deviceType === 'desktop'
              ? theme.sizing.scale900
              : theme.sizing.scale850
          }
          fontSize={
            deviceType === 'desktop'
              ? theme.sizing.scale600
              : theme.sizing.scale550
          }
        />
      </Block>
    </Block>
  );
};

export default PrivacyPolicyContent;
