export enum FILE_TYPE {
  IMAGE,
  VIDEO,
  UNKNOWN,
}

export const getMediatype = (url: string): FILE_TYPE => {
  const ext = url.substring(url.lastIndexOf('.') + 1);

  if (
    ['png', 'jpeg', 'jpg', 'gif', 'apng', 'avif', 'svg', 'webp'].includes(ext)
  ) {
    return FILE_TYPE.IMAGE;
  }
  if (['mp4', 'mov', '3gp', 'm3u8', 'avi', 'wmv'].includes(ext)) {
    return FILE_TYPE.VIDEO;
  }

  return FILE_TYPE.UNKNOWN;
};
