import { Location } from "../../../types/homepage";

export const locations: Location[] = [{
    location: 'AZ',
    utilityCompany: 'APS',
    utilityCompanyId: '60c82968d6717f3b8dff8ecf',
  },
  {
    location: 'AZ',
    utilityCompany: 'SRP',
    utilityCompanyId: '60c82968d6717f3b8dff8edb',
  },
  {
    location: 'AZ',
    utilityCompany: 'TEP',
    utilityCompanyId: '60c82968d6717f3b8dff8ed2',
  },
  {
    location: 'AZ',
    utilityCompany: 'ED3',
    utilityCompanyId: '64be1fae811c869a9e071865',
  },
  {
    location: 'AZ',
    utilityCompany: 'Others',
    utilityCompanyId: '64c8e67346011aa69acae556',
  }];