import { useStyletron } from 'baseui';
import { Block } from 'baseui/block';
import React from 'react';

declare type ImageCardProps = {
  url: string;
};

const Imagecard: React.FC<ImageCardProps> = ({ url }) => {
  const [css, theme] = useStyletron();

  return (
    <Block
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      marginBottom="scale650"
      width={['90%', '90%', '100%', '100%']}
      marginTop="scale900"
    >
      <img
        alt="referralImage"
        src={url}
        width='auto'
        className={css({
          aspectRatio: '1/1',
          borderRadius: theme.sizing.scale400,
          maxHeight: '250px',
        })}
      />
    </Block>
  );
};

export default Imagecard;

