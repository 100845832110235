import React, { Dispatch, useState } from 'react';
import { Params, Slider } from 'baseui/slider';
import theme from '../../page-layouts/theme';

type NumberPickerProps = {
  defaultValue: number;
  max?: number;
  min?: number;
  onChange: Dispatch<number>;
};

const NumberPicker: React.FC<NumberPickerProps> = ({
  defaultValue,
  max = 100,
  min = 0,
  onChange,
}) => {
  const [value, setValue] = useState([defaultValue]);

  const handleChange = ({ value }: Params) => {
    setValue(value);
    onChange(value[0]);
  };

  return (
    <Slider
      overrides={{
        ThumbValue: () => null, // Hide the default tick bar
        InnerThumb: () => null, // Hide the default tick bar
        TickBar: () => null, // Hide the default tick bar
        Thumb: {
          style: {
            backgroundColor: theme.colors.accent, // Change the color here
          },
        },
        Track: {
          style: {
            color: theme.colors.accent, // Change the color here
          },
        },
        InnerTrack: {
          style: {
            backgroundColor: theme.colors.accent, // Change the color here
          },
        },
      }}
      value={value}
      onChange={handleChange}
      min={min}
      max={max}
      step={1}
    />
  );
};

export default NumberPicker;
