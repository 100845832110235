import { useStyletron } from 'baseui';
import { AspectRatioBox, AspectRatioBoxBody } from 'baseui/aspect-ratio-box';
import { Block } from 'baseui/block';

import { FlexGrid, FlexGridItem } from 'baseui/flex-grid';
import { LabelMedium, ParagraphLarge } from 'baseui/typography';
import { navigate } from 'gatsby';
import React, { useState } from 'react';
import theme from '../../page-layouts/theme';
import { Installation } from '../../types/projects';
import { getMediaUrl } from '../../utils/get-media-url';
import Button from '../button/button';
import VimeoPlayer from '../video-library/vimeo-player';
import Headings from './headings';
import BiPlayCircle from '../icons/bi-play-circle';

type GoSolarProps = {
  data: Installation;
};

const GoSolar: React.FC<GoSolarProps> = ({ data }) => {
  const [css] = useStyletron();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleVideoButtonClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <Block
        padding={['scale600', 'scale600', 'scale600', 'scale2400']}
        paddingBottom="scale1200"
        paddingTop="scale1200"
        display={'flex'}
        flexDirection="column"
        alignItems={'center'}
        backgroundImage={[
          '',
          '',
          '',
          `url(${getMediaUrl(data.Background.data.attributes.url)})`,
        ]}
        position={'relative'}
        backgroundSize="cover"
        backgroundPosition="center"
      >
        <FlexGrid
          flexGridColumnCount={[1, 1, 1, 2]}
          marginBottom={'scale900'}
          alignItems="center"
        >
          <FlexGridItem>
            <Block
              display={'flex'}
              alignItems={['center', 'center', 'center', 'flex-start']}
              justifyContent={['center', 'center', 'center', 'flex-start']}
              flexDirection="column"
              marginRight={['scale800', 'scale1200', 'scale1200', 'scale3200']}
              marginTop={['scale1200', 'scale1200', 'scale1200', '0px']}
            >
              <Block display={['block', 'block', 'block', 'none']}>
                <Headings h1={data.H1} h2={data.H2} />
              </Block>
              <Block display={['none', 'none', 'none', 'block']}>
                <Headings centered={false} h1={data.H1} h2={data.H2} />
              </Block>
              <ParagraphLarge
                marginBottom={'scale900'}
                display={['none', 'none', 'none', 'block']}
              >
                {data.Description}
              </ParagraphLarge>
              <Block display={['none', 'none', 'none', 'block']}>
                <Button onClick={() => setIsOpen(!isOpen)} kind="primary">
                  {data.Link?.Text}
                </Button>
              </Block>
            </Block>
          </FlexGridItem>

          <FlexGridItem marginTop={['scale750', 'scale750', 'scale750', '0px']}>
            <Block position="relative">
              <AspectRatioBox aspectRatio={3 / 2}>
                <AspectRatioBoxBody
                  as="img"
                  alt="solar-img"
                  className={css({
                    height: '100%',
                    width: '100%',
                    borderRadius: theme.sizing.scale700,
                  })}
                  src={
                    data.VideoThumbnail
                      ? data.VideoThumbnail &&
                        getMediaUrl(data.VideoThumbnail.data.attributes.url)
                      : getMediaUrl(data.Video.Thumbnail.data.attributes.url)
                  }
                ></AspectRatioBoxBody>
              </AspectRatioBox>
              <Block
                className={css({
                  position: 'absolute',
                  top: ' 50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  ':hover': {
                    backgroundColor: 'none',
                  },
                  ':active': {
                    background: 'transparent',
                    outline: 'none',
                    border: 'none',
                  },
                })}
                onClick={handleVideoButtonClick}
              >
                <BiPlayCircle color={theme.colors.contentPrimary} size={80} />
              </Block>
              {isOpen && (
                <VimeoPlayer
                  video={data.vimeoId ? data.vimeoId : data.Video.VimeoId}
                  isOpen={isOpen}
                  setIsOpen={handleVideoButtonClick}
                />
              )}
            </Block>
            <LabelMedium
              marginTop={'scale900'}
              marginBottom={'scale900'}
              display={['block', 'block', 'block', 'none']}
            >
              {data.Description}
            </LabelMedium>

            <Block
              display={['block', 'block', 'block', 'none']}
              className={css({ textAlign: 'center' })}
            >
              <Button
                kind="primary"
                onClick={() => navigate(`${data.Link.Url}`)}
              >
                {data.Link?.Text}
              </Button>
            </Block>
          </FlexGridItem>
        </FlexGrid>
      </Block>
      <Block paddingBottom={data.H3 ? "scale1200" : "0"}>
        {data.H3 && <Headings h1={data.H3} h2={data.H4} />}
      </Block>

      {}
    </>
  );
};

export default GoSolar;

