import { Block } from 'baseui/block';
import React from 'react';
import { useStyletron } from 'baseui';
import { Card as CardType } from '../../types/solar-tech';
import { ProductCardCarouselResponsiveBreakPoints } from '../../utils/carousel-util';
import { ReactMultiCarousel } from '../carousel/react-multi-carousel';
import CategoryCard from './category-card';
import './styles/styles.css';

declare type CategoryProductProps = {
  cards: CardType[];
};

const CategoryProduct: React.FC<CategoryProductProps> = ({ cards }) => {
  const [, theme] = useStyletron();

  const CustomDot = ({ onClick, active }: any) => {
    return (
      <Block
        position={'relative'}
        onClick={(e: any) => {
          onClick();
          e.preventDefault();
        }}
      >
        <Block
          margin={'scale0'}
          height={'scale200'}
          width={'scale600'}
          backgroundColor={
            active ? theme.colors.accent : theme.colors.backgroundSecondary
          }
        />
      </Block>
    );
  };

  return (
    <ReactMultiCarousel
      arrows={true}
      showDots
      responsive={ProductCardCarouselResponsiveBreakPoints}
      className="product-card-carousel"
      customDot={<CustomDot />}
    >
      {cards.map((card, index) => (
        <CategoryCard key={index} data={card} />
      ))}
    </ReactMultiCarousel>
  );
};

export default CategoryProduct;

