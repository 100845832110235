import * as React from 'react';
import { FormControl, FormControlOverrides } from 'baseui/form-control';
import { Input, MaskedInput } from 'baseui/input';
import { Block } from 'baseui/block';
import { Checkbox } from 'baseui/checkbox';
import { LabelMedium } from 'baseui/typography';
import {
  checkboxOverrides,
  inputOverrides,
} from '../../utils/form-util';

import { FormikProps } from 'formik';

const formControlOverrides: FormControlOverrides = {
  Label: {
    style: ({ $theme }) => ({
      color: $theme.colors.contentInversePrimary,
    }),
  },
};

export declare type GetGuideControlsItem = {
  batteryGuide: boolean;
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  solarGuide: boolean;
};

type GuideSectionFormProps = {
  formik: FormikProps<GetGuideControlsItem>;
};

const GuideSectionForm: React.FC<GuideSectionFormProps> = ({ formik }) => {
  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Block>
          <Block
            display={'flex'}
            flexDirection={['column', 'column', 'column', 'row']}
          >
            <Block flex={'1'}>
              <FormControl
                label="First name"
                error={formik.touched.firstName && formik.errors.firstName}
                overrides={formControlOverrides}
              >
                <Input
                  name="firstName"
                  type="text"
                  value={formik.values.firstName}
                  onChange={formik.handleChange}
                  placeholder="First name"
                  overrides={inputOverrides}
                />
              </FormControl>
            </Block>
            <Block marginLeft={['0', '0', '0', 'scale900']} flex={'1'}>
              <FormControl
                label="Last name"
                error={formik.touched.lastName && formik.errors.lastName}
                overrides={formControlOverrides}
              >
                <Input
                  name="lastName"
                  type="text"
                  value={formik.values.lastName}
                  onChange={formik.handleChange}
                  placeholder={'Last name'}
                  overrides={inputOverrides}
                />
              </FormControl>
            </Block>
          </Block>
          <Block marginBottom={'scale800'}>
            <FormControl
              label="Email"
              error={formik.touched.email && formik.errors.email}
              overrides={formControlOverrides}
            >
              <Input
                name="email"
                type="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder="you@email.com"
                overrides={inputOverrides}
              />
            </FormControl>
          </Block>
          <Block marginBottom={'scale600'}>
            <FormControl
              label="Phone number"
              error={formik.touched.phoneNumber && formik.errors.phoneNumber}
              overrides={formControlOverrides}
            >
              <MaskedInput
                startEnhancer={<LabelMedium marginRight="scale300" color={'black'}>US</LabelMedium>}
                name="phoneNumber"
                placeholder="+(480) 000-0000"
                value={formik.values.phoneNumber}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                overrides={{
                  Input: {
                    style: ({ $theme }) => ({
                      color: $theme.colors.black,
                      backgroundColor: $theme.colors.white,
                      caretColor: $theme.colors.black,
                    }),
                  }
                }}
              />
            </FormControl>
          </Block>
          <Block>
            <FormControl>
              <Checkbox
                checked={formik.values.solarGuide}
                name="solarGuide"
                onChange={formik.handleChange}
                overrides={checkboxOverrides}
              >
                Send Me the Solar Guide
              </Checkbox>
            </FormControl>
          </Block>
          <Block>
            <FormControl>
              <Checkbox
                checked={formik.values.batteryGuide}
                name="batteryGuide"
                onChange={formik.handleChange}
                overrides={checkboxOverrides}
              >
                Send Me the Battery Guide
              </Checkbox>
            </FormControl>
          </Block>
        </Block>
      </form>
    </>
  );
};
export default GuideSectionForm;
