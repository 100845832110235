import { Block } from 'baseui/block';
import React from 'react';
import { Banner } from '../../../types';
import { getMediaUrl } from '../../../utils/get-media-url';
import theme from '../../../page-layouts/theme';
import { useStyletron } from 'baseui';

interface FAQsBannerProps {
  banner: Banner;
}

const FAQsBanner: React.FC<FAQsBannerProps> = ({ banner }) => {
  const [css] = useStyletron();

  return (
    <Block
      backgroundColor={theme.colors.backgroundPrimary}
      display={'flex'}
      paddingTop={['scale2400', 'scale2400', 'scale4800']}
      paddingLeft={['scale400', 'scale400', 'scale3200']}
      paddingRight={['scale400', 'scale400', 'scale3200']}
      paddingBottom={['scale900', 'scale900', 'scale1400']}
      flexDirection={['column', 'column', 'row']}
      justifyContent={['center', 'center', 'space-between']}
      alignItems={'center'}
    >
      <Block display={['none', 'none', 'flex', 'flex']} justifyContent={'center'}>
        <img
          src={getMediaUrl(banner.Background.data.attributes.url)}
          alt="contact-info"
          className={css({
            maxWidth: '350px',
          })}
        />
      </Block>
      <Block display={['flex', 'flex', 'none', 'none']} justifyContent={'center'}>
        <img
          src={getMediaUrl(banner.MobileBackground?.data.attributes.url)}
          alt="contact-info"
          className={css({
            maxWidth: '350px',
          })}
        />
      </Block>
      <Block
        display={'flex'}
        flexDirection={'column'}
        color={'white'}
        marginTop={'scale600'}
      >
        <Block
          font={['ParagraphMedium', 'ParagraphMedium', 'HeadingXLarge']}
          margin={'auto'}
          width={['auto', 'auto', '70%']}
        >
          {banner.H1}
        </Block>
        <Block
          font={['LabelMedium', 'LabelMedium', 'HeadingXXLarge']}
          margin={'auto'}
          width={['auto', 'auto', '70%']}
        >
          {banner.H2}
        </Block>
      </Block>
    </Block>
  );
};

export default FAQsBanner;
