import { useStyletron } from 'baseui';
import React from 'react';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';

declare type MarkdownProps = {
  color?: string;
  data: any;
  fontSize?: string;
  lineHeight?: string;
  fontWeight?: number;
  skipHtml?: boolean;
};

const Markdown: React.FC<MarkdownProps> = ({
  color,
  data,
  fontSize,
  lineHeight,
  fontWeight,
  skipHtml,
}) => {
  const [css, theme] = useStyletron();

  return (
    <ReactMarkdown
      children={data}
      className={css({
        color: color ? color : theme.colors.contentPrimary,
        fontFamily: 'Raleway',
        fontSize: fontSize,
        lineHeight: lineHeight,
        fontWeight: fontWeight,
        fontFeatureSettings: `'pnum' on, 'lnum' on`,
      })}
      skipHtml={skipHtml}
    />
  );
};

export default Markdown;
