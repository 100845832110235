import React, { Dispatch } from 'react';

import { Block } from 'baseui/block';
import { LabelMedium, LabelSmall } from 'baseui/typography';
import theme from '../../../page-layouts/theme';
import { locations } from './available-locations';
import { Location } from '../../../types/homepage';
import LocationsUtilityCompanies from './locations-utility-companies';
import { useStyletron } from 'baseui';
import { Button } from 'baseui/button';
import FieldEmptyError from './field-empty-error';
import BiChevronUp from '../../icons/bi-chevron-up';
import BiChevronDown from '../../icons/bi-chevron-down';
import FaArrowLeft from '../../icons/fa-arrow-left';

export type LocationSelectionProps = {
  changeView?: () => void;
  location?: Location;
  setLocation: Dispatch<Location>;
  showError: boolean;
  selected?: boolean;
  isMobile?: boolean;
};

const arizonaCompanies = locations.filter(l => l.location === 'AZ');

const LocationSelection: React.FC<LocationSelectionProps> = ({
  changeView,
  location,
  setLocation,
  showError,
  selected,
  isMobile,
}) => {
  const [css] = useStyletron();

  const arrows = () =>
    selected ? (
      <BiChevronUp
        className={css({ marginLeft: theme.sizing.scale200 })}
        size={24}
        color={theme.colors.accent}
      />
    ) : (
      <BiChevronDown
        className={css({ marginLeft: theme.sizing.scale200 })}
        size={24}
        color={theme.colors.accent}
      />
    );
  const heading = (
    <Block>
      <Block
        display={'flex'}
        flexDirection={'column'}
        alignItems={'center'}
        onClick={changeView}
        className={css({ cursor: 'pointer' })}
      >
        <Block>
          <LabelMedium
            color={theme.colors.black}
            display={'flex'}
            alignItems={'center'}
          >
            Utility provider
            {isMobile ? '' : arrows()}
          </LabelMedium>
          <LabelSmall color={theme.colors.accent200}>
            {location ? (
              <Block color={theme.colors.accent} marginRight={'scale0'}>
                {location.utilityCompany}
              </Block>
            ) : (
              'Choose your Provider'
            )}
          </LabelSmall>
        </Block>
      </Block>
      <Block>
        {!location && showError ? (
          <FieldEmptyError errorText='Please select Location' />
        ) : (
          null
        )}
      </Block>
    </Block>
  );

  const content = (
    <Block width={'90%'}>
      <LocationsUtilityCompanies
        list={arizonaCompanies}
        title="Arizona"
        location={location}
        setLocation={setLocation}
      />
    </Block>
  );

  if (isMobile) {
    return (
      <Block display={'flex'} flexDirection={'column'} alignItems={'center'}>
        <Block
          marginTop={'scale800'}
          position={'relative'}
          display={'flex'}
          justifyContent={'center'}
          width={'100%'}
        >
          <Block position={'absolute'} left={0}>
            <Button onClick={changeView} kind="tertiary">
              <FaArrowLeft color={theme.colors.accent} />
            </Button>
          </Block>
          <Block>{heading}</Block>
        </Block>
        {content}
      </Block>
    );
  }
  return selected ? (
    <>
      <Block marginTop={'scale800'}>{heading}</Block>
      {content}
    </>
  ) : (
    heading
  );
};

export default LocationSelection;
