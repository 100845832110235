import { Card } from 'baseui/card';
import React from 'react';
import { Block } from 'baseui/block';
import { Feature } from '../../types/tool-page';
import { getMediaUrl } from '../../utils/get-media-url';
import { FeatureData } from './feature-data';
import CustomTabs, { CustomTabType } from '../tabs/custom-tabs';
import TabAccordian from '../tab-accordian/tab-accordian';
import { MonoHeadingLarge, MonoHeadingMedium } from 'baseui/typography';
import { useStyletron } from 'baseui';

export type ToolFeaturesProps = {
  data: Feature[];
  quotePortal?: boolean;
};

const ToolFeatures: React.FC<ToolFeaturesProps> = ({ data, quotePortal }) => {
  const tabData: CustomTabType[] = data.map(item => ({
    iconActive: item.IconActive.data.attributes.url,
    icon: item.Icon.data.attributes.url,
    id: item.id,
    title: item.Heading,
    children: (
      <FeatureData
      key={item.id}
        buttonText={item.Link?.Text}
        buttonLink={item.Link?.Url}
        description={item.H2}
        image={getMediaUrl(item.Media.data.attributes.url)}
        title={item.H1}
        quotePortal={quotePortal}
      />
    ),
  }));

  const [css, theme] = useStyletron();

  return (
    <>
      <Block
        marginTop={['scale1200', 'scale1600', 'scale1200', '0']}
        marginLeft={['scale800', 'scale1600', 'scale3200', 0]}
        marginRight={['scale800', 'scale1600', 'scale3200', 0]}
        marginBottom={['scale1200', 'scale1600', 'scale1200', 'scale2400']}
      >
        <Block display={['none', 'none', 'none', 'block']}>
          <Card
            overrides={{
              Root: {
                style: () => ({
                  border: `1px solid ${theme.colors.mono200}`,
                  borderRadius: theme.sizing.scale1000,
                  paddingTop: theme.sizing.scale400,
                  backgroundColor: theme.colors.backgroundPrimary,
                }),
              },
            }}
          >
            <Block
              display={['none', 'none', 'none', 'block']}
              backgroundColor={theme.colors.backgroundPrimary}
              marginTop={theme.sizing.scale1200}
            >
              {quotePortal && (
                <Block
                  width="100%"
                  display="flex"
                  justifyContent="center"
                  alignItems={'center'}
                >
                  <MonoHeadingLarge
                    color={theme.colors.contentAccent}
                    marginTop="0"
                    className={css({
                      fontFeatureSettings: `'pnum' on, 'lnum' on`,
                    })}
                  >
                    A SOLAR QUOTE IN 3 EASY STEPS
                  </MonoHeadingLarge>
                </Block>
              )}
              <CustomTabs tabs={tabData} initialActiveTab={`${data[0].id}`} />
            </Block>
          </Card>
        </Block>
        <Block display={['block', 'block', 'block', 'none']}>
          <MonoHeadingMedium
            color={theme.colors.contentAccent}
            marginTop="0"
            className={css({
              fontFeatureSettings: `'pnum' on, 'lnum' on`,
              textAlign: 'center',
              width: '100%',
              paddingTop: theme.sizing.scale1200,
              paddingBottom: theme.sizing.scale1200,
            })}
          >
            A SOLAR QUOTE IN 3 EASY STEPS
          </MonoHeadingMedium>
          <Card
            overrides={{
              Root: {
                style: () => ({
                  border: `none`,
                  borderRadius: theme.sizing.scale1000,
                  paddingTop: theme.sizing.scale400,
                  backgroundColor: theme.colors.accent100,
                }),
              },
            }}
          >
            <Block display={['block', 'block', 'block', 'none']}>
              <TabAccordian tabs={tabData} initialActiveTab={`${data[0].id}`} />
            </Block>
          </Card>
        </Block>
      </Block>
    </>
  );
};

export default ToolFeatures;
