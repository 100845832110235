import { Input } from 'baseui/input';
import React from 'react';

type InputProps = {
  error?: boolean;
  name: string;
  onChange?: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
  placeholder: string;
  type: string;
  value?: string;
};

const InputComponent: React.FC<InputProps> = ({
  error,
  name,
  onChange,
  placeholder,
  type,
  value,
}) => {
  return (
    <Input
      placeholder={placeholder}
      type={type}
      name={name}
      onChange={onChange}
      value={value}
      error={error}
      overrides={{
        Root: {
          style: ({ $theme }) => ({
            marginTop: $theme.sizing.scale550,
            borderRadius: '0.5rem',
            border: `1px solid ${$theme.colors.buttonDisabledFill}`,
          }),
        },
        InputContainer: {
          style: () => ({
            backgroundColor: 'white',
          }),
        },
        Input: {
          style: () => ({
            color: 'black',
            caretColor: 'black',
          }),
        },
      }}
    />
  );
};

export default InputComponent;
