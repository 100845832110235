import { useStyletron } from 'baseui';
import { Block } from 'baseui/block';
import { ParagraphLarge, ParagraphMedium } from 'baseui/typography';
import React from 'react';
import SectionH1 from '../../headings/section-h1';
import SectionH2 from '../../headings/section-h2';

declare type ContentProps = {
  h1: string;
  h2: string;
  description: string;
};

const Content: React.FC<ContentProps> = ({ h1, h2, description }) => {
  const [, theme] = useStyletron();

  return (
    <Block
      display="flex"
      flexDirection="column"
      marginBottom={[0, 0, 0, 'scale1200']}
      marginTop={['scale1200', 'scale1200', 'scale1200', '0']}
    >
      <SectionH1
        margin={0}
        color={theme.colors.contentAccent}
        marginBottom={'scale600'}
      >
        {h1.toUpperCase()}
      </SectionH1>

      <SectionH2 margin={0}>{h2}</SectionH2>
      <ParagraphLarge
        margin="0"
        marginTop="scale600"
        display={['none', 'none', 'none', 'flex']}
      >
        {description}
      </ParagraphLarge>
      <ParagraphMedium
        display={['flex', 'flex', 'flex', 'none']}
        color={theme.colors.mono600}
        marginTop="0"
        width={['100%', '100%', '100%', '80%']}
      >
        {description}
      </ParagraphMedium>
    </Block>
  );
};

export default Content;
