import { Block } from 'baseui/block';
import { FormControl } from 'baseui/form-control';
import { Input, MaskedInput } from 'baseui/input';
import { LabelMedium, ParagraphXSmall } from 'baseui/typography';
import { Textarea } from 'baseui/textarea';
import { FormikProps } from 'formik';
import React from 'react';
import { formControlOverrides, inputOverrides } from '../../../utils/form-util';
import { ReferralFormItem } from './form-utils';
import theme from '../../../page-layouts/theme';
import Button from '../../button/button';

declare type ReferralFormProps = {
  formik: FormikProps<ReferralFormItem>;
  description: string;
};

const ReferralForm: React.FC<ReferralFormProps> = ({ formik, description }) => {
  return (
    <Block>
      <form onSubmit={formik.handleSubmit}>
        <Block>
          <Block
            display={'flex'}
            flexDirection={['column', 'column', 'column', 'row']}
          >
            <Block flex={'1'}>
              <FormControl
                label="First name"
                error={formik.touched.firstName && formik.errors.firstName}
                overrides={formControlOverrides}
              >
                <Input
                  name="firstName"
                  type="text"
                  value={formik.values.firstName}
                  onChange={formik.handleChange}
                  placeholder="First name"
                  overrides={inputOverrides}
                />
              </FormControl>
            </Block>
            <Block marginLeft={['0', '0', '0', 'scale900']} flex={'1'}>
              <FormControl
                label="Last name"
                error={formik.touched.lastName && formik.errors.lastName}
                overrides={formControlOverrides}
              >
                <Input
                  name="lastName"
                  type="text"
                  value={formik.values.lastName}
                  onChange={formik.handleChange}
                  placeholder={'Last name'}
                  overrides={inputOverrides}
                />
              </FormControl>
            </Block>
          </Block>
          <Block marginBottom={'scale800'}>
            <FormControl
              label="Email"
              error={formik.touched.email && formik.errors.email}
              overrides={formControlOverrides}
            >
              <Input
                name="email"
                type="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder="you@email.com"
                overrides={inputOverrides}
              />
            </FormControl>
          </Block>
          <Block marginBottom={'scale600'}>
            <FormControl
              label="Phone number"
              error={formik.touched.phoneNumber && formik.errors.phoneNumber}
              overrides={formControlOverrides}
            >
              <MaskedInput
                startEnhancer={<LabelMedium color={'black'}>US</LabelMedium>}
                name="phoneNumber"
                placeholder="+(480) 000-0000"
                value={formik.values.phoneNumber}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                overrides={{
                  Input: {
                    style: ({ $theme }) => ({
                      color: $theme.colors.black,
                    }),
                  },
                }}
              />
            </FormControl>
          </Block>
          <Block
            display={'flex'}
            flexDirection={['column', 'column', 'column', 'row']}
          >
            <Block flex={'1'} marginRight={['0', '0', '0', 'scale1000']}>
              <FormControl
                label="City"
                error={formik.touched.city && formik.errors.city}
                overrides={formControlOverrides}
              >
                <Input
                  name="city"
                  type="city"
                  value={formik.values.city}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder="City"
                  overrides={inputOverrides}
                />
              </FormControl>
            </Block>
            <Block flex={'1'}>
              <FormControl
                label="Zipcode"
                error={formik.touched.zipcode && formik.errors.zipcode}
                overrides={formControlOverrides}
              >
                <Input
                  name="zipcode"
                  type="zipcode"
                  value={formik.values.zipcode}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder="Zipcode"
                  overrides={inputOverrides}
                />
              </FormControl>
            </Block>
          </Block>
          <Block
            display={'flex'}
            flexDirection={['column', 'column', 'column', 'row']}
          >
            <Block flex={'1'} marginRight={['0', '0', '0', 'scale1000']}>
              <FormControl
                label="Referred By"
                error={formik.touched.referredBy && formik.errors.referredBy}
                overrides={formControlOverrides}
              >
                <Input
                  name="referredBy"
                  type="text"
                  value={formik.values.referredBy}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder="Name"
                  overrides={inputOverrides}
                />
              </FormControl>
            </Block>
            <Block flex={1}>
              <FormControl
                label="Referred By Email"
                error={
                  formik.touched.referredByEmail &&
                  formik.errors.referredByEmail
                }
                overrides={formControlOverrides}
              >
                <Input
                  name="referredByEmail"
                  type="text"
                  value={formik.values.referredByEmail}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder="you@email.com"
                  overrides={inputOverrides}
                />
              </FormControl>
            </Block>
          </Block>
          <Block
            display={'flex'}
            flexDirection={['column', 'column', 'column', 'row']}
          >
            <Block flex={'1'} marginRight={['0', '0', '0', 'scale1000']}>
              <FormControl
                label="Referred By Phone"
                error={
                  formik.touched.referredByPhone &&
                  formik.errors.referredByPhone
                }
                overrides={formControlOverrides}
              >
                <Input
                  name="referredByPhone"
                  type="text"
                  value={formik.values.referredByPhone}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder="+1 (480) 000-0000"
                  overrides={inputOverrides}
                />
              </FormControl>
            </Block>
            <Block flex={'1'}>
              <FormControl
                label="Referral Code"
                error={
                  formik.touched.referralCode && formik.errors.referralCode
                }
                overrides={formControlOverrides}
              >
                <Input
                  name="referralCode"
                  type="text"
                  value={formik.values.referralCode}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder="Referral Code"
                  overrides={inputOverrides}
                />
              </FormControl>
            </Block>
          </Block>
          <Block>
            <FormControl
              label="Message"
              error={formik.touched.message && formik.errors.message}
              overrides={formControlOverrides}
            >
              <Textarea
                name="message"
                type="text"
                value={formik.values.message}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder="Message"
                overrides={inputOverrides}
              />
            </FormControl>
          </Block>
        </Block>
        <Block marginTop="scale650">
          <ParagraphXSmall color={theme.colors.mono400}>
            {description}
          </ParagraphXSmall>
        </Block>
        <Block marginTop="scale1000">
          <Button
            type="submit"
            kind="primary"
            disabled={formik.isSubmitting}
            overrides={{
              BaseButton: {
                style: {
                  width: '100%',
                  border: `1px solid ${theme.colors.accent} !important`,
                  ':hover': {
                    backgroundColor: theme.colors.backgroundPrimary,
                  },
                },
              },
            }}
          >
            SEND
          </Button>
        </Block>
      </form>
    </Block>
  );
};

export default ReferralForm;
