import { Block } from 'baseui/block';
import React from 'react';
import ChevronCircleUp from '../../icons/chevron-circle-up';
import ChevronCircleDown from '../../icons/chevron-circle-down';
import theme from '../../../page-layouts/theme';

type AccordionTitleProps = {
  isExpanded: any;
  question: string;
};

const AccordionTitle: React.FC<AccordionTitleProps> = ({
  isExpanded,
  question,
}) => {
  return (
    <Block
      display={'flex'}
      justifyContent={'space-between'}
      alignItems={'center'}
      flex={1}
    >
      <Block
        color={theme.colors.accent600}
        font={['LabelMedium', 'LabelMedium', 'LabelLarge']}
        width={'100%'}
      >
        {question}
      </Block>
      {isExpanded ? (
        <ChevronCircleUp size={30}/>
      ) : (
        <ChevronCircleDown size={30} />
      )}
    </Block>
  );
};

export default AccordionTitle;
