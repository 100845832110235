import { useStyletron } from 'baseui';
import { Block } from 'baseui/block';
import { MonoHeadingSmall, MonoHeadingXSmall } from 'baseui/typography';
import React from 'react';
import theme from '../../../page-layouts/theme';
import SectionH1 from '../../headings/section-h1';
import SectionH2 from '../../headings/section-h2';

export declare type HeadingsProps = {
  h1: string;
  h2?: string;
  description?: string;
};

const Headings: React.FC<HeadingsProps> = ({ h1, h2, description }) => {
  const [css] = useStyletron();
  return (
    <Block
      marginTop={'scale1200'}
      flexDirection={'column'}
      justifyContent={'center'}
    >
      <SectionH1
        color={theme.colors.contentAccent}
        className={css({
          fontFeatureSettings: `'pnum' on, 'lnum' on`,
          textAlign: 'center',
        })}
      >
        {h1.toUpperCase()}
      </SectionH1>
      {h2 && (
        <SectionH2
          margin="0"
          marginTop="scale400"
          color={theme.colors.contentPrimary}
          justifyContent="center"
          className={css({
            fontFeatureSettings: `'pnum' on, 'lnum' on`,
            textAlign: 'center',
          })}
        >
          {h2}
        </SectionH2>
      )}
      {description && (
        <>
          <MonoHeadingXSmall
            color={theme.colors.mono600}
            display={['block', 'block', 'block', 'none']}
            className={css({
              fontFeatureSettings: `'pnum' on, 'lnum' on`,
              textAlign: 'center',
            })}
          >
            {description}
          </MonoHeadingXSmall>
          <MonoHeadingSmall
            color={theme.colors.mono600}
            display={['none', 'none', 'none', 'block']}
            justifyContent="center"
            justifySelf={'center'}
          >
            {description}
          </MonoHeadingSmall>
        </>
      )}
    </Block>
  );
};

export default Headings;
