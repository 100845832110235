import * as React from 'react';
import { SEO } from '../components';
import PageLayout from '../page-layouts';
import NavBar from '../components/nav-bar/nav-bar';
import { Homepage } from '../types/homepage';
import { HomePageServiceImpl } from '../services/page/home-page.service';
import { ToolFeaturesData } from '../types/tool-features';
import { shineServiceImpl } from '../services/page/shine.service';
import { Block } from 'baseui/block';
import theme from '../page-layouts/theme';
import ToolFeatures from '../components/tool-features/tool-features';
import FooterSection from '../components/footer';
import { ToolPageBanner } from '../components/banner/tool-page/tool-page-banner';

declare type IndexPageProps = {
  serverData: any;
};

const ShinePortal: React.FC<IndexPageProps> = ({ serverData }) => {
  const homepage = serverData.homePageData as Homepage;
  const shinePage = serverData.shinePageData as ToolFeaturesData;

  return (
    <PageLayout>
      {shinePage.data.attributes.seo && <SEO data={shinePage.data.attributes.seo} />}
      <NavBar position={'fixed'} data={homepage.data.attributes.Header} />
      <Block
        display={'flex'}
        flexDirection="column"
        position={'relative'}
        paddingLeft={['0', 'scale1600', 'scale1600', 'scale2400']}
        paddingRight={['0', 'scale1600', 'scale1600', 'scale2400']}
        backgroundColor={theme.colors.backgroundPrimary}
      >
        <ToolPageBanner
          data={shinePage.data.attributes.Banner}
          isQuoteTool={false}
        />
        <Block flexDirection={'column'}>
          <ToolFeatures data={shinePage.data.attributes.Features} />
        </Block>
      </Block>
      <Block width={'100%'}>
        <FooterSection data={homepage.data.attributes.Footer} />
      </Block>
    </PageLayout>
  );
};

export const getServerData = async () => {
  const homeServiceObject = new HomePageServiceImpl();
  const serviceObject = new shineServiceImpl();

  const res = await Promise.all([
    serviceObject.getshinePage(),
    homeServiceObject.getCommonData(),
  ]);

  return {
    props: {
      shinePageData: res[0].data,
      homePageData: res[1].data,
    },
  };
};

export default ShinePortal;
