import { useStyletron } from 'baseui';
import { BlockProps } from 'baseui/block';
import { MonoHeadingXXLarge } from 'baseui/typography';
import React, { PropsWithChildren } from 'react';

type CommonBannerH1Props = BlockProps &
  PropsWithChildren & {
    className?: string;
  };

const CommonBannerH1: React.FC<CommonBannerH1Props> = ({
  children,
  className,
  ...props
}) => {
  const [css, theme] = useStyletron();
  return (
    <MonoHeadingXXLarge
      {...props}
      className={`${className} ${css({
        color: theme.colors.contentAccent,
        fontSize: `${theme.sizing.scale900} !important`,
        fontFamily: 'Raleway !important',
      })}`}
    >
      {children}
    </MonoHeadingXXLarge>
  );
};

export default CommonBannerH1;
