import React from 'react';
import { useWindow } from '../../context/use-window';
import { Homepage as HomePageData } from '../../types/homepage';
import DesktopHomepage from './desktop-homepage';
import MobileHomepage from './mobile-homepage';

export declare type HomepageProps = {
  homepage: HomePageData;
};

const Homepage: React.FC<HomepageProps> = ({ homepage }) => {

  const { deviceType } = useWindow();

  return (
    <>
      {deviceType === 'desktop' || deviceType === 'tablet' ? (
        <DesktopHomepage homepage={homepage} />
      ) : (
        <MobileHomepage homepage={homepage} />
      )}
    </>
  );
};

export default Homepage;

