import React, { Dispatch } from 'react';
import { getMediaUrl } from '../../../utils/get-media-url';
import { Block } from 'baseui/block';
import { useStyletron } from 'baseui';
import theme from '../../../page-layouts/theme';
import { Modal, ModalBody, SIZE, ROLE } from 'baseui/modal';
import { MouseEvent } from 'react';
import Carousel from '../../carousel/carousel';
import { fullScreenCarouselResponsiveBreakPoints } from '../../../utils/carousel-util';
import { Project } from '../../../types/projects';
// YadnyeshJtc: css is used as there is no other way to add style to third party library of carousel
import './preview-modal.css';

type PreviewModalProps = {
  isOpen: boolean;
  setIsOpen: Dispatch<boolean>;
  projects: Project[];
};

const PreviewModal: React.FC<PreviewModalProps> = ({
  isOpen,
  setIsOpen,
  projects,
}) => {
  const [css] = useStyletron();

  const images = projects.map(project => (
    <img
      width={theme.sizing.scale2400}
      height={theme.sizing.scale1200}
      className={css({ objectFit: 'cover' })}
      key={project.Media.data.attributes.url}
      src={getMediaUrl(project.Media.data.attributes.url)}
      alt="project-media"
    />
  ));

  const CustomDot = ({ index, onClick, active }: any) => {
    return (
      <Block
        position={'relative'}
        onClick={(e: MouseEvent) => {
          onClick();
          e.preventDefault();
        }}
        className={css({
          border: 'none',
          margin: theme.sizing.scale400,
          outline: active ? 'auto' : 'none',
          transform: `scale(${active ? 1.3 : 1})`,
        })}
      >
        {React.Children.toArray(images)[index]}
      </Block>
    );
  };

  return (
    <Modal
      onClose={() => setIsOpen(false)}
      closeable
      isOpen={isOpen}
      animate
      autoFocus
      size={SIZE.auto}
      role={ROLE.dialog}
      overrides={{
        Close: {
          style: {
            display: 'none',
          },
        },
        Dialog: {
          style: {
            background: theme.colors.mono100,
            overflow: 'visible',
            zIndex: 2,
          },
        },
      }}
    >
      <ModalBody>
        <Block
          width={['90vw', '90vw', '90vw', '80vw']}
          height={['300px', '300px', '500px', '800px']}
        >
          <Carousel
            showDots
            className={css({ width: '100%', height: '100%' })}
            dotListClass="image-dot-list"
            responsive={fullScreenCarouselResponsiveBreakPoints}
            customDot={<CustomDot />}
          >
            {projects.map(item => (
              <img
                src={getMediaUrl(item.Media.data.attributes.url)}
                width={'100%'}
                height="100%"
                alt="project-media"
                className={css({
                  borderRadius: theme.sizing.scale600,
                  objectFit: 'contain',
                })}
              />
            ))}
          </Carousel>
        </Block>
      </ModalBody>
    </Modal>
  );
};

export default PreviewModal;
