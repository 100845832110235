import { useStyletron } from 'baseui';
import { Block } from 'baseui/block';
import React from 'react';
import ReactMarkdown from 'react-markdown';
import { getMediaUrl } from '../../../utils/get-media-url';

declare type Contentprops = {
  content: any;
};

const Content: React.FC<Contentprops> = ({ content }) => {
  const [css, theme] = useStyletron();

  return (
    <Block
      display="flex"
      flexDirection="column"
      width={['90%', '90%', '70%', '70%']}
      marginTop={[0, 0, 'scale1200']}
    >
      <ReactMarkdown
        children={content}
        transformImageUri={url =>
          url.startsWith('http') ? url : getMediaUrl(url)
        }
        className={css({
          color: theme.colors.contentPrimary,
          fontFamily: 'Raleway',
          fontWeight: 400,
          fontSize: theme.sizing.scale650,
          lineHeight: theme.sizing.scale800,
          fontFeatureSettings: `'pnum' on, 'lnum' on`,
        })}
        components={{
          img: ({ node, ...props }) => (
            <img width="100%" alt="blogImage" {...props} />
          ),
          a: ({ node, ...props }) => (
            <a
              style={{
                color: '#F26C1B',
                textDecoration: 'none',
              }}
              {...props}
            >
              {props.children || ' '}
            </a>
          ),
        }}
      />
    </Block>
  );
};

export default Content;
