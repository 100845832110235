import { useStyletron } from 'baseui';
import { BlockProps } from 'baseui/block';
import { MonoHeadingXLarge } from 'baseui/typography';
import React, { Dispatch, PropsWithChildren } from 'react';

type SectionH2Props = BlockProps &
  PropsWithChildren & {
    className?: string;
    onClick?: Dispatch<unknown>;
  };

const SectionH2: React.FC<SectionH2Props> = ({
  children,
  className,
  onClick = () => {},
  ...props
}) => {
  const [css, theme] = useStyletron();
  return (
    <>
      <MonoHeadingXLarge
        {...props}
        onClick={onClick}
        display={['none', 'none', 'none', 'flex']}
        className={`${className} ${css({
          fontSize: `${theme.sizing.scale1000} !important`,
          fontFamily: 'Raleway !important',
        })}`}
      >
        {children}
      </MonoHeadingXLarge>
      <MonoHeadingXLarge
        {...props}
        onClick={onClick}
        display={['flex', 'flex', 'flex', 'none']}
        className={`${className} ${css({
          fontSize: `${theme.sizing.scale700} !important`,
          fontFamily: 'Raleway !important',
        })}`}
      >
        {children}
      </MonoHeadingXLarge>
    </>
  );
};

export default SectionH2;
