import { FlexGrid, FlexGridItem } from 'baseui/flex-grid';
import React, { Dispatch } from 'react';
import { Project } from '../../../types/projects';
import ProjectCard from './project-card';

type ProjectsSlideProps = {
  data: Project[];
  setProjects: Dispatch<Project[]>;
};

const ProjectsSlide: React.FC<ProjectsSlideProps> = ({ data, setProjects }) => {
  const showPreview = () => {
    setProjects(data);
  };
  return (
    <FlexGrid flexGridColumnCount={[2, 2, 2, 3]}>
      {data.map(item => (
        <FlexGridItem>
          <ProjectCard data={item} onClick={showPreview} />
        </FlexGridItem>
      ))}
    </FlexGrid>
  );
};

export default ProjectsSlide;
