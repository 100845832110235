import { useStyletron } from 'baseui';
import { Block } from 'baseui/block';
import React from 'react';
import { Description as DescriptionType } from '../../../types/benefits';
import { getMediaUrl } from '../../../utils/get-media-url';
import VimeoPlayer from '../../video-library/vimeo-player';
import Headings from './headings';
import LazyBackgroundImage from '../../lazy-bg-image';
import BiPlayCircle from '../../icons/bi-play-circle';

declare type DescriptionProps = {
  data: DescriptionType;
};

const Description: React.FC<DescriptionProps> = ({ data }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [css, theme] = useStyletron();
  const handleVideoButtonClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Block
      width="100%"
      display="flex"
      justifyContent={'center'}
      alignItems="center"
      flexDirection={'column'}
      paddingLeft={['scale800', 'scale800', 'scale1600', 'scale4800']}
      paddingRight={['scale800', 'scale800', 'scale1600', 'scale4800']}
      paddingTop={['scale800', 'scale800', 'scale1600', 'sacale3200']}
    >
      <Headings h1={data.H1} h2={data.H2} description={data.Description} />
      <Block
        width="100%"
        display="flex"
        justifyContent={'center'}
        alignItems="center"
        position={'relative'}
        backgroundSize="cover"
        backgroundPosition="center"
        className={css({
          aspectRatio: '2/1',
          borderRadius: theme.sizing.scale400,
        })}
        onClick={handleVideoButtonClick}
      >
        <LazyBackgroundImage img={getMediaUrl(data.Video.Thumbnail.data.attributes.url)} style={{position: 'absolute', width:'100%', height:'100%', top: 0, backgroundPosition:"center", borderRadius: theme.sizing.scale400}} />
        <Block
          position={'relative'}
          className={css({
            ':hover': {
              backgroundColor: 'none',
            },
            ':active': {
              background: 'transparent',
              outline: 'none',
              border: 'none',
            },
          })}
          onClick={handleVideoButtonClick}
        >
          <BiPlayCircle
            color={theme.colors.contentPrimary}
            onClick={handleVideoButtonClick}
            size={80}
          />
        </Block>
        {isOpen && (
          <VimeoPlayer
            video={data.Video.VimeoId}
            isOpen={isOpen}
            setIsOpen={handleVideoButtonClick}
          />
        )}
      </Block>
    </Block>
  );
};

export default Description;
