import { Card } from 'baseui/card';
import React from 'react';
import { Block } from 'baseui/block';
import {
  Card as CardType,
  Category as CategoryType,
} from '../../types/solar-tech';
import { getMediaUrl } from '../../utils/get-media-url';
import theme from '../../page-layouts/theme';
import CustomTabs, { CustomTabType } from '../tabs/custom-tabs';
import { CategoryData } from './category-data';
import { ParagraphLarge } from 'baseui/typography';
import TabAccordian from '../tab-accordian/tab-accordian';
import { useWindow } from '../../context/use-window';
import CategoryProduct from './category-product';

export type CategoryProps = {
  data: CategoryType[];
  cards: CardType[];
};

const Category: React.FC<CategoryProps> = ({ cards, data }) => {
  const { deviceType } = useWindow();
  const tabData: CustomTabType[] = data.map(item => ({
    iconActive: item.IconActive.data.attributes.url,
    icon: item.Icon.data.attributes.url,
    id: item.id,
    title: item.Heading,
    children: (
      <>
        <CategoryData
          buttonText={item.Link.Text}
          description={item.H2}
          image={getMediaUrl(item.Media.data.attributes.url)}
          title={item.H1}
          heading={item.Heading}
          feature={item.Feature}
          buttonLink={item.Link.Url}
        />
        <Block
          paddingTop={['', '', '', 'scale3200']}
          paddingLeft={['', 'scale800', 'scale1600', '']}
          display={['none', 'none', 'none', 'block']}
        >
          <CategoryProduct cards={cards} />
        </Block>
      </>
    ),
  }));

  return (
    <>
      <Block marginTop={'scale2400'} padding={'scale800'} display={'flex'}>
        <Card
          overrides={{
            Root: {
              style: () => ({
                border:
                  deviceType === 'mobile' || deviceType === 'tablet'
                    ? `0px`
                    : `${theme.colors.mono200} solid 1px`,
                borderRadius: theme.sizing.scale1000,
                paddingTop: theme.sizing.scale1600,
                backgroundColor:
                  deviceType === 'mobile' || deviceType === 'tablet'
                    ? `${theme.colors.accent100}`
                    : 'none',
              }),
            },
          }}
        >
          <Block>
            <ParagraphLarge
              $style={{ textAlign: 'center' }}
              color={theme.colors.contentAccent}
            >
              SOLAR TECHNOLOGY
            </ParagraphLarge>
            <Block display={['none', 'none', 'none', 'block']}>
              <CustomTabs tabs={tabData} initialActiveTab={`${data[0].id}`} />
            </Block>
            <Block display={['block', 'block', 'block', 'none']}>
              <TabAccordian tabs={tabData} initialActiveTab={`${data[0].id}`} />
            </Block>
            <Block
              display={['block', 'block', 'block', 'none']}
              marginLeft={['0', '0', 'scale1200', '0']}
              marginTop={['0', 'scale1200', '0', '0']}
            >
              <CategoryProduct cards={cards} />
            </Block>
          </Block>
        </Card>
      </Block>
    </>
  );
};

export default Category;

