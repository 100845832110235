import React from 'react';
import { SEO } from '../components';
import { CommonBanner } from '../components/banner/common-banner/common-banner';
import FooterSection from '../components/footer';
import NavBar from '../components/nav-bar/nav-bar';
import PrivacyPolicyContent from '../components/privacy-policy/content';
import PageLayout from '../page-layouts';
import { HomePageServiceImpl } from '../services/page/home-page.service';
import { PrivacyPolicyPageServiceImpl } from '../services/page/privacy-page.service';
import { Homepage } from '../types/homepage';
import { PrivacyPolicyPageData } from '../types/privacy-page';

declare type PrivacyPolicyProps = {
  serverData: any;
};

const PrivacyPolicy: React.FC<PrivacyPolicyProps> = ({ serverData }) => {
  const homepage = serverData.homePageData as Homepage;
  const privacyPolicyPageData = serverData.privacyPolicyPageData as PrivacyPolicyPageData;
  return (
    <PageLayout>
      {privacyPolicyPageData.data.attributes.seo && <SEO data={privacyPolicyPageData.data.attributes.seo} />}
      <NavBar data={homepage.data.attributes.Header} position="fixed" />
      <CommonBanner banner={privacyPolicyPageData.data.attributes.Banner} />
      <PrivacyPolicyContent
        data={privacyPolicyPageData.data.attributes.Content}
      />
      <FooterSection data={homepage.data.attributes.Footer} />
    </PageLayout>
  );
};

export default PrivacyPolicy;
export const getServerData = async () => {
  const homeServiceObject = new HomePageServiceImpl();
  const privacyPageServiceObject = new PrivacyPolicyPageServiceImpl();

  const res = await Promise.all([
    privacyPageServiceObject.getPrivacyPage(),
    homeServiceObject.getCommonData(),
  ]);
  return {
    props: {
      privacyPolicyPageData: res[0].data,
      homePageData: res[1].data,
    },
  };
};
