import * as Yup from 'yup';

export const ReferralFormvalidationSchema = Yup.object().shape({
  firstName: Yup.string()
    .max(16)
    .required('Please enter first name'),
  lastName: Yup.string().required('Please enter last name'),
  email: Yup.string()
    .email('Please enter a valid email address')
    .required('Please enter your email address'),
  phoneNumber: Yup.string()
    .required('Please enter phone Number')
    .min(10, 'Please enter valid phone umber')
    .max(10, 'Please enter valid phone number'),
  city: Yup.string().required('Please Enter City'),
  zipcode: Yup.string().required('Please enter zipcode'),
  referredBy: Yup.string().required('Please enter referred name'),
  referredByEmail: Yup.string().required(
    'Please enter valid referred by email',
  ),
  referredByPhone: Yup.string().required(
    'Please enter valid referred by phone',
  ),
  referralCode: Yup.string(),
  message: Yup.string().required('Please enter valid message'),
});

export const ReferralFormInitialValues = {
  city: '',
  email: '',
  firstName: '',
  lastName: '',
  phoneNumber: '',
  message: '',
  referredBy: '',
  referredByEmail: '',
  referredByPhone: '',
  referralCode: '',
  zipcode: '',
};

export declare type ReferralFormItem = {
  city: string;
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  message: string;
  referredBy: string;
  referredByEmail: string;
  referredByPhone: string;
  referralCode: string;
  zipcode: string;
};
