import React from 'react';
import { Block, BlockProps } from 'baseui/block';
import { AspectRatioBox, AspectRatioBoxBody } from 'baseui/aspect-ratio-box';
import { useStyletron } from 'baseui';
import theme from '../../page-layouts/theme';

declare type CategoryMediaProps = {
  dataDirection?: boolean;
  url: string;
};

export const CategoryMedia: React.FC<CategoryMediaProps & BlockProps> = ({
  dataDirection,
  url,
}) => {
  const [css] = useStyletron();

  return (
    <>
      <Block
        marginBottom={['scale1000', 'scale1000', 'scale1000', '']}
        paddingRight={!dataDirection ? ['0', '0', '0', 'scale2400'] : '0'}
        paddingLeft={dataDirection ? ['0', '0', '0', 'scale2400'] : '0'}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <AspectRatioBox
          aspectRatio={4 / 3}
          width={['100%', '100%', '100%', '80%']}
        >
          <AspectRatioBoxBody
            as="img"
            alt="category-media"
            src={url}
            className={css({ borderRadius: theme.sizing.scale700 })}
          />
        </AspectRatioBox>
      </Block>
    </>
  );
};

