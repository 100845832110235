import React, { FC } from 'react';
import { Block } from 'baseui/block';
import { Banner } from '../../../types';
import { getMediaUrl } from '../../../utils/get-media-url';
import Headings from './headings';
import Overlay from './overlay';
import { useStyletron } from 'baseui';

export declare type VideoPageBannerProps = {
  banner: Banner;
};

export const NewsPageBanner: FC<VideoPageBannerProps> = ({ banner }) => {
  const [css] = useStyletron();

  return (
    <>
      <Block
        height={['50vh', '50vh', '60vh', '70vh']}
        width="100%"
        display="flex"
        justifyContent={'center'}
        alignItems="center"
        position={'relative'}
        backgroundSize={["cover","contain","contain","contain"]}
        backgroundPosition="center"
        backgroundRepeat={"no-repeat"}
        backgroundImage={`url(${getMediaUrl(
          banner.Background.data.attributes.url,
        )})`}
      >
        <Overlay />
        <Block
          position={'absolute'}
          justifyContent={'center'}
          alignItems="center"
          width={'100%'}
        >
          <Block
            display="flex"
            alignItems={'center'}
            flexDirection="column"
            margin={['auto', 'auto', 'auto', 'scale4800']}
            padding={['auto', 'auto', 'auto', 'scale1200']}
            className={css({ textAlign: 'center' })}
          >
            <Headings banner={banner} />
          </Block>
        </Block>
      </Block>
    </>
  );
};
