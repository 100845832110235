import { useStyletron } from 'baseui';
import { Block } from 'baseui/block';
import React from 'react';
import { Logo } from '../../../types/about-us';
import Headings from '../headings';
import PartnerCarousel from './partner-carousel';

declare type OurPartnersProps = {
  description: string;
  h1: string;
  logos: Logo[];
};

const OurPartners: React.FC<OurPartnersProps> = ({
  description,
  h1,
  logos,
}) => {
  const [, theme] = useStyletron();

  return (
    <Block
      display="block"
      justifyContent={'center'}
      alignItems="center"
      flexDirection={'column'}
      backgroundColor={theme.colors.accent100}
      paddingTop={['scale800', 'scale1600', 'scale800', 'scale800']}
      paddingLeft={['scale800', 'scale1600', 'scale2400', 'scale4800']}
      paddingRight={['scale800', 'scale1600', 'scale2400', 'scale4800']}
      marginTop={['scale800', 'scale1200', 'scale1200', 'scale1200']}
      paddingBottom="scale800"
    >
      <Block>
        <Headings h1={h1} description={description} />
      </Block>

      <PartnerCarousel logos={logos} />
    </Block>
  );
};

export default OurPartners;

