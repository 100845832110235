import { Block } from 'baseui/block';
import React from 'react';
import { Category } from '../../types/video-library';
import { videoCarouselBreakPoints } from '../../utils/carousel-util';
import Carousel from '../carousel/carousel';
import VideoCard from './video-card';

type VideoListProps = {
  category: Category;
  handleVideoSelect: (video: number) => void;
};

const VideoListMobile: React.FC<VideoListProps> = ({
  category,
  handleVideoSelect,
}) => {
  return (
    <Block display={['block', 'block', 'block', 'none']} margin={'scale300'}>
      <Carousel arrows={true} responsive={videoCarouselBreakPoints}>
        {category.Videos.map(video => (
          <VideoCard
            key={`${video.VimeoId}`}
            video={video}
            onClick={handleVideoSelect}
          />
        ))}
      </Carousel>
    </Block>
  );
};

export default VideoListMobile;

