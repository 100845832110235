import { MonoHeadingXLarge } from 'baseui/typography';
import React from 'react';
import { BannerTextProps } from '.';
import { descriptionStyles } from '../../../utils/typpography-utils';
import MonoHeadingXXlargeMobile from '../../typography/mono-heading-xx-large-mobile';

const BannerTextMobile: React.FC<BannerTextProps> = ({
  title,
  description,
}) => {
  return (
    <>
      <MonoHeadingXXlargeMobile
        marginBottom="scale400"
        overrides={{
          Block: {
            style: {
              fontSize: '40px  !important',
              fontWeight: 600,
              textAlign: 'center',
              fontFamily: 'Bitter !important',
              lineHeight: '48px',
            },
          },
        }}
      >
        {title}
      </MonoHeadingXXlargeMobile>

      <MonoHeadingXLarge
        width={['auto', 'auto', '90%', '70%']}
        overrides={{
          ...descriptionStyles,
        }}
      >
        {description}
      </MonoHeadingXLarge>
    </>
  );
};

export default BannerTextMobile;
