import { Block } from 'baseui/block';
import React from 'react';
import { ContactInfo } from '../../../types/resources';
import { getMediaUrl } from '../../../utils/get-media-url';
import { HeadingSmall, ParagraphMedium } from 'baseui/typography';
import PrimaryLink from '../../link/primary-link';
import theme from '../../../page-layouts/theme';
import CallIcon from '../../icons/call-icon';

interface FAQsContactInfoProps {
  contactInfo: ContactInfo;
}

const FAQsContactInfo: React.FC<FAQsContactInfoProps> = ({ contactInfo }) => {
  return (
    <Block
      marginTop={['scale700', 'scale700', 'scale1400']}
      display={'flex'}
      justifyContent={'space-between'}
      alignItems={'center'}
      flexDirection={['column', 'column', 'row', 'row']}
      marginBottom={['scale1200', 'scale1200', 'scale850']}
    >
      <Block
        display={'flex'}
        alignItems={'center'}
        flexDirection={['column', 'column', 'row', 'row']}
      >
        <Block display={'flex'}>
          <img
            src={getMediaUrl(contactInfo.Image.data.attributes.url)}
            alt="contact-info"
          />
        </Block>

        <Block
          display={'flex'}
          flexDirection={'column'}
          marginLeft={'scale850'}
          marginRight={'scale850'}
          marginTop={['scale400', 'scale400', '0']}
          alignItems={['center', 'center', 'initial']}
        >
          <HeadingSmall color={theme.colors.accent} margin={0}>
            {contactInfo.Title}
          </HeadingSmall>
          <ParagraphMedium color={theme.colors.accent600} margin={0} marginTop={['scale300','scale300','0']}>
            {contactInfo.SubTitle}
          </ParagraphMedium>
        </Block>
      </Block>
      <Block
        width={['80%', '80%', 'auto']}
        marginTop={['scale850', 'scale850', '0']}
      >
        <PrimaryLink
          link={`tel:${contactInfo.PhoneNumber.Text}`}
          target="_blank"
          label={contactInfo.PhoneNumber.Text}
          startEnhancer={
            <Block marginRight="scale200">
              <CallIcon />
            </Block>
          }
        />
      </Block>
    </Block>
  );
};

export default FAQsContactInfo;
