import { Block } from 'baseui/block';
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid';
import React from 'react';
import { getFormattedDate } from '../../../utils/date-util';
import { getMediaUrl } from '../../../utils/get-media-url';
import BlogsCard from '../blogs-card';

declare type SubSectionProps = {
  data: any;
};
const SubSection: React.FC<SubSectionProps> = ({ data }) => {
  return (
    <Block
      paddingLeft={['scale800', 'scale1600', 'scale2400', 'scale2400']}
      paddingRight={['scale800', 'scale1600', 'scale2400', 'scale2400']}
    >
      <FlexGrid
        flexGridColumnCount={[1, 1, 3, 3]}
        flexGridColumnGap={'scale1600'}
        flexGridRowGap={'scale1600'}
      >
        {data.slice(0, 3).map((item: any, index: number) => (
          <FlexGridItem key={index}>
            <BlogsCard
              imageHeight="220px"
              Banner={getMediaUrl(
                item.Banner.Background.data.attributes.url
              )}
              slug={item.Slug}
              Description={item.Description}
              H1={item.H1}
              H2={item.H2}
              PublishedDate={getFormattedDate(item.PublishedOn)}
                  />
          </FlexGridItem>
        ))}
      </FlexGrid>
      <FlexGrid
        flexGridColumnCount={[1, 1, 2, 2]}
        flexGridColumnGap={'scale1600'}
        flexGridRowGap={'scale1600'}
        marginTop="scale1600"
        marginBottom={'scale1200'}
      >
        {data.slice(3, 5).map((item: any, index: number) => (
          <FlexGridItem key={index}>
            <BlogsCard
              imageHeight="auto"
              Banner={getMediaUrl(
                item.Banner.Background.data.attributes.url,
              )}
              slug={item.Slug}
              Description={item.Description}
              H1={item.H1}
              H2={item.H2}
              PublishedDate={getFormattedDate(item.PublishedOn)}
            />
          </FlexGridItem>
        ))}
      </FlexGrid>
     
    </Block>
    
  );
};

export default SubSection;
