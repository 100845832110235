import { useStyletron } from 'baseui';
import { Block } from 'baseui/block';
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid';
import { ParagraphLarge, ParagraphMedium } from 'baseui/typography';
import React, { useState } from 'react';
import { Link } from '../../../types';
import { Video } from '../../../types/about-us';
import { getMediaUrl } from '../../../utils/get-media-url';
import Button from '../../button/button';
import SectionH1 from '../../headings/section-h1';
import Markdown from '../../mark-down/mark-down';
import VimeoPlayer from '../../video-library/vimeo-player';
import LazyBackgroundImage from '../../lazy-bg-image';
import BiPlayCircle from '../../icons/bi-play-circle';

declare type WhySunnyEnergyProps = {
  description: string;
  h1: string;
  link: Link;
  video: Video;
};

const WhySunnyEnergy: React.FC<WhySunnyEnergyProps> = ({
  description,
  h1,
  link,
  video,
}) => {
  const [css, theme] = useStyletron();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleVideoButtonClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <FlexGrid
      flexGridColumnCount={[1, 1, 1, 2]}
      paddingTop={['scale800', 'scale1600', 'scale1600', 'scale4800']}
      paddingBottom={['scale1200', 'scale1200', 'scale1200', 'scale3200']}
      paddingLeft={['scale800', 'scale1600', 'scale2400', 'scale2400']}
      paddingRight={['scale800', 'scale1600', 'scale2400', 'scale2400']}
      flexGridColumnGap="scale4800"
      display="flex"
      flexDirection={[
        'column-reverse',
        'column-reverse',
        'column-reverse',
        'row',
      ]}
    >
      <FlexGridItem alignItems="center" justifyContent="center">
        <SectionH1 color={theme.colors.contentAccent}>
          {h1.toUpperCase()}
        </SectionH1>
        <Block width={['100%', '100%', '100%', '80%']}>
          <ParagraphLarge display={['none', 'none', 'none', 'flex']}>
            <Markdown data={description} fontWeight={400} />
          </ParagraphLarge>
          <ParagraphMedium display={['flex', 'flex', 'flex', 'none']}>
            <Markdown data={description} fontWeight={400} />
          </ParagraphMedium>
        </Block>
        <Block
          width="100%"
          alignContent="center"
          display="flex"
          marginTop={['scale1200', 'scale1200', 'scale1200', 'scale650']}
          justifyContent={['center', 'center', 'center', 'flex-start']}
        >
          <Button
            onClick={() => {
              setIsOpen(true);
            }}
          >
            {link.Text.toUpperCase()}
          </Button>
        </Block>
      </FlexGridItem>

      <FlexGridItem
        width="100%"
        display="flex"
        justifyContent={'center'}
        alignItems="center"
        position={'relative'}
        backgroundSize="cover"
        backgroundPosition="center"
        className={css({
          aspectRatio: '2/1',
          borderRadius: theme.sizing.scale400,
          maxHeight: '480px',
        })}
        onClick={handleVideoButtonClick}
        marginTop={['scale1200', 'scale1200', 'scale2400', 'scale1200']}
      >
        <LazyBackgroundImage img={getMediaUrl(video.Thumbnail.data.attributes.url)} style={{position: 'absolute', width:'100%', height:'100%', top: 0, backgroundPosition:"center", borderRadius: theme.sizing.scale400,}} />
        <Block
          position={'relative'}
          className={css({
            ':hover': {
              backgroundColor: 'none',
            },
            ':active': {
              background: 'transparent',
              outline: 'none',
              border: 'none',
            },
          })}
          onClick={handleVideoButtonClick}
        >
          <BiPlayCircle
            color={theme.colors.contentPrimary}
            onClick={handleVideoButtonClick}
            size={80}
          />
        </Block>
        {isOpen && (
          <VimeoPlayer
            video={video.VimeoId}
            isOpen={isOpen}
            setIsOpen={handleVideoButtonClick}
          />
        )}
      </FlexGridItem>
    </FlexGrid>
  );
};

export default WhySunnyEnergy;
