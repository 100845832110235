import { useStyletron } from 'baseui';
import { MonoHeadingXLarge, MonoHeadingXXLarge } from 'baseui/typography';
import React from 'react';
import { BannerTextProps } from '.';

const BannerTextDesktop: React.FC<BannerTextProps> = ({
  title,
  description,
}) => {
  const [css] = useStyletron();

  return (
    <>
      <MonoHeadingXXLarge
        marginBottom={0}
        width={['auto', 'auto', '90%', '80%']}
        className={css({
          textAlign: 'center',
          fontFamily: 'Bitter !important',
        })}
      >
        {title}
      </MonoHeadingXXLarge>

      <MonoHeadingXLarge
        width={['auto', 'auto', '100%', '100%']}
        overrides={{
          Block: {
            style: {
              textAlign: 'center',
              fontFamily: 'Raleway  !important',
              fontSize: '24px',
              lineHeight: '36px',
              fontWeight: 400,
            },
          },
        }}
      >
        {description}
      </MonoHeadingXLarge>
    </>
  );
};

export default BannerTextDesktop;
