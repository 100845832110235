import React, { useEffect } from 'react';
import { SEO } from '../components';
import { CommonBanner } from '../components/banner/common-banner/common-banner';
import Tabs from '../components/contact-us/tabs';
import FooterSection from '../components/footer';
import NavBar from '../components/nav-bar/nav-bar';
import { useQueryParam } from '../context/use-query-string';
import { useWindow } from '../context/use-window';
import PageLayout from '../page-layouts';
import { ContactUsPageServiceImpl } from '../services/page/contact-us.service';
import { HomePageServiceImpl } from '../services/page/home-page.service';
import { ContactUsPageData } from '../types/contact-us';
import { Homepage } from '../types/homepage';

declare type ContactUsPageProps = {
  serverData: any;
};

const ContactUs: React.FC<ContactUsPageProps> = ({ serverData }) => {
  const homepage = serverData.homePageData as Homepage;
  const contactUsPageData = serverData.contactUsPageData as ContactUsPageData;
  const scrollTo = useQueryParam('scrollTo');

  const { currentWindow } = useWindow();

  const handleScrollToView = () => {
    var element = document.getElementById(scrollTo);
    currentWindow?.scrollTo(0, currentWindow.innerHeight);
    if (element && scrollTo === 'contactDetails') {
      element?.scrollIntoView({
        behavior: 'auto',
      });
    }
  };

  useEffect(() => {
    if (currentWindow && scrollTo) {
      handleScrollToView();
    }
  }, [currentWindow]);

  return (
    <PageLayout>
      <NavBar position={'fixed'} data={homepage.data.attributes.Header} />
      {contactUsPageData.data.attributes.seo && <SEO data={contactUsPageData.data.attributes.seo} />}
      <CommonBanner banner={contactUsPageData.data.attributes.Banner} />
      <Tabs
        tabs={contactUsPageData.data.attributes.Tabs}
        quote={homepage.data.attributes.Quote}
        options={contactUsPageData.data.attributes.Options}
      />
      <FooterSection data={homepage.data.attributes.Footer} />
    </PageLayout>
  );
};

export default ContactUs;

export const getServerData = async () => {
  const homeServiceObject = new HomePageServiceImpl();
  const contactUsServiceObject = new ContactUsPageServiceImpl();

  const res = await Promise.all([
    contactUsServiceObject.getContactUs(),
    homeServiceObject.getCommonData(),
  ]);
  return {
    props: {
      contactUsPageData: res[0].data,
      homePageData: res[1].data,
    },
  };
};
