import React, { useState } from 'react';
import { Block } from 'baseui/block';
import { Homepage } from '../../../types/homepage';
import { useStyletron } from 'baseui';
import Button from '../../button/button';
import FooterSection from '../../footer';
import BlogCards from './blog-cards';
import ReadMoreHeadings from './read-more-headings';
import { Blog } from '../../../types/blog';
import { getsubArray } from '../../../utils/array-utils';

declare type ReadMoreSectionProps = {
  data: Blog[];
  homePageData: Homepage;
};

const ReadMoreSection: React.FC<ReadMoreSectionProps> = ({
  homePageData,
  data,
}) => {
  const [css, theme] = useStyletron();
  const [end, setEnd] = useState<number>(1);
  const [currData, setCurrData] = useState<Blog[]>(getsubArray(data, 3));
 
  React.useMemo(() => {
    setCurrData(getsubArray(data, 3 * end));
  }, [data, end]);

  return (
    <Block
      backgroundColor={theme.colors.backgroundPrimary}
      paddingTop="scale1200"
    >
      <Block>
        <ReadMoreHeadings />
      </Block>

      <BlogCards data={currData} />
      <Block
        display={'flex'}
        marginTop={'scale2400'}
        justifyContent="center"
        paddingTop="scale600"
        paddingBottom={'scale2400'}
        marginLeft={['scale800', 'scale800', 'scale1200', 'scale2400']}
        marginRight={['scale800', 'scale800', 'scale1200', 'scale2400']}
        className={css({
          borderTop: `1px solid ${theme.colors.mono300}`,
        })}
      >
        <Button
          kind="tertiary"
          onClick={() => {
            setEnd(prev => prev + 1);
          }}
        >
          READ MORE
        </Button>
      </Block>
      <FooterSection data={homePageData.data.attributes.Footer} />
    </Block>
  );
};

export default ReadMoreSection;

