import { Block } from 'baseui/block';
import React from 'react';
import { Option } from '../../types/contact-us';
import ShortcutCard from './shortcut-card';

type ShortcutsProps = {
  options: Option[];
};

const Shortcuts: React.FC<ShortcutsProps> = ({ options }) => {
  return (
    <Block display={'flex'} flexDirection="column" alignItems={'center'}>
      <Block
        display={'flex'}
        width="100%"
        flexDirection={['column', 'column', 'row', 'row']}
      >
        <Block flex={1} height="100%">
          <ShortcutCard option={options[0]} callable={true} logoId={1} />
        </Block>
        <Block flex={1} height="100%">
          <ShortcutCard option={options[1]} callable={false} logoId={2} />
        </Block>
      </Block>
      <Block width={'100%'}>
        <ShortcutCard option={options[2]} callable={false} logoId={0} />
      </Block>
    </Block>
  );
};

export default Shortcuts;
