import { Block, BlockProps } from 'baseui/block';
import React, { useState } from 'react';
import AddBill from './add-bill';
import { useStyletron } from 'baseui';
import theme from '../../../page-layouts/theme';
import LocationSelection from './location-selection';
import { Location } from '../../../types/homepage';
import { Button } from 'baseui/button';
import {
  useQueryParam,
  useStringifyQueryParam,
} from '../../../context/use-query-string';
import AiOutlineArrowRight from '../../icons/ai-outline-arrow-right';

const divProps: BlockProps = {
  height: '92px',
  width: '100%',
  backgroundColor: theme.colors.mono600,
  position: 'absolute',
};

const cardTyle = {
  borderRadius: theme.sizing.scale700,
  boxShadow: `0px 4px 4px rgba(0, 0, 0, 0.25), 0px 360px 144px rgba(0, 0, 0, 0.01), 0px 202px 121px rgba(0, 0, 0, 0.05), 0px 90px 90px rgba(0, 0, 0, 0.09), 0px 22px 49px rgba(0, 0, 0, 0.1)`,
};

const DesktopView: React.FC = () => {
  const [css] = useStyletron();
  const [selected, setSelected] = useState(0);
  const handleSelect = (value: number) =>
    setSelected(value === selected ? 0 : value);
  const [bill, setBill] = useState(100);
  const [billChange, setBillChange] = useState(false);
  const [location, setLocation] = useState<Location>();
  const [showError, setShowError] = useState(false);
  const queryParams = {
    utm_campaign: useQueryParam('utm_campaign'),
    utm_medium: useQueryParam('utm_medium'),
    utm_source: useQueryParam('utm_source'),
    utm_content: useQueryParam('utm_content'),
    utm_term: useQueryParam('utm_term'),
    gclid: useQueryParam('gclid'),
  };
  let url = useStringifyQueryParam(queryParams);

  const redirectToWebsite = () => {
    if (!bill || !billChange || !location) {
      setShowError(true);
      return;
    }
    window.location.href = `quote/form?bill=${bill}&location=${location?.location}&utilityCompany=${location?.utilityCompanyId}&${url}`;
  };

  return (
    <Block
      display={['none', 'flex']}
      alignItems={'start'}
      justifyContent={'space-between'}
      width={'714px'}
      height={'200px'}
      marginTop={'scale1200'}
    >
      <Block position={'relative'} flex={1}>
        <Block
          {...divProps}
          className={css({
            borderTopLeftRadius: theme.sizing.scale700,
            borderBottomLeftRadius: theme.sizing.scale700,
          })}
        />
        <Block
          width={'100%'}
          className={css({
            borderTopLeftRadius: theme.sizing.scale700,
            borderBottomLeftRadius: theme.sizing.scale700,
            ...(selected === 1 ? cardTyle : {}),
          })}
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
          height={selected === 1 ? '200px' : '92px'}
          position={'absolute'}
          backgroundColor={selected === 1 ? theme.colors.white : ''}
        >
          <AddBill
            bill={bill}
            billChange={billChange}
            setBillChange={setBillChange}
            showError={showError}
            changeView={() => handleSelect(1)}
            setBill={setBill}
            selected={selected === 1}
          />
        </Block>
      </Block>
      <Block position={'relative'} flex={1}>
        <Block {...divProps} />
        <Block
          width={'100%'}
          className={css({
            ...(selected === 2 ? cardTyle : {}),
          })}
          display={'flex'}
          flexDirection={'column'}
          justifyContent={selected === 2 ? 'start' : 'center'}
          alignItems={'center'}
          height={selected === 2 ? 'auto' : '92px'}
          position={'absolute'}
          backgroundColor={selected === 2 ? theme.colors.white : ''}
        >
          <LocationSelection
            changeView={() => handleSelect(2)}
            showError={showError}
            location={location}
            setLocation={setLocation}
            selected={selected === 2}
          />
        </Block>
      </Block>
      <Block
        flex={1}
        height={'92px'}
        backgroundColor={theme.colors.mono600}
        className={css({
          borderTopRightRadius: theme.sizing.scale700,
          borderBottomRightRadius: theme.sizing.scale700,
        })}
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
      >
        <Button
          aria-label="PRICE MY SYSTEM"
          overrides={{
            BaseButton: {
              style: {
                ':hover': {
                  backgroundColor: theme.colors.primary,
                },
              },
            },
          }}
          endEnhancer={<AiOutlineArrowRight />}
          onClick={redirectToWebsite}
        >
          PRICE MY SYSTEM
        </Button>
      </Block>
    </Block>
  );
};

export default DesktopView;
