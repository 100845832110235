import { Block } from 'baseui/block';
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid';
import React from 'react';
import { Benefit } from '../../../types/benefits';
import { getsubArray } from '../../../utils/array-utils';
import { getMediaUrl } from '../../../utils/get-media-url';
import Content from './content';
import ImageSection from './image';

declare type BenefitDesktopProps = {
  data: Benefit[];
};

const BenefitDesktop: React.FC<BenefitDesktopProps> = ({ data }) => {
  const slicedBenfitArray = getsubArray(data, 2);

  return (
    <>
      {slicedBenfitArray.map((item, index) => (
        <React.Fragment key={index}>
          <FlexGrid
            flexGridColumnCount={[1, 1, 1, 2]}
            justifyContent="center"
            alignItems="center"
            marginBottom={['scale1600']}
            marginTop="scale1600"
            display={['none', 'none', 'none', 'flex']}
          >
            <FlexGridItem>
              <Block
                paddingRight="scale1200"
                justifyContent="center"
                alignItems="center"
                display={'flex'}
              >
                <Content
                  h1={item[0].H1}
                  h2={item[0].H2}
                  description={item[0].Description}
                />
              </Block>
            </FlexGridItem>
            <FlexGridItem>
              <Block paddingLeft="scale2400">
                <ImageSection
                  url={getMediaUrl(item[0].Media.data.attributes.url)}
                />
              </Block>
            </FlexGridItem>
          </FlexGrid>
          {item[1] && (
            <FlexGrid
              flexGridColumnCount={[1, 1, 1, 2]}
              justifyContent="center"
              alignItems="center"
              paddingBottom="scale1600"
              paddingTop="scale1600"
              display={['none', 'none', 'none', 'flex']}
            >
              <FlexGridItem>
                <Block paddingRight={'scale1200'}>
                  <ImageSection
                    url={getMediaUrl(item[1].Media.data.attributes.url)}
                  />
                </Block>
              </FlexGridItem>
              <FlexGridItem>
                <Block
                  paddingLeft="scale2400"
                  justifyContent="center"
                  alignItems="center"
                  display={'flex'}
                  maxHeight="480px"
                >
                  <Content
                    h1={item[1].H1}
                    h2={item[1].H2}
                    description={item[1].Description}
                  />
                </Block>
              </FlexGridItem>
            </FlexGrid>
          )}
        </React.Fragment>
      ))}
    </>
  );
};
export default BenefitDesktop;
