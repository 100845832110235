import { useEffect } from 'react';
import { navigate } from 'gatsby';

const Blog = () => {
  useEffect(() => {
    navigate('/news');
  }, []);
  return null;
};

export default Blog;
