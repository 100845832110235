import { Block } from 'baseui/block';
import React from 'react';
import { useStyletron } from 'baseui';
import { LabelLarge } from 'baseui/typography';
import theme from '../../page-layouts/theme';
import { Animation } from '../../types/homepage';
import { getMediaUrl } from '../../utils/get-media-url';
import Overlay from './overlay';

export type VideoAnimationMobileProps = {
  backgroundColor?: string;
  data: Animation;
  key: string;
};

export const VideoAnimationMobile: React.FC<VideoAnimationMobileProps> = ({
  data,
  backgroundColor,
  key,
}) => {
  const videoUrl = getMediaUrl(data.Media.data.attributes.url);
  const [css] = useStyletron();

  return (
    <Block
      key={key}
      position={'relative'}
      height={'60vh'}
      flexDirection="column"
      justifyContent={'center'}
      display={['flex', 'flex', 'flex', 'none']}
      backgroundColor={backgroundColor}
    >
      <Overlay />
      <LabelLarge
        position={'relative'}
        marginBottom={['scale1000']}
        color={theme.colors.contentAccent}
        className={css({
          textAlign: 'center',
        })}
        width="100%"
      >
        {data.Heading}
      </LabelLarge>
      <Block
        position={'relative'}
        overflow={'hidden'}
        marginBottom={['scale1000']}
      >
        <video autoPlay muted loop width={'100%'}>
          <source src={videoUrl} type="video/mp4" />
        </video>
      </Block>
    </Block>
  );
};
