import { Button, SHAPE } from 'baseui/button';
import React from 'react';

type ButtonGradientProps = {
  handleSubmit: () => Promise<void>;
  text: string;
};

const ButtonGradient: React.FC<ButtonGradientProps> = ({
  handleSubmit,
  text,
}) => {
  return (
    <Button
      shape={SHAPE.square}
      onClick={handleSubmit}
      overrides={{
        BaseButton: {
          style: ({ $theme }) => ({
            width: '80%',
            background:
              'linear-gradient(91deg, #F26C1B -13.84%, #F1D333 113.91%)',
            fontSize: $theme.sizing.scale600,
          }),
        },
      }}
    >
      {text}
    </Button>
  );
};

export default ButtonGradient;
