import { styled } from 'baseui';
import React, { PropsWithChildren } from 'react';

declare type SecondaryLinkProps = PropsWithChildren & {
  className?: string;
  link: string;
  target?: string;
};

const StyledAnchor = styled('a', ({ $theme }) => ({
  color: $theme.colors.accent,
  fontFamily: 'Raleway',
  fontSize: $theme.sizing.scale600,
  fontWeight: 600,
  padding: `${$theme.sizing.scale550} ${$theme.sizing.scale800}`,
  textDecoration: 'none',
}));

const TertiaryLink: React.FC<SecondaryLinkProps> = ({
  className,
  children,
  link,
  target,
}) => {
  return (
    <StyledAnchor href={link} target={target} className={className}>
      {children}
    </StyledAnchor>
  );
};

export default TertiaryLink;
