import { Block } from 'baseui/block';
import React from 'react';
import { getMediaUrl } from '../../../../../utils/get-media-url';
import PersonDetails from '../details/person-details';
import ContentImage from './content-image-section';
import { Leader } from '../../../../../types/about-us';
import PersonDescription from '../details/person-descripiton';

declare type ContentProps = {
  leader: Leader;
  handleClose: (isOpen: boolean) => void;
};

const Content: React.FC<ContentProps> = ({ leader, handleClose }) => {

  return (
    <Block
      display="flex"
      flexDirection={['column', 'column', 'column', 'row']}
    >
      <Block marginBottom="scale400" flexDirection="column">
        <ContentImage
          handleClose={handleClose}
          imageUrl={getMediaUrl(leader.Photo.data.attributes.url)}
        />
        <PersonDetails
          name={leader.Name}
          location={leader.Location}
          designation={leader.Designation}
        />
      </Block>
      <PersonDescription
        name={leader.Title}
        description={leader.Info}
        handleClose={handleClose}
      />
    </Block>
  );
};

export default Content;

