import React from 'react';
import { HomePageServiceImpl } from '../services/page/home-page.service';
import { SolarCompany } from '../types/solar-company';
import { Homepage } from '../types/homepage';
import PageLayout from '../page-layouts';
import { SEO } from '../components';
import FooterSection from '../components/footer';
import Quotation from '../components/projects/quotation';
import ReviewsAndAwards from '../components/reviews-and-awards/reviews-and-awards';
import { GetGuideSection } from '../components/get-guide-section/get-guide-section';
import { SolarCompanyPageImpl } from '../services/page/solar-company-page.service';
import { WhySunnyEnergy } from '../components/why-sunny-energy/why-sunny-energy';
import { GoSolarNow } from '../components/go-solar-now/go-solar-now';

declare type SolarCompanyPageProps = {
  serverData: any;
};

const SolarCompanyPage: React.FC<SolarCompanyPageProps> = ({ serverData }) => {
  const SolarCompanyPageData = serverData.SolarCompanyPageData as SolarCompany;
  const homePage = serverData.homePageData as Homepage;

  return (
    <PageLayout>
        {SolarCompanyPageData.data.attributes.seo && <SEO data={SolarCompanyPageData.data.attributes.seo} />}
        <Quotation data={SolarCompanyPageData.data.attributes.Quote} />
        <WhySunnyEnergy data={SolarCompanyPageData.data.attributes.LearnMoreSection} />
        <ReviewsAndAwards
          awards={SolarCompanyPageData.data.attributes.Awards}
          reviews={SolarCompanyPageData.data.attributes.Reviews}
        />
        <GoSolarNow data={SolarCompanyPageData.data.attributes.GoSolarNowSection} />
        <GetGuideSection
          data={SolarCompanyPageData.data.attributes.GetSolarGuideSection}
        />
        <FooterSection data={homePage.data.attributes.Footer} />
    </PageLayout>
  );
};

export default SolarCompanyPage;

export const getServerData = async () => {
  const homeServiceObject = new HomePageServiceImpl();
  const serviceObject = new SolarCompanyPageImpl();

  const res = await Promise.all([
    serviceObject.getSolarCompanyPage(),
    homeServiceObject.getHomePage(),
  ]);
  return {
    props: {
      SolarCompanyPageData: res[0].data,
      homePageData: res[1].data,
    },
  };
};

