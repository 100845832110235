import * as React from 'react';
import { SEO } from '../components';
import PageLayout from '../page-layouts';
import NavBar from '../components/nav-bar/nav-bar';
import { Homepage } from '../types/homepage';
import { HomePageServiceImpl } from '../services/page/home-page.service';
import { AboutSolarPage } from '../types/about-solar';
import { aboutSolarPageServiceImpl } from '../services/page/about-solar-page.service';
import { Block } from 'baseui/block';
import theme from '../page-layouts/theme';
import FooterSection from '../components/footer';
import InfoSection from '../components/solar-tech/info-section';
import { CommonBanner } from '../components/banner/common-banner/common-banner';
import { VideoAnimation } from '../components/video-animation/video-animation';
import { GetGuideSection } from '../components/get-guide-section/get-guide-section';

declare type AboutSolarProps = {
  serverData: any;
};

const AboutSolar: React.FC<AboutSolarProps> = ({ serverData }) => {
  const homepage = serverData.homePageData as Homepage;
  const aboutSolarPageData = serverData.aboutSolarPageData as AboutSolarPage;

  return (
    <PageLayout>
      {aboutSolarPageData.data.attributes.seo && <SEO data={aboutSolarPageData.data.attributes.seo} />}
      <NavBar position={'fixed'} data={homepage.data.attributes.Header} />
      <CommonBanner banner={aboutSolarPageData.data.attributes.Banner} />
      <Block
        display={'flex'}
        flexDirection="column"
        position={'relative'}
        backgroundColor={theme.colors.backgroundPrimary}
      >
        <GetGuideSection data={homepage.data.attributes.GetSolarGuideSection} />

        <Block paddingTop="scale3200">
          {aboutSolarPageData.data.attributes.InfoSection.map(data => (
            <InfoSection data={data} />
          ))}
        </Block>

        {/* Temperory removal of the animation sections - should add once approved. */}
        {homepage.data.attributes.Animations.map(animation => (
          <Block display="none">
            <VideoAnimation data={animation} key={`${animation.id}`} />
          </Block>
        ))}
      </Block>
      <FooterSection data={homepage.data.attributes.Footer} />
    </PageLayout>
  );
};

export const getServerData = async () => {
  const homeServiceObject = new HomePageServiceImpl();
  const serviceObject = new aboutSolarPageServiceImpl();

  const res = await Promise.all([
    serviceObject.getAboutSolarPage(),
    homeServiceObject.getHomePage(),
  ]);

  return {
    props: {
      aboutSolarPageData: res[0].data,
      homePageData: res[1].data,
    },
  };
};

export default AboutSolar;

