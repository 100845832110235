import { useStyletron } from 'baseui';
import { Block } from 'baseui/block';
import {
  HeadingSmall,
  LabelLarge,
  LabelMedium,
  LabelSmall,
} from 'baseui/typography';
import React from 'react';

declare type PersonDetailsProps = {
  name: string;
  designation: string;
  location: string;
};

const PersonDetails: React.FC<PersonDetailsProps> = ({
  name,
  designation,
  location,
}) => {
  const [, theme] = useStyletron();

  return (
    <Block marginLeft={'scale650'}>
      <HeadingSmall
        display={['none', 'none', 'none', 'flex']}
        marginTop="scale400"
        marginBottom="scale400"
      >
        {name}
      </HeadingSmall>
      <LabelMedium
        display={['none', 'none', 'none', 'flex']}
        color={theme.colors.mono200}
      >
        {designation}
      </LabelMedium>
      <LabelMedium
        display={['none', 'none', 'none', 'flex']}
        color={theme.colors.mono200}
      >
        {location}
      </LabelMedium>
      <LabelLarge
        display={['flex', 'flex', 'flex', 'none']}
        marginTop="scale400"
        marginBottom="scale400"
      >
        {name}
      </LabelLarge>
      <LabelSmall
        display={['flex', 'flex', 'flex', 'none']}
        color={theme.colors.mono200}
      >
        {designation}
      </LabelSmall>
      <LabelSmall
        display={['flex', 'flex', 'flex', 'none']}
        color={theme.colors.mono200}
      >
        {location}
      </LabelSmall>
    </Block>
  );
};

export default PersonDetails;

