import { useStyletron } from 'baseui';
import { Block } from 'baseui/block';
import { Tab, Tabs } from 'baseui/tabs';
import { LabelMedium } from 'baseui/typography';
import React from 'react';
import theme from '../../page-layouts/theme';
import { ProjectsPageServiceImpl } from '../../services/page/projects-page.service';
import { Work as WorkData } from '../../types/projects';
import CityProjects from './city-projects/city-projects';
import Headings from './headings';

type WorkProps = {
  data: WorkData;
};

const Work: React.FC<WorkProps> = ({ data }) => {
  const [css] = useStyletron();
  const cityprojects = new ProjectsPageServiceImpl().getCityProjects(
    data.Projects,
  );
  const [activeKey, setActiveKey] = React.useState<React.Key>('0');

  return (
    <Block display={'flex'} flexDirection={'column'} alignItems={'center'}>
      <Headings h1={data.H1} h2={data.H2} />
      <Block
        display={'flex'}
        justifyContent={'center'}
        width={['100%', '100%', '100%', '90%']}
        marginTop={['scale600', 'scale600', 'scale600', 'scale1600']}
      >
        <Tabs
          overrides={{
            Root: {
              style: {
                width: '100%',
              },
            },
            TabBar: {
              style: {
                marginRight: theme.sizing.scale1400,
                backgroundColor: theme.colors.backgroundPrimary,
                display: 'flex',
                flexWrap: 'wrap',
                flexDirection: 'row',
                justifyContent: 'center',
              },
            },
            Tab: {
              style: {
                borderBottom: `0px`,
              },
            },
          }}
          onChange={({ activeKey }) => {
            setActiveKey(activeKey);
          }}
          activeKey={activeKey}
        >
          {cityprojects.map((project, index) => {
            if (!project) return <></>;
            const isActive = activeKey.toString() === `${index}`;
            return (
              <Tab
                title={
                  <Block display={'flex'}>
                    <LabelMedium
                      paddingBottom={'scale300'}
                      color={isActive ? '' : theme.colors.mono200}
                      className={css({
                        borderBottom: isActive
                          ? `${theme.sizing.scale0} solid ${theme.colors.contentAccent}`
                          : '0px',
                      })}
                    >
                      {project.city.toUpperCase()}
                    </LabelMedium>
                    <Block
                      marginLeft={'scale800'}
                      width={'scale0'}
                      height="100%"
                      backgroundColor={theme.colors.mono300}
                      display={cityprojects.length - 1 > index ? 'block' : 'none'}
                    >
                      &nbsp;
                    </Block>
                  </Block>
                }
              >
                <CityProjects data={project} />
              </Tab>
            );
          })}
        </Tabs>
      </Block>
    </Block>
  );
};

export default Work;
