import React, { ReactNode } from 'react';
import { Accordion, Panel } from 'baseui/accordion';
import { TabTitle } from './tab-title';
import { getMediaUrl } from '../../utils/get-media-url';

export type CustomTabType = {
  children: ReactNode;
  iconActive: string;
  icon: string;
  id: number;
  title: string;
};

export type TabsProps = {
  initialActiveTab: string;
  tabs: CustomTabType[];
};

const TabAccordian: React.FC<TabsProps> = ({ tabs, initialActiveTab }) => {
  const [activeKey, setActiveKey] = React.useState<React.Key>(initialActiveTab);

  return (
    <Accordion
      initialState={{ expanded: ['0'] }}
      onChange={({ expanded }) => {
        if (expanded[0]) setActiveKey(parseInt(expanded[0].toLocaleString()));
      }}
      accordion
      overrides={{
        PanelContainer: {
          style: ({ $theme }) => ({
            padding: 0,
            border: '0px',
            backgroundColor: $theme.colors.accent100,
          }),
        },
        Header: {
          style: ({ $theme }) => ({
            backgroundColor: $theme.colors.accent100,
          }),
        },
        ToggleIcon: {
          style: { display: 'none' },
        },
        Content: {
          style: ({ $theme }) => ({
            padding: '0',
            backgroundColor: $theme.colors.accent100,
          }),
        },
      }}
    >
      {tabs.map(tab => {
        const isActive = activeKey.toLocaleString() === `${tab.id}`;
        const iconUrl = isActive ? tab.iconActive : tab.icon;
        return (
          <Panel
            key={tab.id}
            title={
              <TabTitle
                id={`${tab.id}`}
                icon={getMediaUrl(iconUrl)}
                isActive={isActive}
                title={tab.title}
              />
            }
          >
            {tab.children}
          </Panel>
        );
      })}
    </Accordion>
  );
};

export default TabAccordian;
