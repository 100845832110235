import React from 'react';
import { Modal } from 'baseui/modal';
import {
  DisplaySmall,
  HeadingSmall,
  ParagraphMedium,
  ParagraphXSmall,
} from 'baseui/typography';
import { useStyletron } from 'baseui';
import { Block } from 'baseui/block';
import img from '../../images/success-popup.png';
import theme from '../../page-layouts/theme';

interface SuccessPopupProps {
  closeModal: () => void;
  isOpen: boolean;
}

const SuccessPopup: React.FC<SuccessPopupProps> = ({ closeModal, isOpen }) => {
  const [css] = useStyletron();

  return (
    <Modal
      onClose={closeModal}
      isOpen={isOpen}
      overrides={{
        Dialog: {
          style: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            textAlign: 'center',
            backgroundColor: theme.colors.white,
            borderRadius: theme.sizing.scale800,
          },
        },
      }}
    >
      <img
        src={img}
        alt={'popup...'}
        style={{
          width: 'inherit',
          maxWidth: '100%',
          height: 'auto',
          borderTopLeftRadius: theme.sizing.scale800,
          borderTopRightRadius: theme.sizing.scale800,
        }}
        loading="lazy"
      />
      <Block display={['none', 'none', 'block', 'block']}>
        <DisplaySmall color={'black'} marginTop={'scale1000'}>
          Offer Successfully Claimed!
        </DisplaySmall>
        <ParagraphMedium
          className={css({ textAlign: 'center', color: theme.colors.primary400 })}
        >
          We’ll be in touch soon to schedule your solar consultation.
        </ParagraphMedium>
      </Block>

      <Block display={['block', 'block', 'none', 'none']}>
        <HeadingSmall color={'black'} marginTop={'scale1000'}>
          Offer Successfully Claimed!
        </HeadingSmall>
        <ParagraphXSmall className={css({ color: theme.colors.primary400 })}>
          We’ll be in touch soon to schedule your solar consultation.
        </ParagraphXSmall>
      </Block>
    </Modal>
  );
};

export default SuccessPopup;
