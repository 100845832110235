import React from 'react';
import { Leader, Member } from '../../../../types/about-us';
import DesktopCardSection from './desktop';
import MobileCardSection from './mobile';

export declare type CardsSectionProps = {
  leaders: Leader[];
  members: Member[];
  isOpen?:boolean;
};

const CardsSection: React.FC<CardsSectionProps> = ({
  leaders,
  isOpen,
  members,
}) => {

  return (
    <>
      <DesktopCardSection isOpen={isOpen} members={members} leaders={leaders} />
      <MobileCardSection members={members} leaders={leaders} />
    </>
  );
};

export default CardsSection;

