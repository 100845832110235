import React from 'react';
import { Block, BlockProps } from 'baseui/block';
import { AspectRatioBox, AspectRatioBoxBody } from 'baseui/aspect-ratio-box';
import { useStyletron } from 'baseui';
import theme from '../../page-layouts/theme';

declare type MediaProps = {
  url: string;
};

export const FeatureMedia: React.FC<MediaProps & BlockProps> = ({ url }) => {
  const [css] = useStyletron();

  return (
    <>
      <Block
        display={'flex'}
        paddingTop="none"
        marginTop="scale1200"
        alignItems="center"
        justifyContent="center"
      >
        <AspectRatioBox
          aspectRatio={4 / 3}
          width={['100%', '100%', '100%', '80%']}
        >
          <AspectRatioBoxBody
            as="img"
            alt="feature-media"
            src={url}
            width="100%"
            height="100%"
            className={css({
              borderRadius: theme.sizing.scale700,
              objectFit: 'contain',
            })}
          />
        </AspectRatioBox>
      </Block>
    </>
  );
};
