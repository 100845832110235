import { Block } from 'baseui/block';
import React from 'react';
import { Member as MemberType } from '../../../../types/about-us';
import { getMediaUrl } from '../../../../utils/get-media-url';
import CardDetails from './card-details';

declare type MemberProps = {
  member: MemberType;
};

const Member: React.FC<MemberProps> = ({ member }) => {
  
  return (
    <Block >
      <CardDetails
        url={getMediaUrl(member.Photo.data.attributes.url)}
        name={member.Name}
        designation={member.Designation}
        isLeader={false}
      />
    </Block>
  );
};

export default Member;

