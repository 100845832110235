import { useStyletron } from 'baseui';
import { Block } from 'baseui/block';
import { LabelLarge, ParagraphSmall } from 'baseui/typography';
import React from 'react';

declare type TeamMemberMobilPerops = {
  designation: string;
  name: string;
  url: string;
};

const TeamMemberMobile: React.FC<TeamMemberMobilPerops> = ({
  designation,
  name,
  url,
}) => {
  const [css, theme] = useStyletron();

  return (
    <Block
      display="flex"
      flexDirection={'column'}
      alignItems="center"
      justifyContent="center"
    >
      <Block
        display="flex"
        justifyContent={'center'}
        alignItems="center"
        height={['200px', '200px', '200px', '280px']}
        width={['200px', '200px', '200px', '280px']}
        position={'relative'}
        backgroundSize="cover"
        backgroundPosition="center"
        backgroundImage={`url(${url})`}
        className={css({
          aspectRatio: `{1 / 1}`,
          borderRadius: theme.sizing.scale600,
        })}
      />
      <LabelLarge marginTop="scale400">{name}</LabelLarge>
      <ParagraphSmall marginTop="scale400" color={theme.colors.mono200}>
        {designation}
      </ParagraphSmall>
    </Block>
  );
};

export default TeamMemberMobile;

