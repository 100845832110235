import * as React from 'react';
import { FormControl } from 'baseui/form-control';
import { Input, MaskedInput } from 'baseui/input';
import { Block } from 'baseui/block';
import { Checkbox } from 'baseui/checkbox';
import { LabelMedium } from 'baseui/typography';
import * as Yup from 'yup';
import {
  checkboxOverrides,
  formControlOverrides,
  inputOverrides,
} from '../../utils/form-util';

import { useFormik } from 'formik';
import { Textarea } from 'baseui/textarea';
import { Lead } from '../../types';
import { ZohoServiceImpl } from '../../services/api/zoho.service';
import InfoModal, { InfoModalTypes } from '../modal/info-modal';
import Button from '../button/button';
import theme from '../../page-layouts/theme';
import { navigate } from 'gatsby';
import { useQueryParam } from '../../context/use-query-string';

const validationSchema = Yup.object().shape({
  firstName: Yup.string()
    .max(16)
    .required('Please Enter First name'),
  lastName: Yup.string().required('Please Enter Last name'),
  email: Yup.string()
    .email('Please enter a valid Email address')
    .required('Please enter your Email address'),
  phoneNumber: Yup.string()
    .required('Please Enter Phone number')
    .min(10, 'Please Enter valid phone number')
    .max(10, 'Please Enter valid phone number'),
});

const SendMessageForm: React.FC = () => {
  const [modal, setModal] = React.useState<InfoModalTypes>({
    isOpen: false,
  });
  const campaignName = useQueryParam('utm_campaign');
  const campaignMedium = useQueryParam('utm_medium');
  const gclid = useQueryParam('gclid');

  const formik = useFormik({
    initialValues: {
      agree: false,
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      message: '',
    },
    validationSchema,
    onSubmit: async values => {
      if (!values.agree) return;
      const lead: Lead = {
        campaignName,
        campaignMedium,
        email: values.email,
        firstName: values.firstName,
        lastName: values.lastName,
        phone: values.phoneNumber.toString(),
        description: values.message,
        leadSource: 'Website Form',
        leadSourceDetails: 'Contact Us',
        GCLID: gclid,
      };
      const { data } = await new ZohoServiceImpl().createLead(lead);
      const title = data ? 'Thank you' : 'Sorry';
      const message = data
        ? 'We have scheduled your call successfully.'
        : 'Unable to schedule call, Please try again later';

      title === 'Thank you'
        ? navigate('/thank-you-existing-customer')
        : setModal({
            isOpen: true,
            title,
            message,
          });
    },
  });

  return (
    <>
      <Block>
        <Block
          display={'flex'}
          flexDirection={['column', 'column', 'column', 'row']}
        >
          <Block flex={'1'}>
            <FormControl
              label="First name"
              error={formik.touched.firstName && formik.errors.firstName}
              overrides={formControlOverrides}
            >
              <Input
                name="firstName"
                type="text"
                value={formik.values.firstName}
                onChange={formik.handleChange}
                placeholder="First name"
                overrides={inputOverrides}
              />
            </FormControl>
          </Block>
          <Block marginLeft={['0', '0', '0', 'scale900']} flex={'1'}>
            <FormControl
              label="Last name"
              error={formik.touched.lastName && formik.errors.lastName}
              overrides={formControlOverrides}
            >
              <Input
                name="lastName"
                type="text"
                value={formik.values.lastName}
                onChange={formik.handleChange}
                placeholder={'Last name'}
                overrides={inputOverrides}
              />
            </FormControl>
          </Block>
        </Block>
        <Block marginBottom={'scale800'}>
          <FormControl
            label="Email"
            error={formik.touched.email && formik.errors.email}
            overrides={formControlOverrides}
          >
            <Input
              name="email"
              type="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="you@email.com"
              overrides={inputOverrides}
            />
          </FormControl>
        </Block>
        <Block marginBottom={'scale600'}>
          <FormControl
            label="Phone number"
            error={formik.touched.phoneNumber && formik.errors.phoneNumber}
            overrides={formControlOverrides}
          >
            <MaskedInput
              startEnhancer={<LabelMedium color={'black'}>US</LabelMedium>}
              name="phoneNumber"
              placeholder="+(480) 000-0000"
              value={formik.values.phoneNumber}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              overrides={{
                Input: {
                  style: ({ $theme }) => ({
                    color: $theme.colors.black,
                  }),
                },
              }}
            />
          </FormControl>
        </Block>
        <Block marginBottom={'scale600'}>
          <FormControl label="Message" overrides={formControlOverrides}>
            <Textarea
              placeholder="Message"
              clearOnEscape
              overrides={inputOverrides}
              name="message"
              value={formik.values.message}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </FormControl>
        </Block>
        <Block marginTop="scale1000" marginBottom={'scale1000'}>
          <FormControl label="" error={formik.errors.agree}>
            <Checkbox
              name="agree"
              value={formik.values.message}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              checked={formik.values.agree}
              overrides={checkboxOverrides}
            >
              You agree to our friendly privacy policy.
            </Checkbox>
          </FormControl>
        </Block>

        <Button
          kind="primary"
          type="submit"
          disabled={formik.isSubmitting}
          onClick={formik.submitForm}
          overrides={{
            BaseButton: {
              style: {
                width: '100%',
                border: `1px solid ${theme.colors.accent} !important`,
                ':hover': {
                  backgroundColor: theme.colors.backgroundPrimary,
                },
              },
            },
          }}
        >
          Submit
        </Button>
      </Block>
      <InfoModal
        message={modal.message}
        title={modal.title}
        isOpen={modal.isOpen}
        setIsOpen={value =>
          setModal({
            isOpen: value,
          })
        }
      />
    </>
  );
};
export default SendMessageForm;
