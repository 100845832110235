import { useStyletron } from 'baseui';
import { Block } from 'baseui/block';
import React from 'react';
import { useWindow } from '../../context/use-window';
import { Video } from '../../types/about-us';
import { getMediaUrl } from '../../utils/get-media-url';
import VimeoPlayer from './vimeo-player';
import BiPlayCircle from '../icons/bi-play-circle';

type VideoDescriptionProps = {
  video: Video;
};
const VideoDescription: React.FC<VideoDescriptionProps> = ({ video }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [css, theme] = useStyletron();
  const { deviceType } = useWindow();
  const handleVideoButtonClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Block
      width="100%"
      display="flex"
      justifyContent={'center'}
      alignItems="center"
      flexDirection={'column'}
      marginBottom={["scale1200","scale1200","scale2400","scale2400"]}
      paddingTop={['scale800', 'scale800', 'scale1600', 'sacale3200']}
    >
      <Block
        width={['90%', '90%', '90%', '80%']}
        display="flex"
        justifyContent={'center'}
        alignItems="center"
        position={'relative'}
        backgroundSize="cover"
        backgroundPosition="center"
        backgroundImage={`url(${getMediaUrl(
          video.Thumbnail.data.attributes.url,
        )})`}
        className={css({
          aspectRatio: deviceType === 'desktop' ? '2/1' : '4/3',
          borderRadius: theme.sizing.scale400,
       
        })}
        onClick={handleVideoButtonClick}
      >
        <Block
          className={css({
            ':hover': {
              backgroundColor: 'none',
              minHeight: '250px',
            },
            ':active': {
              background: 'transparent',
              outline: 'none',
              border: 'none',
            },
          })}
          onClick={handleVideoButtonClick}
        >
          <BiPlayCircle
            color={theme.colors.contentPrimary}
            onClick={handleVideoButtonClick}
            size={80}
          />
        </Block>
        {isOpen && (
          <VimeoPlayer
            video={video.VimeoId}
            isOpen={isOpen}
            setIsOpen={handleVideoButtonClick}
          />
        )}
      </Block>
    </Block>
  );
};

export default VideoDescription;
