import { Block } from 'baseui/block';
import React, { useState } from 'react';
import { TeamSection } from '../../../types/about-us';
import Headings from '../headings';
import CardsSection from './cards-section/cards-section';

declare type OurTeamProps = {
  ourTeam: TeamSection;
};

const OurTeam: React.FC<OurTeamProps> = ({ ourTeam }) => {
  const [showLeaderDetails, setShowLeaderDetails] = useState<boolean>(true);
  return (
    <Block
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      paddingTop={['0', '0', 'scale1200', 'scale2400']}
      onClick={() => setShowLeaderDetails(!showLeaderDetails)}
    >
      <Block
        paddingLeft={['scale800', 'scale1600', 'scale2400', 'scale2400']}
        paddingRight={['scale800', 'scale1600', 'scale2400', 'scale2400']}
      >
        <Headings
          h1={ourTeam.H1}
          h2={ourTeam.H2}
          description={ourTeam.Description}
        />
      </Block>
      <Block
        width={['90%', '90%', '80%', '90%']}
        paddingLeft={['scale800', 'scale1600', 'scale2400', 'scale4800']}
        paddingRight={['scale800', 'scale1600', 'scale2400', 'scale4800']}
      >
        <CardsSection
          leaders={ourTeam.Leaders}
          members={ourTeam.Members}
          isOpen={showLeaderDetails}
        />
      </Block>
    </Block>
  );
};

export default OurTeam;
