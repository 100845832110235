import { Block } from 'baseui/block';
import { ParagraphMedium } from 'baseui/typography';
import React from 'react';
import { useStyletron } from 'baseui';

export type TabTitleProps = {
  icon: string;
  id: string;
  isActive: boolean;
  title: string;
};

export const TabTitle: React.FC<TabTitleProps> = ({
  icon,
  id,
  isActive,
  title,
}) => {
  const [css, theme] = useStyletron();

  return (
    <Block
      id={`tab${id}`}
      display={['block', 'block', 'block', 'none']}
      className={css({ textAlign: 'center' })}
      margin={'auto'}
    >
      <Block marginTop="scale600">
        <img src={icon} alt="icon" />
      </Block>
      <ParagraphMedium
        paddingBottom={'scale300'}
        className={css({
          borderBottom: isActive
            ? `1px solid ${theme.colors.contentAccent}`
            : 0,
        })}
      >
        {title}
      </ParagraphMedium>
    </Block>
  );
};

