import { useStyletron } from 'baseui';
import { BlockProps } from 'baseui/block';
import { MonoHeadingXXLarge } from 'baseui/typography';
import React, { Dispatch, PropsWithChildren } from 'react';

type SectionH1Props = BlockProps &
  PropsWithChildren & {
    className?: string;
    onClick?: Dispatch<unknown>;
  };

const SectionH1: React.FC<SectionH1Props> = ({
  children,
  className,
  onClick = () => {},
  ...props
}) => {
  const [css, theme] = useStyletron();
  return (
    <>
      <MonoHeadingXXLarge
        {...props}
        onClick={onClick}
        color={theme.colors.contentAccent}
        className={`${className} ${css({
          fontSize: `${theme.sizing.scale800} !important`,
          fontFamily: 'Raleway !important',
        })}`}
      >
        {children}
      </MonoHeadingXXLarge>
    </>
  );
};

export default SectionH1;
