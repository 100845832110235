import * as React from 'react';
import { Block } from 'baseui/block';
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid';
import ImageSection from './images-section/referral-image';
import theme from '../../page-layouts/theme';
import ReferralFormSection from './form/form-section';
import { Illustration } from '../../types/referral';

declare type ReferralPageContentProps = {
  description: string;
  Images: Illustration[];
};

const ReferralPageContent: React.FC<ReferralPageContentProps> = ({
  description,
  Images,
}) => {
  return (
    <FlexGrid
      flexGridColumnCount={[1, 1, 1, 2]}
      backgroundColor={theme.colors.backgroundPrimary}
      padding={['0', '0', 'scale1200', 'scale1200']}
      paddingBottom="scale1200"
      height="auto"
      margin="0 auto"
    >
      <FlexGridItem>
        <Block
          display={['none', 'none', 'none', 'flex']}
          justifyContent="center"
          alignItems={'center'}
        >
          <ReferralFormSection description={description} />
        </Block>
        <Block
          justifyContent="center"
          alignItems={'center'}
          display={['flex', 'flex', 'flex', 'none']}
        >
          <ImageSection data={Images} />
        </Block>
      </FlexGridItem>

      <FlexGridItem>
        <Block
          justifyContent="center"
          alignItems={'center'}
          display={['none', 'none', 'none', 'flex']}
          marginTop={`-${theme.sizing.scale800}`}
        >
          <ImageSection data={Images} />
        </Block>
        <Block
          display={['flex', 'flex', 'flex', 'none']}
          justifyContent="center"
          alignItems={'center'}
          paddingTop="scale1200"
        >
          <ReferralFormSection description={description} />
        </Block>
      </FlexGridItem>
    </FlexGrid>
  );
};

export default ReferralPageContent;
