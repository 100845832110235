import { Block } from 'baseui/block';
import React from 'react';
import { useStyletron } from 'baseui';
import { HeadingLarge, HeadingSmall, LabelLarge } from 'baseui/typography';
import Markdown from '../../mark-down/mark-down';

declare type ConclusionProps = {
  conclusion: string;
  h1: string;
};

const Conclusion: React.FC<ConclusionProps> = ({ conclusion, h1 }) => {
  const [css, theme] = useStyletron();

  return (
    <Block
      backgroundColor={theme.colors.accent100}
      className={css({
        borderRadius: theme.sizing.scale200,
      })}
      display="flex"
      flexDirection="column"
      marginTop="scale1200"
      padding={'scale800'}
      marginBottom="scale1200"
      width={['80%', '90%', '65%', '65%']}
    >
      <HeadingLarge
        marginBottom={'scale200'}
        margin="0"
        display={['none', 'none', 'none', 'block']}
      >
        {h1}
      </HeadingLarge>
      <HeadingSmall
        marginBottom={'scale400'}
        display={['block', 'block', 'block', 'none']}
      >
        {h1}
      </HeadingSmall>
      <Markdown
        data={conclusion}
        fontSize={theme.sizing.scale650}
        lineHeight={theme.sizing.scale850}
        fontWeight={400}
      />
    </Block>
  );
};

export default Conclusion;

