import { useEffect, useState } from 'react';
import { ONE_DAY, ONE_HOUR, ONE_MINUTE, ONE_SECOND } from '../utils/timer-utils';

const useCountDown = (offerDateTime: Date) => {
  const [countDown, setCountDown] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date().getTime();
      const offerDate = new Date(offerDateTime).getTime();
      const timeDifference = offerDate - now;
      if (timeDifference < 0) {
        clearInterval(interval);
        return;
      }

      const days = Math.floor(timeDifference / ONE_DAY);
      const hours = Math.floor((timeDifference % ONE_DAY) / ONE_HOUR);
      const minutes = Math.floor((timeDifference % ONE_HOUR) / ONE_MINUTE);
      const seconds = Math.floor((timeDifference % ONE_MINUTE) / ONE_SECOND);

      setCountDown({
        days,
        hours,
        minutes,
        seconds,
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [offerDateTime]);
  return countDown;
};

export default useCountDown;
