import { Block } from 'baseui/block';
import React from 'react';
import BlogBanner from '../components/blog/banner';
import Conclusion from '../components/blog/content/conclusion';
import Content from '../components/blog/content/content';
import Headings from '../components/blog/headings/headings';
import PageLayout from '../page-layouts';
import theme from '../page-layouts/theme';
import { HomePageServiceImpl } from '../services/page/home-page.service';
import { getMediaUrl } from '../utils/get-media-url';
import { Homepage as HomepageData } from '../types/homepage';
import NavBar from '../components/nav-bar/nav-bar';
import { Blog } from '../types/blog';
import ReadMoreSection from '../components/blog/read-more/read-more-section';
import { Button } from 'baseui/button';
import { navigate } from 'gatsby';
import { SEO } from '../components/seo';

declare type IndexPageProps = {
  serverData: any;
};

const BlogTemplate: React.FC<IndexPageProps> = ({ serverData, ...props }) => {
  const blogData: Blog = props.pageContext.blogData;
  const sortedBlogs = props.pageContext.sortedBlogs;
  const guideData: Blog = props.pageContext.guideData;
  const sortedGuides = props.pageContext.sortedGuides;
  const homepage = serverData.homePageData as HomepageData;
  const isBlog = !!blogData;
  const data = isBlog ? blogData : guideData;
  const readMoreData = isBlog ? sortedBlogs : sortedGuides;
  return (
    <>
      <PageLayout>
        {blogData && blogData.seo && <SEO data={blogData.seo} type="article"/>}
        {guideData && guideData.seo && <SEO data={guideData.seo} />}
        <NavBar data={homepage.data.attributes.Header} position="fixed" />
        <Headings
          mainHeading={data.H1}
          secondaryHeading={data.H2}
          publishedOn={data.PublishedOn}
        />
        <BlogBanner
          url={getMediaUrl(data?.Banner?.Background.data.attributes.url)}
        />
        <Block
          backgroundColor={theme.colors.backgroundPrimary}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Content content={data.Content} />
          {data.Conclusion && (
            <Conclusion
              conclusion={data.Conclusion.Conclusion}
              h1={data.Conclusion.H1}
            />
          )}
          <Block marginTop={'scale1600'} marginBottom="scale1600">
            <Button
              kind="tertiary"
              overrides={{
                BaseButton: {
                  style: {
                    border: `1px solid ${theme.colors.accent} !important`,
                    backgroundColor: theme.colors.accent,
                    ':hover': {
                      backgroundColor: theme.colors.backgroundPrimary,
                    },
                  },
                },
              }}
              onClick={()=>navigate('/contact')}
            >
              {'GO SOLAR TODAY'}
            </Button>
          </Block>
        </Block>
        <ReadMoreSection data={readMoreData} homePageData={homepage} />
      </PageLayout>
    </>
  );
};

export default BlogTemplate;

export const getServerData = async () => {
  const serviceObject = new HomePageServiceImpl();
  const { data } = await serviceObject.getHomePage();
  return {
    props: {
      homePageData: data,
    },
  };
};
