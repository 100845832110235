import { Block } from 'baseui/block';
import { graphql } from 'gatsby';
import React from 'react';
import { SEO } from '../components';
import { NewsPageBanner } from '../components/banner/news-page-banner/news-page-banner';
import FooterSection from '../components/footer';
import NavBar from '../components/nav-bar/nav-bar';
import NewsSection from '../components/news-and-blogs/news-section/news-section';
import PageLayout from '../page-layouts';
import { HomePageServiceImpl } from '../services/page/home-page.service';
import { NewsAndBlogsPageServiceImpl } from '../services/page/news-and-blogs-page.service';
import { Homepage } from '../types/homepage';
import { NewsAndBlogs as NewsAndBlogsType } from '../types/news-and-blogs';
import theme from '../page-layouts/theme';
import { getBogsByCategory } from '../utils/blog-utils';

declare type NewsAndBlogsProps = {
  serverData: any;
  data: any;
};

const NewsAndBlogs: React.FC<NewsAndBlogsProps> = ({ data, serverData }) => {
  const newsAndBlogsPageData = serverData.newsAndBlogsPageData as NewsAndBlogsType;
  const homePageData = serverData.homePageData as Homepage;
 
  return (
    <PageLayout>
      {newsAndBlogsPageData.data.attributes.seo && <SEO data={newsAndBlogsPageData.data.attributes.seo} type="article"/>}
      <Block backgroundColor={theme.colors.backgroundPrimary}>
        <NavBar position={'fixed'} data={homePageData.data.attributes.Header} />
        <NewsPageBanner banner={newsAndBlogsPageData.data.attributes.Banner} />
        <NewsSection data={getBogsByCategory(data.strapi.blogs.data)} />
      </Block>
      <FooterSection data={homePageData.data.attributes.Footer} />
    </PageLayout>
  );
};

export default NewsAndBlogs;

export const getServerData = async () => {
  const homeServiceObject = new HomePageServiceImpl();
  const newsAndBlogServiceObject = new NewsAndBlogsPageServiceImpl();

  const res = await Promise.all([
    newsAndBlogServiceObject.getNewsAndBlogsPage(),
    homeServiceObject.getCommonData(),
  ]);

  return {
    props: {
      newsAndBlogsPageData: res[0].data,
      homePageData: res[1].data,
    },
  };
};
export const query = graphql`
  {
    strapi {
      blogs(pagination: { limit: 200, start: 0 }, sort: "PublishedOn") {
        data {
          attributes {
            Author
            Banner {
              Background {
                data {
                  attributes {
                    url
                  }
                }
              }
            }
            Conclusion {
              Conclusion
              H1
            }
            Category
            Content
            H1
            H2
            PublishedOn
            Slug
            createdAt
            publishedAt
            updatedAt
            Description
          }
        }
      }
    }
  }
`;
