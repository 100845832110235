import { Block } from 'baseui/block';
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid';
import { ParagraphLarge, ParagraphMedium } from 'baseui/typography';
import React from 'react';
import { useStyletron } from 'styletron-react';
import theme from '../../page-layouts/theme';
import SectionH2 from '../headings/section-h2';
import PrimaryLink from '../link/primary-link';
import { FeatureMedia } from './feature-media';
import HiArrowNarrowRight from '../icons/hi-arrow-narrow-right';

export type FeatureDataProps = {
  buttonText: string;
  buttonLink: string;
  description: string;
  image: string;
  title: string;
  quotePortal?: boolean;
};

export const FeatureData: React.FC<FeatureDataProps> = ({
  buttonText,
  buttonLink,
  description,
  image,
  title,
  quotePortal = false,
}) => {
  const [css] = useStyletron();

  return (
    <>
      <FlexGrid
        flexGridColumnCount={2}
        display={['none', 'none', 'none', 'flex']}
        height="100%"
        flexGridColumnGap="scale400"
      >
        <FlexGridItem>
          <Block marginLeft={['', '', '', 'scale1200']}>
            <SectionH2 marginTop={'scale3200'}>{title}</SectionH2>

            <ParagraphLarge
              marginTop={'scale1000'}
              width={['100%', '100%', '100%', '80%']}
            >
              {description}
            </ParagraphLarge>
            <Block
              marginTop={'scale2400'}
              display={buttonLink ? 'flex' : 'none'}
            >
              <PrimaryLink
                label={buttonText}
                link={buttonLink}
                hasQueryParams={quotePortal}
              />
            </Block>
          </Block>
        </FlexGridItem>
        <FlexGridItem paddingTop="scale1200">
          <FeatureMedia url={image} />
        </FlexGridItem>
      </FlexGrid>

      <Block
        display={['block', 'block', 'block', 'none']}
        backgroundColor={theme.colors.accent100}
      >
        <SectionH2
          marginTop={'scale0'}
          justifyContent="center"
          className={css({ textAlign: 'center' })}
        >
          {title}
        </SectionH2>
        <ParagraphMedium
          marginTop={'scale1000'}
          width={['100%', '100%', '100%', '80%']}
        >
          {description}
        </ParagraphMedium>
        <Block>
          <FeatureMedia url={image} />
        </Block>
        <Block
          marginTop={'scale800'}
          className={css({ textAlign: 'center' })}
          display={buttonLink ? 'block' : 'none'}
        >
          <PrimaryLink
            endEnhancer={<HiArrowNarrowRight width={24} height={20} />}
            link={buttonLink}
            label={buttonText}
            hasQueryParams={true}
          />
        </Block>
      </Block>
    </>
  );
};
