import { useStyletron } from 'baseui';
import { Block } from 'baseui/block';
import { LabelMedium, ParagraphLarge } from 'baseui/typography';
import React from 'react';
import { Banner } from '../../../types';
import SectionH1 from '../../headings/section-h1';
import SectionH2 from '../../headings/section-h2';

export type HeadingProps = {
  banner: Banner;
};

const Headings: React.FC<HeadingProps> = ({ banner }) => {
  const [css, theme] = useStyletron();

  return (
    <Block flexDirection="column" alignItems={'center'}>
      <SectionH1 className={css({ color: theme.colors.contentAccent })}>
        {banner.H1.toUpperCase()}
      </SectionH1>
      <SectionH2
        justifyContent={'center'}
        className={css({ textAlign: 'center' })}
        margin={0}
      >
        {banner.H2}
      </SectionH2>
      <ParagraphLarge
        display={['none', 'none', 'none', 'block']}
        marginTop={'scale600'}
      >
        {banner.Description}
      </ParagraphLarge>
      <LabelMedium
        display={['block', 'block', 'block', 'none']}
        marginTop={'scale600'}
      >
        {banner.Description}
      </LabelMedium>
    </Block>
  );
};

export default Headings;
