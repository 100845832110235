import { Block } from 'baseui/block';
import React from 'react';
import { Benefit } from '../../../types/benefits';
import { getMediaUrl } from '../../../utils/get-media-url';
import Content from './content';
import ImageSection from './image';

declare type BenefitMobileProps = {
  data: Benefit[];
};

const BenefitMobile: React.FC<BenefitMobileProps> = ({ data }) => {
  
  return (
    <Block display={['flex', 'flex', 'flex', 'none']} flexDirection="column">
      {data.map((item, index) => (
        <Block key={index}>
          <ImageSection url={getMediaUrl(item.Media.data.attributes.url)} />
          <Content h1={item.H1} h2={item.H2} description={item.Description} />
        </Block>
      ))}
    </Block>
  );
};

export default BenefitMobile;

