import { Block } from 'baseui/block';
import React from 'react';
import { Category } from '../../types/video-library';
import { videoCarouselBreakPoints } from '../../utils/carousel-util';
import Carousel from '../carousel/carousel';
import VideoCard from './video-card';

type VideoListProps = {
  category: Category;
  handleVideoSelect: (video: number) => void;
};

const VideoListDesktop: React.FC<VideoListProps> = ({
  category,
  handleVideoSelect,
}) => {
  return (
    <Block display={['none', 'none', 'none', 'block']} width="100%">
      <Carousel responsive={videoCarouselBreakPoints} arrows={true}>
        {category.Videos.map(video => (
          <VideoCard
            key={`${video.VimeoId}`}
            video={video}
            onClick={handleVideoSelect}
          />
        ))}
      </Carousel>
    </Block>
  );
};

export default VideoListDesktop;
