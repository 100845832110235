import { Block } from 'baseui/block';
import React from 'react';
import { useStyletron } from 'baseui';
import { AspectRatioBox, AspectRatioBoxBody } from 'baseui/aspect-ratio-box';
import { getMediaUrl } from '../../utils/get-media-url';

declare type BlogBannerProps = {
  url: string;
};

const BlogBanner: React.FC<BlogBannerProps> = ({ url }) => {
  const [css, theme] = useStyletron();

  return (
    <Block
      backgroundColor={theme.colors.backgroundPrimary}
      width="100%"
      display="flex"
      justifyContent={'center'}
      alignItems="center"
      className={css({
        border: 'none',
      })}
    >
      <AspectRatioBox
        width={['90%', '90%', '70%', '70%']}
        height={['40vh', '50vh', '60vh', '70vh']}
        className={css({
          borderRadius: theme.sizing.scale400,
          border: 'none',
        })}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <AspectRatioBoxBody
          as="img"
          alt="banner"
          src={getMediaUrl(url)}
          width={['auto']}
          overrides={{
            Block: {
              style: {
                height: '100%',
                width: '100%',
              },
            },
          }}
        />
      </AspectRatioBox>
    </Block>
  );
};

export default BlogBanner;

