import { useStyletron } from 'baseui';
import { Block } from 'baseui/block';
import { MonoHeadingSmall } from 'baseui/typography';
import React from 'react';
import CommonBannerH1 from '../../../headings/common-banner-h1';
import CommonBannerH2 from '../../../headings/common-banner-h2';

export type HeadingProps = {
  description?: string;
  h1: string;
  h2: string;
};

const HeadingsDesktop: React.FC<HeadingProps> = ({ description, h1, h2 }) => {
  const [css] = useStyletron();
  return (
    <Block
      display={['none', 'none', 'none', 'flex']}
      flexDirection="column"
      alignItems={'center'}
    >
      <CommonBannerH1>{h1} </CommonBannerH1>
      <CommonBannerH2>{h2}</CommonBannerH2>
      {description && (
        <MonoHeadingSmall
          marginTop={'scale600'}
          className={css({ textAlign: 'center' })}
          width="80%"
        >
          {description}
        </MonoHeadingSmall>
      )}
    </Block>
  );
};

export default HeadingsDesktop;
