import React from 'react';
import { Block } from 'baseui/block';

type FieldEmptyErrorProps = {
  errorText: string;
}

const FieldEmptyError: React.FC<FieldEmptyErrorProps> = ({ errorText }) => {
  return (
    <Block
      color="red"
      font="ParagraphSmall"
      paddingTop="scale100"
    >
      {errorText}
    </Block>
  );
}

export default FieldEmptyError;