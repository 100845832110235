export const getsubArray = (array: any, numberOfItemsPerSubArray: number) => {
  const numberOfSubArrays = Math.ceil(array.length / numberOfItemsPerSubArray);
  return new Array(numberOfSubArrays)
    .fill('')
    .map((_, i) =>
      array.slice(
        i * numberOfItemsPerSubArray,
        (i + 1) * numberOfItemsPerSubArray,
      ),
    );
};
