import React from 'react';
import { IconBaseProps } from './icon-base-props';

export const CallIcon: React.FC<IconBaseProps> = ({
  color = '#fff',
  size = '24px',
  stroke = '#fff',
  ...props
}) => {
  return (
    <svg
      stroke={stroke}
      fill={color}
      stroke-width="0"
      viewBox="0 0 24 24"
      aria-hidden="true"
      height={size}
      width={size}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.57136 7.88855C5.28486 6.00155 6.61536 4.30655 8.64786 3.68555C9.00855 3.57528 9.39771 3.60667 9.73605 3.77333C10.0744 3.93999 10.3365 4.22939 10.4689 4.58255L11.1214 6.32255C11.2264 6.60247 11.2454 6.90739 11.1759 7.19819C11.1064 7.48898 10.9516 7.75237 10.7314 7.95455L8.79036 9.73205C8.69469 9.81985 8.6234 9.93094 8.58344 10.0545C8.54348 10.1781 8.53622 10.3099 8.56236 10.4371L8.58036 10.5151L8.62686 10.7101C8.86825 11.6575 9.23605 12.5682 9.72036 13.4176C10.2479 14.3201 10.9026 15.142 11.6644 15.8581L11.7244 15.9121C11.8213 15.9981 11.9388 16.0575 12.0655 16.0846C12.1922 16.1117 12.3238 16.1054 12.4474 16.0666L14.9569 15.2761C15.2421 15.1865 15.5475 15.1842 15.834 15.2695C16.1205 15.3547 16.375 15.5236 16.5649 15.7546L17.7529 17.1961C18.2479 17.7961 18.1879 18.6781 17.6194 19.2076C16.0639 20.6581 13.9249 20.9551 12.4369 19.7596C10.6129 18.2883 9.07529 16.4939 7.90086 14.4661C6.7169 12.4398 5.92606 10.2082 5.56986 7.88855H5.57136ZM10.1359 10.5361L11.7439 9.06005C12.1846 8.65586 12.4945 8.12916 12.6338 7.54756C12.773 6.96597 12.7353 6.35603 12.5254 5.79605L11.8744 4.05605C11.6079 3.34542 11.0805 2.76314 10.3996 2.42786C9.71871 2.09258 8.93561 2.02953 8.20986 2.25155C5.68536 3.02405 3.65886 5.28755 4.08786 8.11505C4.38786 10.0891 5.07936 12.6001 6.60486 15.2221C7.87193 17.4086 9.53055 19.3433 11.4979 20.9296C13.7299 22.7221 16.7089 22.1101 18.6439 20.3071C19.1975 19.7915 19.5336 19.0842 19.5835 18.3293C19.6334 17.5744 19.3934 16.829 18.9124 16.2451L17.7244 14.8021C17.3443 14.3408 16.8352 14.0035 16.2622 13.8336C15.6892 13.6636 15.0785 13.6686 14.5084 13.8481L12.4249 14.5036C11.8869 13.9489 11.4157 13.3332 11.0209 12.6691C10.6394 11.9976 10.3422 11.2817 10.1359 10.5376V10.5361Z"
        fill="white"
      />
    </svg>
  );
};

export default CallIcon;
