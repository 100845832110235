export const mainHeadingDesktopStyles = {
  Block: {
    style: {
      fontFamily: 'Raleway',
      fontSize: '24px',
      fontWeight: 600,
      lineHeight: '36px',
    },
  },
};

export const secondHeadingDesktopStyles = {
  Block: {
    style: {
      fontFamily: 'bitter',
      fontSize: '48px',
      fontWeight: 600,
      lineHeight: '57.6px',
      textAlign: 'center',
    },
  },
};
export const googleReviewHeadingDesktopStyles = {
  Block: {
    style: {
      textAlign: 'center',
      fontFamily: 'Raleway',
      fontSize: '18px',
      fontWeight: 1000,
      lineHeight: '24px',
      letterSpacing: '3%',
    },
  },
};

export const googleReviewSubHeadingDesktopStyles = {
  Block: {
    style: {
      textAlign: 'center',
      fontFamily: 'Raleway',
      fontSize: '18px',
      fontWeight: 400,
      lineHeight: '24px',
    },
  },
};

export const googleReviewHeadingMobileStyles = {
  Block: {
    style: {
      textAlign: 'center',
      fontFamily: 'Raleway',
      fontSize: '16px',
      fontWeight: 600,
      lineHeight: '18px',
    },
  },
};

export const googleReviewSubHeadingMobileStyles = {
  Block: {
    style: {
      textAlign: 'center',
      fontFamily: 'Raleway',
      fontSize: '16px',
      fontWeight: 400,
      lineHeight: '18px',
    },
  },
};

export const mainHeadingMobileStyles = {
  Block: {
    style: {
      fontSize: '18px',
      lineHeight: '18px',
      fontWeight: 600,
      fontFamily: 'Raleway !important',
    },
  },
};

export const secondHeadingMobileStyles = {
  Block: {
    style: {
      fontFamily: 'Bitter',
      fontSize: '24px',
      fontWeight: 400,
      lineHeight: '36px',
    },
  },
};


export const descriptionStyles = {
  Block: {
    style: {
      textAlign: 'center',
      fontFamily: 'Raleway',
      fontSize: '16px',
      fontWeight: 400,
      lineHeight: '24px',
    },
  },
};

export const bitterDescriptionStyles = {
  Block: {
    style: {
      textAlign: 'center',
      fontFamily: 'Bitter',
      fontSize: '16px',
      fontWeight: 400,
      lineHeight: '24px',
    },
  },
};

