import React, { FC } from 'react';
import { Block } from 'baseui/block';
import { Banner } from '../../../types';
import { getMediaUrl } from '../../../utils/get-media-url';
import Overlay from './overlay';
import { useStyletron } from 'baseui';
import HeadingsDesktop from '../common-banner/common-headings/headings-desktop';
import HeadingsMobile from '../common-banner/common-headings/headings-mobile';
import PrimaryLink from '../../link/primary-link';
import LazyBackgroundImage from '../../lazy-bg-image';
import RiArrowRightLine from '../../icons/ri-arrow-right-line';

export declare type ReferralPageBannerProps = {
  banner: Banner;
};

export const ReferralPageBanner: FC<ReferralPageBannerProps> = ({ banner }) => {
  const [css] = useStyletron();

  return (
    <>
      <Block
        height={['40vh', '50vh', '70vh', '80vh']}
        width="100%"
        display="flex"
        justifyContent={'center'}
        alignItems="center"
        position={'relative'}
        backgroundSize="cover"
        backgroundPosition="center"
        paddingTop={['scale2400','','','']}
      >
        <LazyBackgroundImage img={getMediaUrl(banner.Background.data.attributes.url)} style={{position: 'absolute', width:'100%', height:'100%', top: 0}} />
        <Overlay />
        <Block
          position={'absolute'}
          justifyContent={'center'}
          alignItems="center"
          width={'100%'}
        >
          <Block
            display="flex"
            alignItems={'center'}
            flexDirection="column"
            margin={['auto', 'auto', 'auto', 'scale4800']}
            padding={['auto', 'auto', 'auto', 'scale1200']}
            className={css({ textAlign: 'center' })}
          >
            <HeadingsDesktop
              h1={banner.H1}
              h2={banner.H2}
              description={banner.Description}
            />
            <HeadingsMobile
              h1={banner.H1}
              h2={banner.H2}
              description={banner.Description}
            />
            {banner?.Link?.Text && (
              <Block marginTop={banner.Description ? 'scale1000' : 'scale1600'}>
                <PrimaryLink
                  label={banner.Link.Text}
                  link={banner.Link.Url}
                  target="_blank"
                  endEnhancer={
                    <Block marginLeft={'scale400'}>
                      <RiArrowRightLine size={20} />
                    </Block>
                  }
                />
              </Block>
            )}
          </Block>
        </Block>
      </Block>
    </>
  );
};
