import React from 'react';
import { IconBaseProps } from './icon-base-props';

export const HiArrowNarrowRight: React.FC<IconBaseProps> = ({
  color = '#fff',
  size = '24px',
  stroke = '#fff',
  ...props
}) => {
  return (
    <svg
      stroke={stroke}
      fill={color}
      strokeWidth="0"
      viewBox="0 0 24 24"
      aria-hidden="true"
      height={size}
      width={size}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

export default HiArrowNarrowRight;
