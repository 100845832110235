import { CityProjects, Project, ProjectsPageData } from '../../types/projects';
import APIServiceImpl from '../api/api.service';
import ServiceResponse from '../api/response.service';
import { ProjectsPageService } from './projects-page.interface';

const componentsData = [
  'Awards',
  'Awards.Logo',
  'Banner',
  'Banner.Link',
  'Banner.Background',
  'Categories',
  'Categories.Videos',
  'Categories.Videos.Thumbnail',
  'Installation',
  'Installation.Background',
  'Installation.Link',
  'Installation.Video',
  'Installation.Video.Thumbnail',
  'Quote',
  'Quote.Background',
  'Quote.GetSolarQuoteSection',
  'Quote.GetSolarQuoteSection.Media',
  'Quote.GetSolarQuoteSection.Link',
  'Quote.ScheduleCallSection',
  'Quote.ScheduleCallSection.Link',
  'Reviews',
  'Reviews.Reviews',
  'seo',
  'seo.metaImage',
  'seo.metaSocial',
  'seo.metaSocial.image',
  'Work',
  'Work.Projects',
  'Work.Projects.Media',
];

export class ProjectsPageServiceImpl extends APIServiceImpl
  implements ProjectsPageService {
  static readonly GET_VIDEO_LIBRARY_PAGE = `api/project?populate=${componentsData.join(
    ',',
  )}`;

  async getProjectsPage(): Promise<ServiceResponse<ProjectsPageData>> {
    try {
      const url = ProjectsPageServiceImpl.GET_VIDEO_LIBRARY_PAGE;
      const { data: res } = await this.get(url);

      return new ServiceResponse<ProjectsPageData>(new ProjectsPageData(res));
    } catch (error) {
      return new ServiceResponse<ProjectsPageData>(undefined, error);
    }
  }

  getCityProjects(projects: Project[]): CityProjects[] {
    let cityProjects: CityProjects[] = [];

    projects.forEach(project => {
      let index = cityProjects.findIndex(item => {
        return (
          item.city.toLocaleLowerCase() === project.City.toLocaleLowerCase()
        );
      });

      if (index !== -1) cityProjects[index].projects.push(project);
      else {
        cityProjects.push({
          projects: [project],
          city: project.City,
          state: project.State,
        });
      }
    });

    cityProjects.sort(function(a, b) {
      return b.city < a.city ? 1 : a.city < b.city ? -1 : 0;
    });

    const allData: CityProjects = {
      city: 'ALL',
      state: '',
      projects: projects,
    };

    return [allData, ...cityProjects];
  }
}
