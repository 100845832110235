import React, { FC } from 'react';
import { Block } from 'baseui/block';
import { Banner } from '../../../types';
import { getMediaUrl } from '../../../utils/get-media-url';
import HeadingsDesktop from './headings-desktop';
import HeadingsMobile from './headings-mobile';
import BannerOverlay from '../banner-overlay';
import { useStyletron } from 'baseui';
import VideoPlayer from '../../video-player/video-player';
import BannerWrapper from '../banner-wrapper';

export declare type VideoPageBannerProps = {
  banner: Banner;
  handleVideoSelect?: (video: number) => void;
};

export const VideoPageBanner: FC<VideoPageBannerProps> = ({ banner }) => {
  const [css] = useStyletron();

  return (
    <>
      <Block
        width="100%"
        display="flex"
        justifyContent={'center'}
        alignItems="center"
        position={'relative'}
        backgroundPosition="center"
      >
        <BannerWrapper
          backgroundUrl={`${getMediaUrl(
            banner.Background.data.attributes.url,
          )}`}
          height="90vh"
        />

        <BannerOverlay />
        <Block
          display={['none', 'none', 'none', 'flex']}
          position={'absolute'}
          justifyContent={'center'}
          alignItems="center"
          width={'100%'}
        >
          <HeadingsDesktop banner={banner} />
        </Block>
        <Block
          display={['flex', 'flex', 'flex', 'none']}
          justifyContent="center"
          alignItems="center"
          position={'absolute'}
          width={'100%'}
          className={css({
            textAlign: 'center',
          })}
        >
          <HeadingsMobile banner={banner} />
        </Block>
      </Block>
    </>
  );
};
