import React from 'react';
import { IconBaseProps } from './icon-base-props';

export const BiChevronUp: React.FC<IconBaseProps> = ({ color = '#fff', size="24px", stroke = '#fff', ...props}) => {
  return (
    <svg
      stroke={stroke}
      fill={color}
      strokeWidth="0"
      viewBox="0 0 24 24"
      aria-hidden="true"
      height={size}
      width={size}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="m6.293 13.293 1.414 1.414L12 10.414l4.293 4.293 1.414-1.414L12 7.586z"></path>
    </svg>
  );
};

export default BiChevronUp;
