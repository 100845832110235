import { useStyletron } from 'baseui';
import { Block } from 'baseui/block';
import React from 'react';
import { closeButtonStyles } from '../styles';
import RiCloseFill from '../../../../icons/ri-close-fill';

declare type ContentImageProps = {
  imageUrl: string;
  handleClose: (isOpen: boolean) => void;
};

const ContentImage: React.FC<ContentImageProps> = ({
  imageUrl,
  handleClose,
}) => {
  const [css, theme] = useStyletron();

  return (
    <Block
      flexDirection="row"
      display="flex"
      justifyContent="space-between"
      position="relative"
    >
      <Block
        backgroundImage={`url(${imageUrl})`}
        className={css({
          borderTopLeftRadius: theme.sizing.scale650,
          zIndex: '0',
          aspectRatio: `{1 / 1}`,
        })}
        height="300px"
        width="280px"
        display={['none', 'none', 'none', 'flex']}
        justifyContent={'center'}
        alignItems="center"
        position={'relative'}
        backgroundSize="cover"
        backgroundPosition="center"
      />
      <Block
        backgroundImage={`url(${imageUrl})`}
        className={css({
          borderTopLeftRadius: theme.sizing.scale650,
          borderTopRightRadius: theme.sizing.scale650,
          zIndex: '0',
          aspectRatio: `{1 / 1}`,
        })}
        height="300px"
        width={['100%', '100%', '100%', '280px']}
        display={['flex', 'flex', 'flex', 'none']}
        justifyContent={'center'}
        alignItems="center"
        position={'relative'}
        backgroundSize="cover"
        backgroundPosition="center"
      />
      <Block display={['flex', 'flex', 'flex', 'none']}>
        <RiCloseFill
          onClick={() => {
            handleClose(false);
          }}
          className={css({
            ...closeButtonStyles,
            zIndex: '10',
            position: 'absolute',
            top: '10',
            right: '0',
          })}
        />
      </Block>
    </Block>
  );
};

export default ContentImage;

