import { useStyletron } from 'baseui';
import { Block } from 'baseui/block';
import { Card, StyledBody } from 'baseui/card';
import { HeadingSmall } from 'baseui/typography';
import React, { useState } from 'react';
import theme from '../../../page-layouts/theme';
import { Project } from '../../../types/projects';
import { getMediaUrl } from '../../../utils/get-media-url';
import { Slide } from 'react-awesome-reveal';

type ProjectCardProps = {
  data: Project;
  onClick: () => void;
};

const ProjectCard: React.FC<ProjectCardProps> = ({ data, onClick }) => {
  const [css] = useStyletron();
  const [showInfo, setShowInfo] = useState(false);
  const [hovered, setHovered] = useState(false);

  return (
    <Block
      marginLeft={['scale400', 'scale400', 'scale700']}
      marginRight={['scale400', 'scale400', 'scale700']}
      marginTop={['scale400', 'scale400', 'scale700']}
      paddingBottom={['scale400', 'scale400', 'scale800']}
      onClick={onClick}
      height={['scale3200', 'scale3200', '300px']}
      onMouseEnter={() => {
        setHovered(true);
      }}
      onMouseLeave={() => {
        setHovered(false);
      }}
    >
      <Card
        overrides={{
          Root: {
            props: {
              onClick: () => setShowInfo(true),
            },
            style: {
              height: '100%',
              background: `url(${getMediaUrl(data.Media.data.attributes.url)})`,
              backgroundSize: 'cover !important',
              border: 0,
            },
          },
          Contents: {
            style: {
              height: '100%',
              // without !important margin is not overriding, always taking default margin value
              margin: '0 !important',
            },
          },
          Body: {
            style: {
              height: '100%',
              margin: '0px !important',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'end',
            },
          },
        }}
      >
        <StyledBody className={css({ margin: '0px !important' })}>
          {hovered && (
            <Slide direction="up" duration={500}>
              <HeadingSmall
                margin="0px"
                padding={theme.sizing.scale600}
                className={css({ backdropFilter: 'blur(10px)' })}
                backgroundColor={theme.colors.mono200}
              >
                {data.City}, {data.State}
              </HeadingSmall>
            </Slide>
          )}
        </StyledBody>
      </Card>
    </Block>
  );
};

export default ProjectCard;
