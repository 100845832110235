import { Accordion, Panel } from 'baseui/accordion';
import { LabelMedium } from 'baseui/typography';
import React, { useState } from 'react';
import { Block } from 'baseui/block';
import { ContactInfo, Faq, QA } from '../../../types/resources';
import { Link as article } from '../../../types/homepage';
import AccordionTitle from './accordion-title';
import FAQsContactInfo from './contact-info';
import theme from '../../../page-layouts/theme';
import { useWindow } from '../../../context/use-window';
import Markdown from '../../mark-down/mark-down';

declare type FAQsProps = {
  articles: article[];
  contactInfo: ContactInfo;
  data: Faq;
};

const FAQs: React.FC<FAQsProps> = ({ articles, contactInfo, data }) => {
  const { deviceType } = useWindow();
  const [isExpanded, setIsExpanded] = useState<boolean[]>(
    data.QA.map(() => false),
  );

  const handleOnChange = (index: number) => {
    setIsExpanded(prevIsExpanded => {
      const newIsExpanded = prevIsExpanded.map((value, i) => {
        if (i === index) {
          return !value;
        } else {
          return value;
        }
      });
      return newIsExpanded;
    });
  };

  return (
    <Block
      display={'flex'}
      backgroundColor={'white'}
      paddingLeft={['0', '0', 'scale1200', 'scale1200']}
      paddingRight={['0', '0', 'scale1200', 'scale1200']}
      paddingTop={['0', '0', 'scale1200']}
      flexDirection={'column'}
    >
      <Block display={'flex'} flexDirection={['column', 'column', 'row']}>
        <Block
          flex={1}
          paddingLeft={['scale850', 'scale850', 'scale1400']}
          paddingTop={['scale400', 'scale400', '0']}
          paddingRight={['scale850', 'scale850', 'scale0']}
        >
          <Block>
            {data &&
              data.QA.map((qaItem: QA, index: number) => (
                <Accordion
                  key={index}
                  accordion={false}
                  overrides={{
                    Header: {
                      style: () => ({
                        color: theme.colors.accent600,
                      }),
                    },
                    ToggleIcon: {
                      style: () => ({
                        display: 'none',
                      }),
                    },
                    Root: {
                      style: () => ({
                        marginTop: deviceType === 'desktop' ? 'scale1200' : '0',
                      }),
                    },
                    Content: {
                      style: () => ({
                        color: theme.colors.accent600,
                        fontSize: '14px',
                        paddingTop: 0,
                      }),
                    },
                    ContentAnimationContainer: {
                      style: () => ({
                        outline: 'rgba(56, 122, 185, 0.20)',
                      }),
                    },
                  }}
                >
                  <Panel
                    onChange={() => {
                      handleOnChange(index);
                    }}
                    key={index}
                    title={
                      <AccordionTitle
                        isExpanded={isExpanded[index]}
                        question={qaItem.Question}
                      />
                    }
                  >
                    <Markdown
                      data={qaItem.Answer}
                      color={theme.colors.accent600}
                      lineHeight={
                        deviceType === 'desktop'
                          ? theme.sizing.scale700
                          : theme.sizing.scale600
                      }
                    />
                  </Panel>
                </Accordion>
              ))}
          </Block>
          <Block
            marginTop={'scale850'}
            width={'80%'}
            display={'flex'}
            flexDirection={'column'}
          >
            <LabelMedium color={theme.colors.accent}>
              Related articles, blogs
            </LabelMedium>
            {
              <ul>
                {articles.map((data, index) => (
                  <Block marginBottom={'scale400'} key={index}>
                    <li>
                      <a
                        href={data.Url}
                        style={{
                          color: theme.colors.accent600,
                          textDecoration: 'none',
                          fontFamily: 'Raleway',
                        }}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {data.Text}
                      </a>
                    </li>
                  </Block>
                ))}
              </ul>
            }
          </Block>
        </Block>
      </Block>
      <Block>
        <FAQsContactInfo contactInfo={contactInfo} />
      </Block>
    </Block>
  );
};

export default FAQs;
