import { useStyletron } from 'baseui';
import { Block } from 'baseui/block';
import React from 'react';

const Overlay = () => {
  const [css, theme] = useStyletron();

  return (
    <>
      <Block
        height={'100%'}
        width="100%"
        position="absolute"
        bottom={0}
        className={css({
          background: `linear-gradient(0deg, ${theme.colors.backgroundPrimary} 0%,${theme.colors.mono100} 5%)`,
          pointerEvents: 'none',
        })}
      />
    </>
  );
};

export default Overlay;
