import { Block } from 'baseui/block';
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid';
import React from 'react';
import { Blog } from '../../../types/blog';
import { getFormattedDate } from '../../../utils/date-util';
import { getMediaUrl } from '../../../utils/get-media-url';
import BlogsCard from '../../news-and-blogs/blogs-card';

declare type BlogCardProps = {
  data: Blog[];
};

const BlogCards: React.FC<BlogCardProps> = ({ data }) => {
  return (
    <Block
      paddingLeft={['scale800', 'scale800', 'scale1200', 'scale2400']}
      paddingRight={['scale800', 'scale800', 'scale1200', 'scale2400']}
    >
      <FlexGrid
        flexGridColumnCount={[1, 1, 2, 3]}
        flexGridColumnGap="scale1200"
        flexGridRowGap="scale1200"
        marginBottom={['scale1000', 'scale1000', 'scale1000', 'auto']}
      >
        {data[0].map((item, index) => (
          <FlexGridItem key={index}>
            <BlogsCard
              Description={item.Description}
              imageHeight="scale4800"
              H1={item.H1}
              H2={item.H2}
              PublishedDate={getFormattedDate(item.PublishedOn)}
              Banner={getMediaUrl(item.Banner.Background.data.attributes.url)}
              slug={item.Slug}
            />
          </FlexGridItem>
        ))}
      </FlexGrid>
    </Block>
  );
};

export default BlogCards;

