import React from 'react';
import { Animation } from '../../types/homepage';
import { VideoAnimationDesktop } from './video-animation-desktop';
import { VideoAnimationMobile } from './video-animation-mobile';

export type VideoAnimationProps = {
  backgroundColor?: string;
  data: Animation;
  key: string;
};

export const VideoAnimation: React.FC<VideoAnimationProps> = ({
  data,
  backgroundColor,
  key,
}) => {
  return (
    <>
      <VideoAnimationDesktop data={data} key={key} />
      <VideoAnimationMobile
        data={data}
        backgroundColor={backgroundColor}
        key={key}
      />
    </>
  );
};
