import { useStyletron } from 'baseui';
import { Block } from 'baseui/block';
import { LabelLarge, ParagraphMedium, ParagraphSmall } from 'baseui/typography';
import React, { useState } from 'react';

declare type CardDetailsProps = {
  url: string;
  name: string;
  designation: string;
  location?: string;
  isLeader?: boolean;
};

const CardDetails: React.FC<CardDetailsProps> = ({
  url,
  name,
  designation,
  isLeader,
  location,
}) => {
  const [css, theme] = useStyletron();
  const [hovered, setHovered] = useState(false);

  return (
    <Block display={'inline-flex'} flexDirection="column">
      <Block
        display="flex"
        justifyContent={'center'}
        alignItems="center"
        height={['200px', '200px', '200px', '280px']}
        width={['200px', '200px', '200px', '280px']}
        position={'relative'}
        backgroundSize="cover"
        backgroundPosition="center"
        backgroundImage={`url(${url})`}
        className={css({
          aspectRatio: `{1 / 1}`,
          borderRadius: theme.sizing.scale400,
          borderBottomLeftRadius: hovered ? '0px' : theme.sizing.scale400,
          borderBottomRightRadius: hovered ? '0px' : theme.sizing.scale400,
          ':hover': {
            borderBottomLeftRadius: !isLeader ? theme.sizing.scale400 : '0px',
            borderBottomRightRadius: !isLeader ? theme.sizing.scale400 : '0px',
          },
        })}
      />
      <Block
        marginTop={'scale800'}
        marginLeft="scale650"
        display={'flex'}
        flexDirection="column"
        alignItems={'baseline'}
        onMouseEnter={() => {
          setHovered(true);
        }}
        onMouseLeave={() => setHovered(false)}
      >
        <LabelLarge marginBottom={'0'}>{name}</LabelLarge>
        <ParagraphMedium marginBottom={0} maxWidth="200px">
          {designation}
        </ParagraphMedium>
        {location && (
          <ParagraphSmall
            marginTop={'0'}
            marginBottom={'0'}
            color={theme.colors.mono400}
          >
            {location}
          </ParagraphSmall>
        )}
      </Block>
    </Block>
  );
};

export default CardDetails;
