import React, { ReactNode } from 'react';
import { Quote } from '../../types/homepage';
import { Option, Tab as TabType } from '../../types/contact-us';
import QuotationDesktop from '../quotation/desktop/quotation-desktop';
import QuotationMobile from '../quotation/quotation-mobile';
import { Block } from 'baseui/block';
import CustomTabs, { CustomTabType } from '../tabs/custom-tabs';
import theme from '../../page-layouts/theme';
import ExistingCustomer from './existing-customer';
import TabAccordian from '../tab-accordian/tab-accordian';
import { Card } from 'baseui/card';
import SectionSnapY from '../scroll-snap-y/section-snap-y';
import { useQueryParam } from '../../context/use-query-string';

type TabsProps = {
  options: Option[];
  quote: Quote;
  tabs: TabType[];
};

const Tabs: React.FC<TabsProps> = ({ quote, options, tabs }) => {
  const activeIndex = useQueryParam('activeIndex');
  const getTabChildren = (index: number): ReactNode => {
    switch (index) {
      case 0:
        return (
          <Block
            marginTop={['0', '0', '0', 'scale1000']}
            marginBottom={['0', '0', '0', 'scale1000']}
          >
            <SectionSnapY display={['none', 'none', 'none', 'block']}>
              <QuotationDesktop data={quote} isContactUs={true} />
            </SectionSnapY>
            <Block display={['block', 'block', 'block', 'none']}>
              <QuotationMobile data={quote} isContactUs={true} />
            </Block>
          </Block>
        );
      case 1:
        return <ExistingCustomer options={options} />;
      default:
        return '';
    }
  };

  const tabData: CustomTabType[] = tabs.map((item, index) => ({
    iconActive: item.IconActive.data.attributes.url,
    icon: item.Icon.data.attributes.url,
    id: index,
    title: item.Heading,
    children: getTabChildren(index),
  }));

  return (
    <Block
      paddingBottom={['scale1200', 'scale1600', 'scale2400', 'scale2400']}
      paddingTop={['scale1200', 'scale1600', 'scale2400', 'scale2400']}
      backgroundColor={theme.colors.backgroundPrimary}
      id="contactDetails"
    >
      <Block display={['none', 'none', 'none', 'block']} width="100%">
        <CustomTabs
          tabs={tabData}
          initialActiveTab={activeIndex ? activeIndex : "0"}
        />
      </Block>

      <Block display={['block', 'block', 'block', 'none']}>
        <Card
          overrides={{
            Root: {
              style: ({ $theme }) => ({
                border: `${$theme.sizing.scale100} solid ${$theme.colors.accent100}`,
                backgroundColor: $theme.colors.accent100,
                margin: $theme.sizing.scale800,
                borderRadius: $theme.sizing.scale800,
              }),
            },
          }}
        >
          <TabAccordian
            tabs={tabData}
            initialActiveTab={activeIndex ? activeIndex : 0}
          />
        </Card>
      </Block>
    </Block>
  );
};

export default Tabs;
