import { Block } from 'baseui/block';
import React from 'react';
import { InfoSection as InfoSectionType } from '../../types/benefits';
import SecondaryLink from '../link/secondary-link';
import BenefitDesktop from './benefit/benefit-desktop';
import BenefitMobile from './benefit/benefit-mobile';
import Headings from './description/headings';

declare type InfoSectionProps = {
  data: InfoSectionType;
};

const InfoSection: React.FC<InfoSectionProps> = ({ data }) => {
  return (
    <Block
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent={'center'}
      paddingTop={['scale2400', 'scale2400', 'scale2400', 'scale3200']}
    >
      <Headings h2={data.H2} h1={data.H1} description={data.Description} />
      <BenefitDesktop data={data.Benefits} />
      <BenefitMobile data={data.Benefits} />
      <Block marginTop="scale1200">
        <SecondaryLink
          label={data?.Link?.Text}
          link={data.Link.Url}
          target={'_self'}
        />
      </Block>
    </Block>
  );
};

export default InfoSection;
