import { useStyletron } from 'baseui';
import { Block } from 'baseui/block';
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid';
import { ParagraphLarge, ParagraphMedium } from 'baseui/typography';
import React from 'react';
import { InfoSection as InfoSectionData } from '../../types/solar-tech';
import { getMediaUrl } from '../../utils/get-media-url';
import PrimaryLink from '../link/primary-link';
import { CategoryMedia } from './category-media';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import SectionH1 from '../headings/section-h1';
import SectionH2 from '../headings/section-h2';

export type InfoSectionDataProps = {
  data: InfoSectionData;
};

const InfoSection: React.FC<InfoSectionDataProps> = ({ data }) => {
  const [, theme] = useStyletron();

  return (
    <FlexGrid
      flexGridColumnCount={[1, 1, 1, 2]}
      flexDirection={[
        'row-reverse',
        data.DirectionReverse ? 'row-reverse' : 'row',
      ]}
      marginLeft={['scale800', 'scale1200', 'scale1200', 'scale2400']}
      marginRight={['scale800', 'scale1200', 'scale1200', 'scale2400']}
      marginBottom={['scale2400', 'scale2400', 'scale2400', 'scale3200']}
      alignItems="center"
      justifyContent="center"
    >
      <FlexGridItem>
        <Block display={'block'}>
          <CategoryMedia
            dataDirection={data.DirectionReverse}
            url={getMediaUrl(data.Picture.data.attributes.url)}
          />
        </Block>
      </FlexGridItem>

      <FlexGridItem
        flexDirection={'column'}
        justifyContent="center"
        display={'flex'}
      >
        <Block
          paddingLeft={
            !data.DirectionReverse ? ['0', '0', '0', 'scale2400'] : '0'
          }
        >
          <Block>
            <Block>
              <SectionH1
                marginTop={['scale1600','scale1600',0]}
                marginBottom={'scale600'}
                color={theme.colors.contentAccent}
              >
                {data.H1.toUpperCase()}
              </SectionH1>
            </Block>
            <Block>
              <SectionH2 margin="0">{data.H2}</SectionH2>
            </Block>
            <ParagraphLarge
              width={['100%', '100%', '100%', '90%']}
              display={['none', 'none', 'none', 'inline-block']}
            >
              <ReactMarkdown children={data.Description} skipHtml={false} />
            </ParagraphLarge>
            <ParagraphMedium
              display={['inline-block', 'inline-block', 'inline-block', 'none']}
              width={['100%', '100%', '100%', '90%']}
            >
              <ReactMarkdown children={data.Description} skipHtml={false} />
            </ParagraphMedium>
          </Block>
          <Block
            display="flex"
            alignItems="center"
            justifyContent="center"
            width={['100%', '100%', '100%', '75%']}
          >
            <Block display="inline-block" marginTop={'scale1000'}>
              <PrimaryLink
                link={data.Link.Url}
                target="_blank"
                label={data.Link.Text}
              />
            </Block>
          </Block>
        </Block>
      </FlexGridItem>
    </FlexGrid>
  );
};

export default InfoSection;
