import { useStyletron } from 'baseui';
import { Block } from 'baseui/block';
import React, { useState } from 'react';
import { Fade } from 'react-awesome-reveal';
import { Leader as LeaderType } from '../../../../types/about-us';
import { TeamCarouselResponsiveBreakPoints } from '../../../../utils/carousel-util';
import { getMediaUrl } from '../../../../utils/get-media-url';
import Button from '../../../button/button';
import Carousel from '../../../carousel/carousel';
import Leader from '../cards/leader';

import TeamMemberMobile from '../cards/team-member-mobile-card';
import Content from '../pop-over/content/content';
import { CardsSectionProps } from './cards-section';

const MobileCardSection: React.FC<CardsSectionProps> = ({
  leaders,
  members,
}) => {
  const [showMore, setShowMore] = useState<boolean>(false);
  const mobileMaxteam = members.slice(0, 3);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [css, theme] = useStyletron();

  const handleClose = (isOpen: boolean) => {
    setIsOpen(isOpen);
  };

  const handleCardSelect = (leader: LeaderType) => {
    setCurrCard(leader);
  };

  const [currCard, setCurrCard] = useState<LeaderType>(leaders[0]);

  return (
    <>
      <Block
        width="100%"
        alignContent="center"
        justifyContent="center"
        display={isOpen ? ['flex', 'flex', 'flex', 'none'] : 'none'}
        marginTop="scale2400"
        marginBottom="scale1200"
        backgroundColor={theme.colors.accent100}
        className={css({
          borderRadius: theme.sizing.scale800,
        })}
      >
        <Fade direction={'left'} duration={800}>
          <Content leader={currCard} handleClose={handleClose} />
        </Fade>
      </Block>
      <Block
        display={!isOpen ? ['block', 'block', 'block', 'none'] : 'none'}
        width="95%"
      >
        <Carousel
          arrows={false}
          autoPlay={true}
          autoPlaySpeed={3000}
          responsive={TeamCarouselResponsiveBreakPoints}
          infinite={true}
        >
          {leaders.map((item, index) => (
            <Block key={index}>
              <Leader
                leader={item}
                key={0}
                handleCardSelect={handleCardSelect}
                handleClose={handleClose}
                isOpen={false}
              />
            </Block>
          ))}
        </Carousel>
      </Block>

      <Block
        display={['flex', 'flex', 'flex', 'none']}
        justifyContent={'center'}
        alignItems="center"
        flexDirection={'column'}
        flexWrap={true}
      >
        {showMore
          ? members.map((item, index) => (
              <Block key={index} margin="scale650">
                <TeamMemberMobile
                  url={getMediaUrl(item.Photo.data.attributes.url)}
                  name={item.Name}
                  designation={item.Designation}
                />
              </Block>
            ))
          : mobileMaxteam.map((item, index) => (
              <Block key={index} marginTop="scale1200">
                <TeamMemberMobile
                  url={getMediaUrl(item.Photo.data.attributes.url)}
                  name={item.Name}
                  designation={item.Designation}
                />
              </Block>
            ))}
      </Block>
      <Block
        display={['flex', 'flex', 'flex', 'none']}
        width="100%"
        flex={1}
        justifyContent="center"
        marginTop="scale1200"
      >
        <Button
          kind="primary"
          onClick={() => {
            setShowMore(!showMore);
          }}
        >
          SHOW MORE
        </Button>
      </Block>
    </>
  );
};

export default MobileCardSection;
