import { useStyletron } from 'baseui';
import { Block } from 'baseui/block';
import React from 'react';

const Overlay = () => {
  const [css] = useStyletron();

  return (
    <>
      <Block
        height={['100%']}
        width={'100%'}
        position="absolute"
        bottom={0}
        className={css({
          background: `rgba(30, 45, 83, 0.5);`,
          pointerEvents: 'none',
        })}
      />
    </>
  );
};

export default Overlay;
