import React from 'react';
import { IconBaseProps } from './icon-base-props';

export const BiPlayCircle: React.FC<IconBaseProps> = ({
  color = '#fff',
  size = '24px',
  stroke = '#fff',
  ...props
}) => {
  return (
    <svg
      stroke={stroke}
      fill={color}
      strokeWidth="0"
      viewBox="0 0 24 24"
      aria-hidden="true"
      height={size}
      width={size}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <svg
        stroke="currentColor"
        fill="currentColor"
        strokeWidth="0"
        viewBox="0 0 24 24"
        height="200px"
        width="200px"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2zm0 18c-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8-3.589 8-8 8z"></path>
        <path d="m9 17 8-5-8-5z"></path>
      </svg>
    </svg>
  );
};

export default BiPlayCircle;
