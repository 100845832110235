import { Block } from 'baseui/block';
import React, { useState } from 'react';
import theme from '../../page-layouts/theme';
import { useStyletron } from 'baseui';
import { InformativeBanner } from '../../types/homepage';
import CountDownTimer from './countdown';
import PrimaryLink from '../link/primary-link';
import RxCross2 from '../icons/rx-cross-2';

export type DiscountOfferProps = {
  data: InformativeBanner[];
};

const DiscountOfferPopup: React.FC<DiscountOfferProps> = ({ data }) => {
  const [css] = useStyletron();
  const [showPopup, setShowPopup] = useState(true);

  const closePopup = () => {
    setShowPopup(false);
  };

  return (
    <>
      {data.map(popup => {
        if (popup.Active && showPopup ) {
          return (
            <Block
              display={'flex'}
              justifyContent={'space-between'}
              backgroundColor={theme.colors.backgroundPrimary}
              alignItems={['flex-start','center','center','center']}
              position="relative"
            >
              <Block
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
                flex="1"
                flexDirection={['column', 'row', 'row']}
              >
                <Block
                  display={'flex'}
                  flexDirection={['column', 'row', 'row']}
                  alignItems={'center'}
                  className={css({ textAlign: 'center' })}
                >
                  <Block font={['LabelXSmall','LabelLarge','HeadingXSmall','HeadingXSmall']} color={'white'} marginTop={['scale400', 0]}>
                    {popup.Description}
                  </Block>
                  <CountDownTimer offerDateTime={popup.CountDown} />
                  <Block
                    display={'flex'}
                    padding={[
                      'scale200',
                      `${theme.sizing.scale550} ${theme.sizing.scale800}`,
                    ]}
                  >
                    <PrimaryLink
                      link={popup.Link.Url}
                      label={popup.Link.Text}
                      target="__blank"
                    />
                  </Block>
                </Block>
              </Block>
              {popup.CanDismiss && (
                <Block
                  display={'flex'}
                  marginRight={['scale800','scale1200', 'scale700']}
                  marginTop={['scale400', '0', '0', '0']}
                  onClick={closePopup}
                  color={'white'}
                  className={css({ cursor: 'pointer' })}
                >
                  <RxCross2 size={25} />
                </Block>
              )}
            </Block>
          );
        }
        return null;
      })}
    </>
  );
};

export default DiscountOfferPopup;
