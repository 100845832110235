import { useStyletron } from 'baseui';
import { Block } from 'baseui/block';
import React from 'react';
import theme from '../../../page-layouts/theme';
import { Banner } from '../../../types';
import SectionH1 from '../../headings/section-h1';
import SectionH2 from '../../headings/section-h2';

export type HeadingProps = {
  banner: Banner;
};

const HeadingsDesktop: React.FC<HeadingProps> = ({ banner }) => {
  const [css] = useStyletron();

  return (
    <Block
      display={['none', 'none', 'none', 'flex']}
      flexDirection="column"
      alignItems={'center'}
      justifyContent="center"
    >
      <SectionH1
        marginBottom={'scale950'}
        className={css({
          color: theme.colors.contentAccent,
        })}
      >
        {banner.H1.toUpperCase()}
      </SectionH1>
      <SectionH2
        className={css({ textAlign: 'center' })}
        margin={0}
        justifyContent="center"
      >
        {banner.H2}
      </SectionH2>
    </Block>
  );
};

export default HeadingsDesktop;
