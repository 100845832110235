import { Block } from 'baseui/block';
import { ParagraphMedium } from 'baseui/typography';
import React from 'react';
import { useStyletron } from 'baseui';

export type TabTitleProps = {
  icon: string;
  isActive: boolean;
  title: string;
};

export const TabTitle: React.FC<TabTitleProps> = ({
  icon,
  isActive,
  title,
}) => {
  const [css, theme] = useStyletron();

  return (
    <Block display={['none', 'none', 'none', 'block']} maxWidth="200px">
      <Block className={css({ textAlign: 'center' })} marginTop="scale600">
        <img
          src={icon}
          alt="icon"
          className={css({ width: '54px', height: '50px' })}
        />
      </Block>
      <ParagraphMedium
        paddingBottom={'scale300'}
        className={css({
          borderBottom: isActive
            ? `${theme.sizing.scale0} solid ${theme.colors.contentAccent}`
            : 0,
          textAlign: 'center',
        })}
      >
        {title}
      </ParagraphMedium>
    </Block>
  );
};
