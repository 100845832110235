import * as React from 'react';
import { SEO } from '../components';
import PageLayout from '../page-layouts';
import { HomePageServiceImpl } from '../services/page/home-page.service';
import Homepage from '../components/homepage/homepage';
import { Homepage as HomepageData } from '../types/homepage';
import { useEffect, useState } from 'react';
import SuccessPopup from '../components/pop-up-flow/success-popup';
import OfferPopup from '../components/pop-up-flow/offer-popup';
import { SOME_INTERVAL_IN_MILLIS } from '../utils/timer-utils';

declare type IndexPageProps = {
  serverData: any;
};

const IndexPage: React.FC<IndexPageProps> = ({ serverData }) => {
  const homepage = serverData.homePageData as HomepageData;
  const [showDiscountPopup, setShowDiscountPopup] = useState(false);
  const [successPopupOpen, setSuccessPopupOpen] = useState(false);
  const isDiscountPopupShown = typeof window !== 'undefined' && window.sessionStorage.getItem('ShowOfferPopup') === 'true';

  const closeDiscountModal = () => {
    setShowDiscountPopup(false);
  };

  const closeSuccessPopupModal = () => {
    setSuccessPopupOpen(false);
  };

  useEffect(() => {
    if (!isDiscountPopupShown) {
      const discountPopupTimer = setTimeout(() => {
        setShowDiscountPopup(true);
      }, SOME_INTERVAL_IN_MILLIS);

      return () => {
        clearTimeout(discountPopupTimer);
      };
    }
  }, []);

  return (
    <PageLayout>
      {homepage.data.attributes.seo && <SEO data={homepage.data.attributes.seo} />}
      <Homepage homepage={homepage} />
      {showDiscountPopup && homepage.data.attributes.DiscountPopup && (
        <OfferPopup
          data={homepage.data.attributes.DiscountPopup}
          closeModal={closeDiscountModal}
          isOpen={showDiscountPopup}
          setSuccessPopupOpen={setSuccessPopupOpen}
          showNameInput={true}
          showPhoneInput={new Date().getHours() % 2 === 0 ? true : false}
          showEmailInput={new Date().getHours() % 2 === 0 ? false : true}
        />
      )}
      {successPopupOpen && <SuccessPopup isOpen={successPopupOpen} closeModal={closeSuccessPopupModal} />}
    </PageLayout>
  );
};

export const getServerData = async () => {
  const serviceObject = new HomePageServiceImpl();
  const { data } = await serviceObject.getHomePage();
  return {
    props: {
      homePageData: data,
    },
  };
};

export default IndexPage;

