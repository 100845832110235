import { Block } from 'baseui/block';
import React from 'react';
import theme from '../../page-layouts/theme';
import SectionH1 from '../headings/section-h1';
import SectionH2 from '../headings/section-h2';

type HeadingsProps = {
  h1: string;
  h2: string;
  centered?: boolean;
};

const Headings: React.FC<HeadingsProps> = ({ h1, h2, centered = true }) => {
  return (
    <Block
      display={'flex'}
      flexDirection="column"
      alignItems={centered ? 'center' : 'start'}
    >
      <SectionH1 color={theme.colors.contentAccent} marginBottom={'scale600'}>
        {h1.toUpperCase()}
      </SectionH1>
      <SectionH2 margin={0}>{h2}</SectionH2>
    </Block>
  );
};

export default Headings;
