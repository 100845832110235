import { useStyletron } from 'baseui';
import { Block } from 'baseui/block';
import React from 'react';

interface ErrorProps {
  errorMessage: string;
  usernameForm?: boolean;
  bottom?: string;
  top?: string;
  left?: string;
  display?: boolean;
}

const ErrorMessage: React.FC<ErrorProps> = ({
  errorMessage,
  bottom,
  top,
  left,
  display = true,
}) => {
  const [css, theme] = useStyletron();
  return (
    <>
      <Block
        position="relative"
        display={display ? 'inline' : 'none'}
        marginLeft="0px"
        bottom={bottom}
        left="0"
        top="0"
        color="red"
        className={css({
          letterSpacing: '0.01em',
          fontSize: '12px',
          [theme.mediaQuery.small]: {
            top: top,
            left: left,
            fontSize: '1rem',
          },
        })}
      >
        {errorMessage}
      </Block>
    </>
  );
};

export default ErrorMessage;
