import { useStyletron } from 'baseui';
import { Block } from 'baseui/block';
import React from 'react';

const Overlay = () => {
  const [css, theme] = useStyletron();

  return (
    <>
      <Block
        height={['80%']}
        width={'100%'}
        position="absolute"
        bottom={0}
        className={css({
          background: `linear-gradient(0deg, ${theme.colors.backgroundPrimary},${theme.colors.mono100})`,
          pointerEvents: 'none',
        })}
      />
      <Block
        height={'100%'}
        width={'100%'}
        className={css({
          background: 'rgba(30, 45, 83, 0.2)',
          pointerEvents: 'none',
        })}
      />
    </>
  );
};

export default Overlay;
