import { Block } from 'baseui/block';
import React, { useState } from 'react';
import { SEO } from '../components';
import { VideoPageBanner } from '../components/banner/video-page-banner/video-page-banner';
import FooterSection from '../components/footer';
import NavBar from '../components/nav-bar/nav-bar';
import VideoCategoryList from '../components/video-library/video-category-list';
import VideoDescription from '../components/video-library/video-description';
import VimeoPlayer from '../components/video-library/vimeo-player';
import PageLayout from '../page-layouts';
import theme from '../page-layouts/theme';
import { HomePageServiceImpl } from '../services/page/home-page.service';
import { VideosLibraryPageServiceImpl } from '../services/page/videos-library-page.service';
import { Homepage } from '../types/homepage';
import { VideoLibrary as VideoLibraryData } from '../types/video-library';

declare type VideoLibraryProps = {
  serverData: any;
};

const serviceObject = new VideosLibraryPageServiceImpl();

const VideoLibrary: React.FC<VideoLibraryProps> = ({ serverData }) => {
  const videoLibraryPageData = serverData.videoLibraryPageData as VideoLibraryData;
  const homePageData = serverData.homePageData as Homepage;
  const [isOpen, setIsOpen] = React.useState(false);
  const [currentVideo, setVideo] = useState<number>();
  const handleVideoSelect = (video: number) => {
    setVideo(video);
    setIsOpen(true);
  };

  return (
    <PageLayout>
      {videoLibraryPageData.data.attributes.seo && <SEO data={videoLibraryPageData.data.attributes.seo} />}
      <NavBar position={'fixed'} data={homePageData.data.attributes.Header} />
      <Block
        display={'flex'}
        flexDirection="column"
        backgroundColor={theme.colors.backgroundPrimary}
        position={'relative'}
      >
        <Block width={'100%'}>
          <VideoPageBanner
            handleVideoSelect={handleVideoSelect}
            banner={videoLibraryPageData.data.attributes.Banner}
          />
        </Block>
        <Block>
          <VideoDescription
            video={videoLibraryPageData.data.attributes.Description}
          />
        </Block>
        <Block
          paddingLeft={['', '', 'scale800', 'scale1200']}
          paddingRight={['', '', 'scale800', 'scale1200']}
          flexDirection={'column'}
        >
          <VideoCategoryList
            handleVideoSelect={handleVideoSelect}
            data={videoLibraryPageData.data.attributes.Categories}
          />
        </Block>
      </Block>
      <FooterSection data={homePageData.data.attributes.Footer} />
      {currentVideo && isOpen && (
        <VimeoPlayer
          video={currentVideo}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
        />
      )}
    </PageLayout>
  );
};

export default VideoLibrary;

export const getServerData = async () => {
  const homeServiceObject = new HomePageServiceImpl();

  const res = await Promise.all([
    serviceObject.getVideoLibraryPage(),
    homeServiceObject.getCommonData(),
  ]);

  return {
    props: {
      videoLibraryPageData: res[0].data,
      homePageData: res[1].data,
    },
  };
};
