import { useStyletron } from 'baseui';
import { Block } from 'baseui/block';
import React from 'react';
import SectionH2 from '../../headings/section-h2';
import { HeadingProps } from './headings-desktop';
import SectionH1 from '../../headings/section-h1';

const HeadingsMobile: React.FC<HeadingProps> = ({ banner }) => {
  const [css, theme] = useStyletron();

  return (
    <Block
      display={['flex', 'flex', 'flex', 'none']}
      flexDirection="column"
      margin="scale600"
      paddingLeft={['', '', 'scale800', '']}
      paddingRight={['', '', 'scale800', '']}
    >
      <SectionH1
        marginBottom={'scale950'}
        className={css({
          color: theme.colors.contentAccent,
        })}
      >
        {banner.H1.toUpperCase()}
      </SectionH1>
      <SectionH2 margin={0} className={css({ fontFamily: 'Bitter' })}>
        {banner.H2}
      </SectionH2>
    </Block>
  );
};

export default HeadingsMobile;
