import { Block } from 'baseui/block';
import React, { useEffect, useState } from 'react';
import Button from '../../../button/button';
import Leader from '../cards/leader';
import Member from '../cards/member';
import { CardsSectionProps } from './cards-section';
import {
  Leader as LeaderType,
  Member as MemberType,
} from '../../../../types/about-us';
import Content from '../pop-over/content/content';
import { useStyletron } from 'baseui';
import { Fade } from 'react-awesome-reveal';

const DesktopCardSection: React.FC<CardsSectionProps> = ({
  leaders,
  isOpen,
  members,
}) => {
  const [css, theme] = useStyletron();
  const [showMore, setShowMore] = useState<boolean>(false);
  const desktopMaxteam = members.slice(0, 12 - leaders.length);
  const [showLeader, setshowLeader] = useState<boolean>(false);
  const [currMembers, setcurrMembers] = useState<MemberType[]>(desktopMaxteam);
  const [currLeaderCard, setCurrLeaderCard] = useState<LeaderType>(leaders[0]);

  const handleClose = (data: boolean) => {
    setshowLeader(isOpen ? false : data ? true : false);
  };
  const [end, setEnd] = useState<number>(12 - leaders.length);

  React.useMemo(() => setcurrMembers(members.slice(0, end)), [members, end]);

  useEffect(() => {
    !isOpen && setshowLeader(false);
  }, [isOpen]);

  const handleCardSelect = (leader: LeaderType) => {
    setCurrLeaderCard(leader);
  };

  return (
    <Block>
      <Block
        width="90%"
        alignContent="center"
        justifyContent="center"
        display={showLeader ? ['none', 'none', 'flex', 'flex'] : 'none'}
        marginTop="scale2400"
        marginBottom="scale1200"
        backgroundColor={theme.colors.accent100}
        className={css({
          borderRadius: theme.sizing.scale800,
        })}
        marginLeft={['scale800', 'scale1600', 'scale2400', '']}
      >
        <Fade direction={'left'} duration={500}>
          <Content leader={currLeaderCard} handleClose={handleClose} />
        </Fade>
      </Block>
      <Block
        flexDirection={'row'}
        flexWrap={true}
        width="100%"
        alignContent="center"
        justifyContent="center"
        display={['none', 'none', 'none', 'flex']}
      >
        {leaders.map((item, index) => (
          <Block margin="scale1200" display={!showLeader ? 'block' : 'none'}>
            <Leader
              leader={item}
              key={index}
              isOpen={showLeader}
              handleCardSelect={handleCardSelect}
              handleClose={handleClose}
            />
          </Block>
        ))}

        {currMembers.map((item, index) => (
          <Block key={index} margin="scale1200">
            <Member member={item} />
          </Block>
        ))}
      </Block>

      <Block
        display={['none', 'none', 'none', 'flex']}
        width="100%"
        flex={1}
        justifyContent="center"
      >
        <Button
          kind="primary"
          onClick={() => {
            setShowMore(!showMore);
            setEnd(prev => prev + 12 - leaders.length);
          }}
        >
          SHOW MORE
        </Button>
      </Block>
    </Block>
  );
};

export default DesktopCardSection;
