import { Block } from 'baseui/block';
import React from 'react';
import { SEO } from '../components';
import { CommonBanner } from '../components/banner/common-banner/common-banner';
import Description from '../components/benefits/description/description';
import FooterSection from '../components/footer';
import NavBar from '../components/nav-bar/nav-bar';
import PageLayout from '../page-layouts';
import theme from '../page-layouts/theme';
import { BenefitsPageServiceImpl } from '../services/page/benefits-of-solar.service';
import { HomePageServiceImpl } from '../services/page/home-page.service';
import { BenefitsPage as BenifitsPageType } from '../types/benefits';
import { Homepage } from '../types/homepage';
import InfoSection from '../components/benefits/info-section';

declare type BenefitsOfSolarProps = {
  serverData: any;
};

const BenefitsOfSolar: React.FC<BenefitsOfSolarProps> = ({ serverData }) => {
  const benefitsPage = serverData.benefitsPageData as BenifitsPageType;
  const homePage = serverData.homePageData as Homepage;

  return (
    <PageLayout>
      {benefitsPage.data.attributes.seo && <SEO data={benefitsPage.data.attributes.seo} />}
      <NavBar data={homePage.data.attributes.Header} position={'fixed'} />
      <CommonBanner
        banner={benefitsPage.data.attributes.Banner}
        redirectToQuote={true}
      />
      <Block
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        backgroundColor={theme.colors.backgroundPrimary}
        paddingRight={['scale800', 'scale800', 'scale1600', 'scale2400']}
        paddingLeft={['scale800', 'scale800', 'scale1600', 'scale2400']}
        paddingBottom={['scale800', 'scale800', 'scale1600', 'scale3200']}
        paddingTop={['scale2400', 'scale2400', 'scale2400', 'scale3200']}
        overflow="hidden"
      >
        <Description data={benefitsPage.data.attributes.Description} />
        <InfoSection data={benefitsPage.data.attributes.InfoSection} />
      </Block>
      <FooterSection data={homePage.data.attributes.Footer} />
    </PageLayout>
  );
};

export const getServerData = async () => {
  const befinitsPageserviceObject = new BenefitsPageServiceImpl();
  const homePageServiceObject = new HomePageServiceImpl();

  const res = await Promise.all([
    befinitsPageserviceObject.getBenefits(),
    homePageServiceObject.getHomePage(),
  ]);

  return {
    props: {
      benefitsPageData: res[0].data,
      homePageData: res[1].data,
    },
  };
};

export default BenefitsOfSolar;
