import React from 'react';
import { Card, StyledBody } from 'baseui/card';
import { useStyletron } from 'baseui';
import { Block } from 'baseui/block';
import {  MonoHeadingLarge } from 'baseui/typography';
import { Video } from '../../types/video-library';
import { getMediaUrl } from '../../utils/get-media-url';

declare type VideoCardProps = {
  key: string;
  onClick: (video: number) => void;
  video: Video;
};

const VideoCard: React.FC<VideoCardProps> = ({ onClick, video, key }) => {
  const [, theme] = useStyletron();

  const animationUrl =
    video.Animation?.data?.attributes.url ||
    video.Thumbnail.data.attributes.url;

  return (
    <Block key={key} margin={['scale300', 'scale300', 'scale300', 'scale600']}>
      <Card
        overrides={{
          Root: {
            props: {
              onClick: () => onClick(video.VimeoId),
            },
            style: {
              backgroundImage: `url(${getMediaUrl(
                video.Thumbnail.data.attributes.url,
              )})`,
              backgroundSize: 'cover !important',
              borderRadius: theme.sizing.scale400,
              height: '250px',
              display: 'flex',
              width: '100%',
              flex: 1,
              alignItems: 'flex-end',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              border: 'none',
              ':hover': {
                backgroundImage: `url(${getMediaUrl(animationUrl)})`,
                cursor: 'pointer',
              },
            },
          },
          Body: {
            style: {
              width: '60%',
            },
          },
        }}
      >
        <StyledBody>
          <MonoHeadingLarge
            overrides={{
              Block: {
                style: {
                  fontSize: theme.sizing.scale850,
                  lineHeight: theme.sizing.scale850,
                  marginBottom: 0,
                  marginTop: 0,
                  fontFamily: 'Avenir',
                },
              },
            }}
          >
            {video.Title}
          </MonoHeadingLarge>
        </StyledBody>
      </Card>
    </Block>
  );
};

export default VideoCard;

