import { Block } from 'baseui/block';
import React, { useState } from 'react';
import { useStyletron } from 'styletron-react';
import theme from '../../../page-layouts/theme';
import { Location } from '../../../types/homepage';
import AddBill from './add-bill';
import LocationSelection from './location-selection';
import { Button } from 'baseui/button';
import {
  useQueryParam,
  useStringifyQueryParam,
} from '../../../context/use-query-string';
import AiOutlineArrowRight from '../../icons/ai-outline-arrow-right';

const MobileView: React.FC = () => {
  const [css] = useStyletron();
  const [selected, setSelected] = useState(1);
  const [bill, setBill] = useState(100);
  const [location, setLocation] = useState<Location>();
  const [billChange, setBillChange] = useState(false);
  const [showBillError, setShowBillError] = useState(false);
  const [showLocationError, setShowLocationError] = useState(false);
  const handleNext = () => {
    if ((selected === 1 && !billChange) || !bill) {
      setShowBillError(true);
      return;
    } else if (selected === 2 && !location) {
      setShowLocationError(true);
      return;
    } else if (selected >= 2) {
      redirectToWebsite();
      return;
    }
    setSelected(selected + 1);
  };

  const queryParams = {
    utm_campaign: useQueryParam('utm_campaign'),
    utm_medium: useQueryParam('utm_medium'),
    utm_source: useQueryParam('utm_source'),
    utm_content: useQueryParam('utm_content'),
    utm_term: useQueryParam('utm_term'),
    gclid: useQueryParam('gclid'),
  };
  let url = useStringifyQueryParam(queryParams);

  const redirectToWebsite = () => {
    if (!bill || !location) return;
    window.location.href = `quote/form?bill=${bill}&location=${location?.location}&utilityCompany=${location?.utilityCompanyId}&${url}`;
  };

  return (
    <Block
      width={'300px'}
      className={css({
        borderRadius: theme.sizing.scale700,
        overflow: 'hidden',
      })}
      display={['block', 'none']}
      justifyContent={'center'}
      alignItems={'center'}
      position={'absolute'}
      backgroundColor={theme.colors.mono600}
    >
      {selected === 1 && (
        <Block marginTop={'scale800'} marginBottom={'scale600'}>
          <AddBill
            bill={bill}
            billChange={billChange}
            setBillChange={setBillChange}
            showError={showBillError}
            setBill={setBill}
            selected={selected === 1}
            isMobile
          />
        </Block>
      )}
      {selected === 2 && (
        <Block>
          <LocationSelection
            location={location}
            showError={showLocationError}
            setLocation={setLocation}
            changeView={() => setSelected(1)}
            selected={selected === 2}
            isMobile={true}
          />
        </Block>
      )}

      <Block>
        <Button
          aria-label={
            selected === 1 ? 'Choose Utility Company' : 'PRICE MY SYSTEM'
          }
          overrides={{
            BaseButton: {
              style: {
                width: '100%',
                ':hover': {
                  backgroundColor: theme.colors.primary,
                },
              },
            },
          }}
          endEnhancer={<AiOutlineArrowRight />}
          onClick={handleNext}
        >
          {selected === 1 ? 'Choose Utility Company' : 'PRICE MY SYSTEM'}
        </Button>
      </Block>
    </Block>
  );
};

export default MobileView;
