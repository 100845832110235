import { useStyletron } from 'baseui';
import { AspectRatioBox, AspectRatioBoxBody } from 'baseui/aspect-ratio-box';
import { Block } from 'baseui/block';
import { Card, StyledBody } from 'baseui/card';
import { ParagraphMedium } from 'baseui/typography';
import { navigate } from 'gatsby';
import React from 'react';
import { useWindow } from '../../context/use-window';
import theme from '../../page-layouts/theme';
import { Option } from '../../types/contact-us';
import { getMediaUrl } from '../../utils/get-media-url';
import PrimaryLink from '../link/primary-link';

type ShortcutProps = {
  callable: boolean;
  logoId: number;
  option: Option;
};

const ShortcutCard: React.FC<ShortcutProps> = ({
  callable,
  logoId,
  option,
}) => {
  const [css] = useStyletron();
  const { deviceType } = useWindow();
  return (
    <Card
      overrides={{
        Root: {
          style: {
            border: 0,
            marginTop: theme.sizing.scale600,
            marginBottom: theme.sizing.scale600,
            marginLeft: deviceType === 'desktop' ? theme.sizing.scale600 : '0 ',
            marginRight:
              deviceType === 'desktop' ? theme.sizing.scale600 : '0 ',
            backgroundColor: theme.colors.accent300,
            minWidth: '280px',
          },
        },
      }}
    >
      <StyledBody
        className={css({
          margin: '0px !important',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        })}
      >
        <AspectRatioBox
          width={theme.sizing.scale3200}
          aspectRatio={2 / 1}
          marginTop="scale600"
          className={css({
            cursor:'pointer'
          })}
        >
          <AspectRatioBoxBody
            as="img"
            alt="card"
            src={getMediaUrl(option.Icon.data.attributes.url)}
            onClick={() => {
              logoId === 1 && navigate('/');
              logoId === 2 && navigate('http://shine.sunnyenergysolar.com/');
            }}
          />
        </AspectRatioBox>

        <ParagraphMedium
          margin="scale300"
          className={css({
            fontFeatureSettings: `'pnum' on, 'lnum' on`,
          })}
        >
          {option.H1}
        </ParagraphMedium>
        <ParagraphMedium
          margin="scale300"
          className={css({
            fontFeatureSettings: `'pnum' on, 'lnum' on`,
          })}
        >
          {option.H2}
        </ParagraphMedium>
        <Block width="scale4800" margin={'scale650'} paddingTop={'scale650'}>
          <PrimaryLink
            label={option.Link.Text}
            link={callable ? `tel:${option.Link.Url}` : option.Link.Url}
            target="__blank"
          />
        </Block>
      </StyledBody>
    </Card>
  );
};

export default ShortcutCard;
