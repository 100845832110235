import { Block } from 'baseui/block';
import React from 'react';
import theme from '../../page-layouts/theme';
import { formatCountDown } from '../../utils/timer-utils';
import { HeadingXSmall, ParagraphSmall } from 'baseui/typography';
import useCountDown from '../../hooks/use-count-down';
import CountDownBlock from './countdown-block';

type CountDownTimerProps = {
  offerDateTime: Date;
};

const circleStyle = {
  display: 'flex',
  margin: theme.sizing.scale400,
  fontVariantNumeric: 'lining-nums proportional-nums',
};

const CountDownTimer: React.FC<CountDownTimerProps> = ({ offerDateTime }) => {
  const countDown = useCountDown(offerDateTime);

  return (
    <Block
      display={'flex'}
      alignItems={'center'}
      marginLeft={['0', '0', 'scale200', 'scale200']}
    >
      {countDown.days ? (
        <CountDownBlock value={countDown.days} label={'Days'} />
      ) : null}
      {countDown.hours > 0 ? (
        <CountDownBlock value={countDown.hours} label={'Hrs'} />
      ) : (
        countDown.days > 0 && (
          <CountDownBlock value={countDown.hours} label={'Hrs'} />
        )
      )}
      {countDown.minutes ? (
        <CountDownBlock value={countDown.minutes} label={'Mins'} />
      ) : (
        countDown.hours > 0 && (
          <CountDownBlock value={countDown.minutes} label={'Mins'} />
        )
      )}
      {countDown.seconds ? (
        <Block style={circleStyle}>
          <Block
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            flexDirection={'column'}
          >
            <HeadingXSmall color={theme.colors.accent} margin={'auto'}>
              {formatCountDown(countDown.seconds)}
            </HeadingXSmall>
            <ParagraphSmall margin={'auto'}>Secs</ParagraphSmall>
          </Block>
        </Block>
      ) : (
        countDown.minutes > 0 && (
          <Block style={circleStyle}>
            <Block
              display={'flex'}
              justifyContent={'center'}
              alignItems={'center'}
              flexDirection={'column'}
            >
              <HeadingXSmall color={theme.colors.accent} margin={'auto'}>
                {formatCountDown(countDown.seconds)}
              </HeadingXSmall>
              <ParagraphSmall margin={'auto'}>Secs</ParagraphSmall>
            </Block>
          </Block>
        )
      )}
    </Block>
  );
};

export default CountDownTimer;
