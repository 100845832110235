import { Block } from 'baseui/block';
import React from 'react';
import RxCross2 from '../icons/rx-cross-2';

interface CustomCloseIconProps {
  onClick: () => void;
}

const CustomCloseIcon: React.FC<CustomCloseIconProps> = ({ onClick }) => {
  return (
    <Block
      overrides={{
        Block: {
          style: {
            position: 'absolute',
            top: '10px',
            right: '10px',
            cursor: 'pointer',
            color: 'black',
          },
        },
      }}
      onClick={onClick}
    >
      <RxCross2 size={25} fontWeight={'bold'} />
    </Block>
  );
};

export default CustomCloseIcon;
