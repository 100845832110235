import { Block } from 'baseui/block';
import { LabelMedium, MonoHeadingLarge, MonoHeadingMedium } from 'baseui/typography';
import React from 'react';
import theme from '../../../../page-layouts/theme';
import { HeadingProps } from './headings-desktop';

const HeadingsMobile: React.FC<HeadingProps> = ({ description, h1, h2 }) => {
  return (
    <Block
      display={['flex', 'flex', 'flex', 'none']}
      flexDirection="column"
      margin="scale600"
    >
      <MonoHeadingMedium color={theme.colors.contentAccent}>
        {h1.toUpperCase()}
      </MonoHeadingMedium>
      <MonoHeadingLarge marginTop={['scale700']} margin={0}>
        {h2}
      </MonoHeadingLarge>
      {description && (
        <LabelMedium marginTop={'scale600'}>{description}</LabelMedium>
      )}
    </Block>
  );
};

export default HeadingsMobile;
