import * as React from 'react';
import { SEO } from '../components';
import PageLayout from '../page-layouts';
import NavBar from '../components/nav-bar/nav-bar';
import { Homepage } from '../types/homepage';
import { HomePageServiceImpl } from '../services/page/home-page.service';
import { SolarTechPage } from '../types/solar-tech';
import { solarTechPageServiceImpl } from '../services/page/solar-tech.service';
import { Block } from 'baseui/block';
import theme from '../page-layouts/theme';
import FooterSection from '../components/footer';
import InfoSection from '../components/solar-tech/info-section';
import { CommonBanner } from '../components/banner/common-banner/common-banner';
import Category from '../components/solar-tech/category';

declare type SolarTechProps = {
  serverData: any;
};

const SolarTech: React.FC<SolarTechProps> = ({ serverData }) => {
  const homepage = serverData.homePageData as Homepage;
  const solarTechPageData = serverData.solarTechPageData as SolarTechPage;

  return (
    <PageLayout>
      {solarTechPageData.data.attributes.seo && <SEO data={solarTechPageData.data.attributes.seo} />}
      <NavBar position={'fixed'} data={homepage.data.attributes.Header} />
      <CommonBanner banner={solarTechPageData.data.attributes.Banner} />
      <Block
        display={'flex'}
        flexDirection="column"
        position={'relative'}
        backgroundColor={theme.colors.backgroundPrimary}
        paddingTop={['scale400', 'scale400', 'scale2400', 'scale3200']}
      >
        {solarTechPageData.data.attributes.InfoSection.map((data, index) => (
          <InfoSection data={data} key={index} />
        ))}
        <Block
          marginLeft={['', 'scale1600', 'scale1600', 'scale1200']}
          marginRight={['', 'scale1600', 'scale1600', 'scale1200']}
        >
          <Category
            data={solarTechPageData.data.attributes.Category}
            cards={solarTechPageData.data.attributes.ProductCards}
          />
        </Block>
      </Block>
      <FooterSection data={homepage.data.attributes.Footer} />
    </PageLayout>
  );
};

export const getServerData = async () => {
  const homeServiceObject = new HomePageServiceImpl();
  const serviceObject = new solarTechPageServiceImpl();

  const res = await Promise.all([
    serviceObject.getSolarTechPage(),
    homeServiceObject.getHomePage(),
  ]);

  return {
    props: {
      solarTechPageData: res[0].data,
      homePageData: res[1].data,
    },
  };
};

export default SolarTech;
