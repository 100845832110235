import { useStyletron } from 'baseui';
import { BlockProps } from 'baseui/block';
import { MonoHeadingXLarge } from 'baseui/typography';
import React, { PropsWithChildren } from 'react';

type CommonBannerH2Props = BlockProps &
  PropsWithChildren & {
    className?: string;
    fontFamily?: string;
  };

const CommonBannerH2: React.FC<CommonBannerH2Props> = ({
  children,
  className,
  fontFamily,
  ...props
}) => {
  const [css, theme] = useStyletron();
  return (
    <MonoHeadingXLarge
      {...props}
      className={`${className} ${css({
        fontSize: `${theme.sizing.scale1000} !important`,
        fontFamily: fontFamily || 'Bitter !important',
        margin: 0,
      })}`}
    >
      {children}
    </MonoHeadingXLarge>
  );
};

export default CommonBannerH2;
