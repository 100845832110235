import { useStyletron } from 'baseui';
import { Block } from 'baseui/block';
import { ParagraphLarge } from 'baseui/typography';
import React from 'react';
import SectionH1 from '../../headings/section-h1';
import SectionH2 from '../../headings/section-h2';

declare type HeadingsProps = {
  h1: string;
  h2: string;
  description: string;
};

const Headings: React.FC<HeadingsProps> = ({ h1, h2, description }) => {
  const [css, theme] = useStyletron();

  return (
    <Block
      display="flex"
      flexDirection={'column'}
      alignItems="center"
      justifyContent="center"
    >
      <SectionH1
        marginTop="0"
        color={theme.colors.contentAccent}
        className={css({
          textAlign: 'center',
        })}
      >
        {h1.toUpperCase()}
      </SectionH1>
      <SectionH2
        marginTop="0"
        display={['none', 'none', 'none', 'flex']}
        className={css({
          textAlign: 'center',
        })}
        alignItems="center"
        justifyContent="center"
        width={['100%', '100%', '100%', '100%']}
      >
        {h2}
      </SectionH2>
      <ParagraphLarge
        display={['none', 'none', 'none', 'flex']}
        marginBottom="scale1200"
        width={['100%', '100%', '100%', '75%']}
        className={css({
          textAlign: 'center',
          color: theme.colors.mono600,
        })}
      >
        {description}
      </ParagraphLarge>
    </Block>
  );
};

export default Headings;
