import { Tab, Tabs } from 'baseui/tabs';
import React, { ReactNode } from 'react';
import theme from '../../page-layouts/theme';
import { getMediaUrl } from '../../utils/get-media-url';
import { TabTitle } from './tab-title';

export type CustomTabType = {
  children: ReactNode;
  iconActive: string;
  icon: string;
  id: number;
  title: string;
};

export type TabsProps = {
  initialActiveTab: string;
  tabs: CustomTabType[];
};

const CustomTabs: React.FC<TabsProps> = ({ tabs, initialActiveTab }) => {
  const [activeKey, setActiveKey] = React.useState<React.Key>(initialActiveTab);

  return (
    <Tabs
      activeKey={activeKey}
      onChange={({ activeKey }) => {
        setActiveKey(activeKey);
      }}
      overrides={{
        TabBar: {
          style: {
            paddingLeft: theme.sizing.scale2400,
            paddingRight: theme.sizing.scale1200,
            justifyContent: 'center',
            backgroundColor: theme.colors.tabBg,
            borderRadius: theme.sizing.scale700,
            margin: 'auto',
          },
        },
        TabContent: {
          style: {
            padding: '0px',
          },
        },
        Tab: {
          style: {
            paddingRight: theme.sizing.scale1600,
            borderBottom: `${theme.sizing.scale0} solid ${theme.colors.borderTransparent}`,
          },
        },
      }}
    >
      {tabs.map(tab => {
        const isActive = activeKey.toLocaleString() === `${tab.id}`;
        const iconUrl = isActive ? tab.iconActive : tab.icon;
        return (
          <Tab
            key={tab.id}
            title={
              <TabTitle
                icon={getMediaUrl(iconUrl)}
                isActive={isActive}
                title={tab.title}
              />
            }
          >
            {tab.children}
          </Tab>
        );
      })}
    </Tabs>
  );
};

export default CustomTabs;

