import { useStyletron } from 'baseui';
import { Block } from 'baseui/block';
import React from 'react';
import { Story as StoryType } from '../../../types/about-us';
import Headings from '../headings';
import StatisticsSection from './statistics';

declare type StorySectionProps = {
  data: StoryType;
};

const Story: React.FC<StorySectionProps> = ({ data }) => {
  const [, theme] = useStyletron();

  return (
    <Block
      display="flex"
      flexDirection={[
        'column-reverse',
        'column-reverse',
        'column-reverse',
        'column',
      ]}
      justifyContent="center"
      alignItems="center"
      backgroundColor={theme.colors.backgroundPrimary}
      paddingTop={['scale800', 'scale1600', 'scale1600', 'scale2400']}
    >
      <Block
        paddingLeft={['scale800', 'scale1600', 'scale2400', 'scale4800']}
        paddingRight={['scale800', 'scale1600', 'scale2400', 'scale4800']}
      >
        <Headings h2={data.H2} description={data.Description} h1={data.H1} />
      </Block>
      <StatisticsSection data={data.Statistics} />
    </Block>
  );
};

export default Story;

