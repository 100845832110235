import { useStyletron } from 'baseui';
import { Block } from 'baseui/block';
import { DisplaySmall, HeadingSmall } from 'baseui/typography';
import React from 'react';
import { useWindow } from '../../../../../context/use-window';
import Markdown from '../../../../mark-down/mark-down';
import { closeButtonStyles } from '../styles';
import RiCloseFill from '../../../../icons/ri-close-fill';

declare type PersonDescriptionProps = {
  description: string;
  name: string;
  handleClose: (isOpen: boolean) => void;
};

const PersonDescription: React.FC<PersonDescriptionProps> = ({
  description,
  name,
  handleClose,
}) => {
  const [css, theme] = useStyletron();
  const { deviceType } = useWindow();

  return (
    <Block marginLeft={['scale650', 'scale650', 'scale650', 'scale1200']}>
      <Block display="flex" justifyContent="space-between">
        <DisplaySmall
          display={['none', 'none', 'none', 'flex']}
          marginTop="scale1200"
        >
          {name}
        </DisplaySmall>
        <HeadingSmall
          display={['flex', 'flex', 'flex', 'none']}
          marginTop={['scale200', 'scale200', 'scale200', '']}
          marginBottom="0"
        >
          {name}
        </HeadingSmall>
        <Block display={['none', 'none', 'none', 'flex']}>
          <RiCloseFill
            onClick={() => {
              handleClose(false);
            }}
            className={css({
              ...closeButtonStyles,
            })}
          />
        </Block>
      </Block>
      <Block
        maxHeight={'350px'}
        margin={`${theme.sizing.scale900} 0px`}
        overflow="scrollY"
        width="95%"
      >
        <Markdown
          data={description}
          fontSize={
            deviceType === 'desktop'
              ? theme.sizing.scale800
              : theme.sizing.scale650
          }
          lineHeight={
            deviceType === 'desktop'
              ? theme.sizing.scale1000
              : theme.sizing.scale950
          }
        />
      </Block>
    </Block>
  );
};

export default PersonDescription;
