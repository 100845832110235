import React, { useEffect, useRef } from 'react';
import { useWindow } from '../../context/use-window';

type VideoPlayerProps = {
  url: string;
  className?: string;
};

const VideoPlayer: React.FC<VideoPlayerProps> = ({ url, className }) => {
  const { documentLoaded } = useWindow();
  const playerRef = useRef<any>(null);
  const nameArray = url.split('.');
  const ext = nameArray?.[nameArray.length - 1];

  useEffect(() => {
    if (documentLoaded && playerRef.current) {
      (playerRef.current as HTMLVideoElement)?.play();
    }
  }, [documentLoaded, playerRef.current]);

  return (
    <>
      <video
        muted
        ref={playerRef}
        loop
        className={className}
      >
        <source src={url} type={`video/${ext}`} />
      </video>
    </>
  );
};

export default VideoPlayer;
