import { useStyletron } from 'baseui';
import { BlockProps } from 'baseui/block';
import { MonoHeadingXXLarge } from 'baseui/typography';
import React from 'react';

const MonoHeadingXXlargeMobile: React.FC<React.PropsWithChildren<
  BlockProps & {
    className?: string;
  }
>> = ({ children, className, ...props }) => {
  const [css] = useStyletron();
  return (
    <MonoHeadingXXLarge
      {...props}
      className={`${className} ${css({
        fontSize: '40px  !important',
        fontWeight: 600,
        lineHeight: '48px',
      })}`}
    >
      {children}
    </MonoHeadingXXLarge>
  );
};

export default MonoHeadingXXlargeMobile;
