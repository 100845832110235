import { useStyletron } from 'baseui';
import { Block } from 'baseui/block';
import { Card } from 'baseui/card';
import React from 'react';
import { useWindow } from '../../context/use-window';
import theme from '../../page-layouts/theme';
import { Quote } from '../../types/homepage';
import QuotationDesktop from '../quotation/desktop/quotation-desktop';
import QuotationMobile from '../quotation/quotation-mobile';
import SectionSnapY from '../scroll-snap-y/section-snap-y';

type QuotationProps = {
  data: Quote;
};

const Quotation: React.FC<QuotationProps> = ({ data }) => {
  const [css] = useStyletron();
  const { deviceType } = useWindow();
  return (
    <>
      <SectionSnapY display={['none', 'none', 'none', 'flex']}>
        <QuotationDesktop data={data} />
      </SectionSnapY>
      <Block
        backgroundColor={[
          theme.colors.accent100,
          theme.colors.accent100,
          theme.colors.accent100,
          theme.colors.backgroundPrimary,
        ]}
        padding={['0px', 'scale600', 'scale600', 'scale600']}
        paddingTop="scale1600"
        paddingBottom="scale1600"
        display={['block', 'block', 'block', 'none']}
        className={css({
          borderRadius:
            deviceType === 'mobile' || deviceType === 'tablet' ? '20px' : '0px',
        })}
        margin={theme.sizing.scale650}
      >
        <Card
          overrides={{
            Root: {
              style: () => ({
                border: `none`,
                borderRadius: theme.sizing.scale1000,
                paddingTop: theme.sizing.scale400,
                backgroundColor: theme.colors.accent100,
              }),
            },
          }}
        >
          <QuotationMobile data={data} />
        </Card>
      </Block>
    </>
  );
};

export default Quotation;
