export const formatCountDown = (timer: number) => {
  if (timer < 10) {
    return `0${timer}`;
  }
  return timer;
};

export const ONE_SECOND = 1000;
export const ONE_MINUTE = ONE_SECOND * 60;
export const ONE_HOUR = ONE_MINUTE * 60;
export const ONE_DAY = ONE_HOUR * 24;
export const SOME_INTERVAL_IN_MILLIS = 5000;
