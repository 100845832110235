import React from 'react';
import { IconBaseProps } from './icon-base-props';

export const ChevronCircleUp: React.FC<IconBaseProps> = ({
  color = '#F26C1BD9',
  size = '24px',
  ...props
}) => {
  return (
    <svg
    width={32}
    height={32}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.0563 16.3906C10.9324 16.5146 10.834 16.6618 10.7669 16.8237C10.6998 16.9857 10.6653 17.1593 10.6653 17.3346C10.6653 17.51 10.6998 17.6836 10.7669 17.8455C10.834 18.0075 10.9324 18.1547 11.0563 18.2786C11.1803 18.4026 11.3275 18.5009 11.4894 18.568C11.6514 18.6351 11.825 18.6697 12.0003 18.6697C12.1756 18.6697 12.3492 18.6351 12.5112 18.568C12.6732 18.5009 12.8204 18.4026 12.9443 18.2786L16.0003 15.22L19.0563 18.2786C19.1803 18.4026 19.3275 18.5009 19.4894 18.568C19.6514 18.6351 19.825 18.6697 20.0003 18.6697C20.1756 18.6697 20.3492 18.6351 20.5112 18.568C20.6732 18.5009 20.8204 18.4026 20.9443 18.2786C21.0683 18.1547 21.1666 18.0075 21.2337 17.8455C21.3008 17.6836 21.3353 17.51 21.3353 17.3346C21.3353 17.1593 21.3008 16.9857 21.2337 16.8237C21.1666 16.6618 21.0683 16.5146 20.9443 16.3906L16.9443 12.3906C16.8205 12.2665 16.6733 12.168 16.5113 12.1007C16.3494 12.0335 16.1757 11.9989 16.0003 11.9989C15.8249 11.9989 15.6513 12.0335 15.4893 12.1007C15.3273 12.168 15.1802 12.2665 15.0563 12.3906L11.0563 16.3906ZM2.66699 16.0013C2.66699 19.5375 4.07175 22.9289 6.57223 25.4294C9.07272 27.9299 12.4641 29.3346 16.0003 29.3346C19.5365 29.3346 22.9279 27.9299 25.4284 25.4294C27.9289 22.9289 29.3337 19.5375 29.3337 16.0013C29.3337 12.4651 27.9289 9.0737 25.4284 6.57321C22.9279 4.07273 19.5365 2.66797 16.0003 2.66797C12.4641 2.66797 9.07272 4.07273 6.57223 6.57321C4.07175 9.0737 2.66699 12.4651 2.66699 16.0013ZM16.0003 26.668C13.1713 26.668 10.4582 25.5442 8.45785 23.5438C6.45747 21.5434 5.33366 18.8303 5.33366 16.0013C5.33366 13.1723 6.45747 10.4592 8.45785 8.45883C10.4582 6.45844 13.1713 5.33464 16.0003 5.33464C18.8293 5.33464 21.5424 6.45844 23.5428 8.45883C25.5432 10.4592 26.667 13.1723 26.667 16.0013C26.667 18.8303 25.5432 21.5434 23.5428 23.5438C21.5424 25.5442 18.8293 26.668 16.0003 26.668Z"
      fill="#F26C1B"
      fillOpacity={0.85}
    />
  </svg>
  );
};

export default ChevronCircleUp;
