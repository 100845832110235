import { useStyletron } from 'baseui';
import { Block } from 'baseui/block';
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid';
import { ParagraphMedium } from 'baseui/typography';
import React from 'react';
import { KeyFeature } from '../../types/solar-tech';
import { FeatureMedia } from '../tool-features/feature-media';
import PrimaryLink from '../link/primary-link';
import SectionH1 from '../headings/section-h1';
import SectionH2 from '../headings/section-h2';
import HiArrowNarrowRight from '../icons/hi-arrow-narrow-right';

export type CategoryFeatureType = {
  buttonText: string;
  buttonLink: string;
  description: string;
  heading: string;
  title: string;
  feature: KeyFeature[];
  image: string;
};

export type CategoryFeatureProps = {
  data: CategoryFeatureType;
};

const CategoryFeature: React.FC<CategoryFeatureProps> = ({ data }) => {
  const [, theme] = useStyletron();

  return (
    <FlexGrid
      flexGridColumnCount={[1, 2]}
      flexGridColumnGap="scale800"
      flexGridRowGap="scale800"
      height="100%"
      padding={[0, 0, 0, 'scale1000']}
    >
      <FlexGridItem height="550px">
        <Block paddingLeft={['', '', '', 'scale800']}>
          <Block>
            <SectionH1 color={theme.colors.contentAccent}>
              {data.heading.toUpperCase()}
            </SectionH1>
            <SectionH2>{data.title}</SectionH2>
          </Block>
          <ParagraphMedium marginTop={'scale1000'}>
            {data.description}
          </ParagraphMedium>
          <ul>
            {data.feature.map((data, index) => (
              <li key={index}>{data.Discription}</li>
            ))}
          </ul>
          <Block
            marginTop={'scale1200'}
            display={['none', 'flex', 'flex', 'flex']}
          >
            <PrimaryLink
              label={data.buttonText}
              link={data.buttonLink}
              hasQueryParams={true}
            />
          </Block>
        </Block>
      </FlexGridItem>
      <FlexGridItem>
        <FeatureMedia url={data.image} />
        <Block
          marginTop={['35%', '', '', '']}
          display={['flex', 'none', 'none', 'none']}
          width="100%"
          alignItems={'center'}
          justifyContent="center"
        >
          <PrimaryLink
            endEnhancer={<HiArrowNarrowRight width={24} height={20} />}
            label={data.buttonText}
            link={data.buttonLink}
            hasQueryParams={true}
          />
        </Block>
      </FlexGridItem>
    </FlexGrid>
  );
};

export default CategoryFeature;

