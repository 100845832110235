import React from 'react';
import { IconBaseProps } from './icon-base-props';

export const ChevronCircleDown: React.FC<IconBaseProps> = ({
  color = '#F26C1BD9',
  size = '24px',
  stroke = '#fff',
  ...props
}) => {
  return (
    <svg
    width={32}
    height={32}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.0563 15.6094C10.9324 15.4854 10.834 15.3382 10.7669 15.1763C10.6998 15.0143 10.6653 14.8407 10.6653 14.6654C10.6653 14.49 10.6998 14.3164 10.7669 14.1545C10.834 13.9925 10.9324 13.8453 11.0563 13.7214C11.1803 13.5974 11.3275 13.4991 11.4894 13.432C11.6514 13.3649 11.825 13.3303 12.0003 13.3303C12.1756 13.3303 12.3492 13.3649 12.5112 13.432C12.6732 13.4991 12.8204 13.5974 12.9443 13.7214L16.0003 16.78L19.0563 13.7214C19.1803 13.5974 19.3275 13.4991 19.4894 13.432C19.6514 13.3649 19.825 13.3303 20.0003 13.3303C20.1756 13.3303 20.3492 13.3649 20.5112 13.432C20.6732 13.4991 20.8204 13.5974 20.9443 13.7214C21.0683 13.8453 21.1666 13.9925 21.2337 14.1545C21.3008 14.3164 21.3353 14.49 21.3353 14.6654C21.3353 14.8407 21.3008 15.0143 21.2337 15.1763C21.1666 15.3382 21.0683 15.4854 20.9443 15.6094L16.9443 19.6094C16.8205 19.7335 16.6733 19.832 16.5113 19.8993C16.3494 19.9665 16.1757 20.0011 16.0003 20.0011C15.8249 20.0011 15.6513 19.9665 15.4893 19.8993C15.3273 19.832 15.1802 19.7335 15.0563 19.6094L11.0563 15.6094ZM2.66699 15.9987C2.66699 12.4625 4.07175 9.07109 6.57223 6.57061C9.07272 4.07012 12.4641 2.66537 16.0003 2.66537C19.5365 2.66537 22.9279 4.07012 25.4284 6.57061C27.9289 9.07109 29.3337 12.4625 29.3337 15.9987C29.3337 19.5349 27.9289 22.9263 25.4284 25.4268C22.9279 27.9273 19.5365 29.332 16.0003 29.332C12.4641 29.332 9.07272 27.9273 6.57223 25.4268C4.07175 22.9263 2.66699 19.5349 2.66699 15.9987ZM16.0003 5.33203C13.1713 5.33203 10.4582 6.45584 8.45785 8.45623C6.45747 10.4566 5.33366 13.1697 5.33366 15.9987C5.33366 18.8277 6.45747 21.5408 8.45785 23.5412C10.4582 25.5416 13.1713 26.6654 16.0003 26.6654C18.8293 26.6654 21.5424 25.5416 23.5428 23.5412C25.5432 21.5408 26.667 18.8277 26.667 15.9987C26.667 13.1697 25.5432 10.4566 23.5428 8.45623C21.5424 6.45584 18.8293 5.33203 16.0003 5.33203Z"
      fill="#F26C1B"
      fillOpacity={0.85}
    />
  </svg>
  );
};

export default ChevronCircleDown;
