import { Block } from 'baseui/block';
import React from 'react';
import PageLayout from '../page-layouts';
import theme from '../page-layouts/theme';
import { HomePageServiceImpl } from '../services/page/home-page.service';
import { Homepage as HomepageData } from '../types/homepage';
import NavBar from '../components/nav-bar/nav-bar';
import { SEO } from '../components';
import { CommonBanner } from '../components/banner/common-banner/common-banner';
import Quotation from '../components/projects/quotation';
import ReviewsAndAwards from '../components/reviews-and-awards/reviews-and-awards';
import FooterSection from '../components/footer';
import { GetGuideSection } from '../components/get-guide-section/get-guide-section';
import InfoSection from '../components/solar-tech/info-section';
import GoSolar from '../components/projects/go-solar';
import { LandingPage } from '../types/landing';
import Content from '../components/blog/content/content';

declare type IndexPageProps = {
  serverData: any;
};

const LandingPageTemplate: React.FC<IndexPageProps> = ({
  serverData,
  ...props
}) => {
  const landingPageData: LandingPage= props.pageContext.landingPagesData;
  const homePageData = serverData.homePageData as HomepageData;  
  return (
    <PageLayout>
      <Block
        backgroundColor={theme.colors.backgroundPrimary}
        width="100%"
        overflow={'hidden'}
      >
        {landingPageData.seo && <SEO data={landingPageData.seo} />}
        <NavBar data={homePageData.data.attributes.Header} position="fixed" />
        <CommonBanner banner={landingPageData.Banner} />
        <Block
          backgroundColor={theme.colors.backgroundPrimary}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          marginBottom="scale1200"
        >
          <Content content={landingPageData.Content} />
        </Block>
        <Quotation data={homePageData.data.attributes.Quote} />
        {landingPageData.InfoSection.map(item => (
          <Block paddingTop="scale3200">
            <InfoSection data={item} />
          </Block>
        ))}
        <GoSolar data={landingPageData.SolarInstallation} />
        <ReviewsAndAwards
          awards={homePageData.data.attributes.Awards}
          reviews={homePageData.data.attributes.Reviews}
        />
        <GetGuideSection
          data={homePageData.data.attributes.GetSolarGuideSection}
        />
        <FooterSection data={homePageData.data.attributes.Footer} />
      </Block>
    </PageLayout>
  );
};

export default LandingPageTemplate;

export const getServerData = async () => {
  const serviceObject = new HomePageServiceImpl();
  const { data } = await serviceObject.getHomePage();
  return {
    props: {
      homePageData: data,
    },
  };
};
