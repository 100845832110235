import React, { FC, useState } from 'react';
import { Block } from 'baseui/block';
import { Banner } from '../../../types';
import { getMediaUrl } from '../../../utils/get-media-url';
import Overlay from './overlay';
import { useStyletron } from 'baseui';
import PrimaryLink from '../../link/primary-link';
import HeadingsDesktop from './common-headings/headings-desktop';
import HeadingsMobile from './common-headings/headings-mobile';
import VimeoPlayer from '../../video-library/vimeo-player';
import Button from '../../button/button';
import RiArrowRightLine from '../../icons/ri-arrow-right-line';

export declare type CommonBannerProps = {
  banner: Banner;
  redirectToQuote?: boolean;
};

export const CommonBanner: FC<CommonBannerProps> = ({
  banner,
  redirectToQuote,
}) => {
  const [css] = useStyletron();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <>
      <Block
        height={['100vh', '100vh', '70vh', '90vh']}
        width="100%"
        display="flex"
        justifyContent={'center'}
        alignItems="center"
        position={'relative'}
        backgroundSize="cover"
        backgroundPosition="center"
        backgroundImage={`url(${getMediaUrl(
          banner.Background.data.attributes.url,
        )})`}
      >
        <Overlay />
        <Block
          position={'absolute'}
          justifyContent={'center'}
          alignItems="center"
          width={'100%'}
        >
          <Block
            display="flex"
            alignItems={'center'}
            flexDirection="column"
            margin={['auto', 'auto', 'auto', 'scale4800']}
            padding={['auto', 'auto', 'auto', 'scale1200']}
            className={css({ textAlign: 'center' })}
          >
            <HeadingsDesktop
              h1={banner.H1}
              h2={banner.H2}
              description={banner.Description}
            />
            <HeadingsMobile
              h1={banner.H1}
              h2={banner.H2}
              description={banner.Description}
            />
            {banner?.Link?.Text && (
              <Block marginTop={'scale1400'}>
                {['/', 'https', 'http', 'tel'].some(word =>
                  banner.Link.Url.startsWith(word),
                ) ? (
                  <PrimaryLink
                    label={banner.Link.Text.toUpperCase()}
                    link={banner.Link.Url}
                    target={
                      banner.Link.Url.startsWith('tel') ? '_blank' : '_self'
                    }
                    endEnhancer={
                      <Block marginLeft={'scale400'}>
                        <RiArrowRightLine size={20} />
                      </Block>
                    }
                    hasQueryParams={redirectToQuote}
                  />
                ) : (
                  <Button
                    kind="primary"
                    onClick={() => {
                      setIsOpen(true);
                    }}
                    endEnhancer={
                      <Block marginLeft={'scale400'}>
                        <RiArrowRightLine size={20} />
                      </Block>
                    }
                  >
                    {banner.Link.Text}
                  </Button>
                )}
              </Block>
            )}
          </Block>
        </Block>
      </Block>

      {isOpen && (
        <VimeoPlayer
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          video={parseInt(banner.Link.Url)}
        />
      )}
    </>
  );
};
