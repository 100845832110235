import * as React from 'react';
import { SEO } from '../components';
import PageLayout from '../page-layouts';
import NavBar from '../components/nav-bar/nav-bar';
import { Homepage } from '../types/homepage';
import { HomePageServiceImpl } from '../services/page/home-page.service';
import { quoteServiceImpl } from '../services/page/quote-tool.service';
import { Block } from 'baseui/block';
import theme from '../page-layouts/theme';
import FooterSection from '../components/footer';
import ToolFeatures from '../components/tool-features/tool-features';
import { ToolPageBanner } from '../components/banner/tool-page/tool-page-banner';
import { ToolFeaturesData } from '../types/tool-features';

declare type IndexPageProps = {
  serverData: any;
};

const QuoteTool: React.FC<IndexPageProps> = ({ serverData }) => {
  const homepage = serverData.homePageData as Homepage;
  const quoteTool = serverData.quoteToolData as ToolFeaturesData;

  return (
    <PageLayout>
      {quoteTool.data.attributes.seo && <SEO data={quoteTool.data.attributes.seo} />}
      <NavBar position={'fixed'} data={homepage.data.attributes.Header} />
      <Block
        backgroundColor={theme.colors.backgroundPrimary}
        paddingLeft={['0', 'scale1600', 'scale1600', 'scale2400']}
        paddingRight={['0', 'scale1600', 'scale1600', 'scale2400']}
        paddingBottom="scale1200"
      >
        <Block marginBottom="scale1200">
          <ToolPageBanner
            data={quoteTool.data.attributes.Banner}
            showIllustration
            isQuoteTool ={true}
          />
        </Block>
        <ToolFeatures
          data={quoteTool.data.attributes.Features}
          quotePortal={true}
        />
      </Block>
      <FooterSection data={homepage.data.attributes.Footer} />
    </PageLayout>
  );
};

export const getServerData = async () => {
  const homeServiceObject = new HomePageServiceImpl();
  const serviceObject = new quoteServiceImpl();

  const res = await Promise.all([
    serviceObject.getquoteTool(),
    homeServiceObject.getCommonData(),
  ]);

  return {
    props: {
      quoteToolData: res[0].data,
      homePageData: res[1].data,
    },
  };
};

export default QuoteTool;

