import { useStyletron } from 'baseui';
import { AspectRatioBox, AspectRatioBoxBody } from 'baseui/aspect-ratio-box';
import { Block } from 'baseui/block';
import React from 'react';

declare type ImageSectionProps = {
  url: string;
};

const ImageSection: React.FC<ImageSectionProps> = ({ url }) => {
  const [, theme] = useStyletron();

  return (
    <Block
      justifyContent="center"
      alignItems="center"
      display="block"
      marginTop="scale1200"
    >
      <AspectRatioBox aspectRatio={16 / 9}>
        <AspectRatioBoxBody
          as="img"
          alt="solar-benefit"
          src={url}
          overrides={{
            Block: {
              style: {
                height: '100%',
                width: '100%',
                borderRadius: theme.sizing.scale600,
                objectFit: 'cover',
              },
            },
          }}
        />
      </AspectRatioBox>
    </Block>
  );
};

export default ImageSection;
