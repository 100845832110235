import React from 'react';
import { HeadingXSmall, LabelSmall, ParagraphMedium } from 'baseui/typography';
import { useStyletron } from 'baseui';
import { AspectRatioBox, AspectRatioBoxBody } from 'baseui/aspect-ratio-box';
import { Block } from 'baseui/block';
import { Blog } from '../../types/news-and-blogs';
import TertiaryLink from '../link/tertiary-link';
import { getRouteUrl } from '../../utils/get-route';
import { navigate } from 'gatsby';
import SectionH2 from '../headings/section-h2';
import HiArrowUpRight from '../icons/hi-arrow-up-right';

type BlogsCardProps = Blog & {
  imageHeight: string;
  slug: string;
};

const BlogsCard: React.FC<BlogsCardProps> = ({
  Banner,
  Description,
  H1,
  H2,
  imageHeight = 'auto',
  PublishedDate,
  slug,
}) => {
  const [css, theme] = useStyletron();

  return (
    <Block display={'flex'} flexDirection="column">
      <AspectRatioBox
        aspectRatio={2 / 1}
        minHeight={['auto', 'auto', 'auto', imageHeight]}
        onClick={() => navigate(`/blogs/${getRouteUrl(slug)}`)}
      >
        <AspectRatioBoxBody
          as="img"
          alt="blog"
          src={Banner}
          className={css({
            height: '100%',
            width: '100%',
            borderRadius: theme.sizing.scale400,
            cursor: 'pointer',
          })}
        />
      </AspectRatioBox>
      <Block
        marginTop={'scale800'}
        display={'flex'}
        justifyContent="space-between"
        alignItems={'baseline'}
        onClick={() => navigate(`/blogs/${getRouteUrl(slug)}`)}
      >
        <HeadingXSmall
          display={['none', 'none', 'none', 'flex']}
          color={theme.colors.contentAccent}
          margin={0}
        >
          {H1?.toUpperCase()}
        </HeadingXSmall>
        <LabelSmall
          display={['flex', 'flex', 'flex', 'none']}
          color={theme.colors.contentAccent}
          margin={0}
        >
          {H1?.toUpperCase()}
        </LabelSmall>
        <ParagraphMedium
          margin={0}
          className={css({ fontFeatureSettings: `'pnum' on, 'lnum' on` })}
        >
          {PublishedDate}
        </ParagraphMedium>
      </Block>

      <SectionH2
        margin={0}
        className={css({
          fontSize: `${theme.sizing.scale800} !important`,
        })}
        onClick={() => navigate(`/blogs/${getRouteUrl(slug)}`)}
      >
        {H2}
      </SectionH2>
      <ParagraphMedium
        color={theme.colors.white}
        width="100%"
        height="auto"
        className={css({
          wordWrap: 'break-word',
        })}
        onClick={() => navigate(`/blogs/${getRouteUrl(slug)}`)}
      >
        {Description}
      </ParagraphMedium>

      <TertiaryLink
        link={`/blogs/${getRouteUrl(slug)}`}
        className={css({
          ':hover': {
            cursor: 'pointer',
          },
          padding: '0',
        })}
      >
        <LabelSmall color={theme.colors.accent} display={'flex'}>
          Read More
          <HiArrowUpRight
            size={15}
            className={css({
              color: theme.colors.accent,
            })}
          />
        </LabelSmall>
      </TertiaryLink>
    </Block>
  );
};

export default BlogsCard;
