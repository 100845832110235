import React, { Dispatch } from 'react';

import NumberPicker from '../../number-picker/number-picker';
import { Block } from 'baseui/block';
import { LabelMedium, LabelSmall } from 'baseui/typography';
import theme from '../../../page-layouts/theme';
import { useStyletron } from 'baseui';
import FieldEmptyError from './field-empty-error';
import BiChevronUp from '../../icons/bi-chevron-up';
import BiChevronDown from '../../icons/bi-chevron-down';

export type AddBillProps = {
  bill: number;
  billChange: boolean;
  setBillChange: Dispatch<React.SetStateAction<boolean>>;
  showError: boolean;
  changeView?: () => void;
  selected?: boolean;
  setBill: Dispatch<number>;
  isMobile?: boolean;
};

const AddBill: React.FC<AddBillProps> = ({
  bill,
  billChange,
  setBillChange,
  showError,
  setBill,
  selected,
  changeView,
  isMobile,
}) => {
  const [css] = useStyletron();

  const arrows = () =>
    selected ? (
      <BiChevronUp
        className={css({ marginLeft: theme.sizing.scale200 })}
        size={24}
        color={theme.colors.accent}
      />
    ) : (
      <BiChevronDown
        className={css({ marginLeft: theme.sizing.scale200 })}
        size={24}
        color={theme.colors.accent}
      />
    );

  const heading = (
    <Block>
      <Block
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        flexDirection={'column'}
        onClick={changeView}
        className={css({ cursor: 'pointer' })}
      >
        <Block>
          <LabelMedium
            width={isMobile ? 'auto' : '155px'}
            color={theme.colors.accent50}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'start'}
          >
            Electric bill
            {isMobile ? '' : arrows()}
          </LabelMedium>
          <LabelSmall color={theme.colors.accent200}>
            {!billChange ? (
              'Choose Monthly usage'
            ) : (
              <Block display={'flex'} alignItems={'center'}>
                <Block
                  color={theme.colors.accent}
                  marginRight={'scale0'}
                  className={css({
                    fontFeatureSettings: `'pnum' on, 'lnum' on`,
                  })}
                >
                  $ {bill}
                </Block>
                per month
              </Block>
            )}
          </LabelSmall>
        </Block>
      </Block>
      <Block>
        {!bill || !billChange && showError ? (
          <FieldEmptyError errorText='Please select Electricity Bill' />
        ) : (
          null
        )}
      </Block>
    </Block>
  );

  const content = (
    <Block marginTop={'scale1000'} width={'200px'}>
      <NumberPicker
        max={1000}
        min={100}
        defaultValue={bill}
        onChange={v => {
          setBillChange(true);
          setBill(v);
        }}
      />
      <Block
        display={'flex'}
        justifyContent={'space-between'}
        paddingRight={'scale600'}
        paddingLeft={'scale600'}
        className={css({
          fontFeatureSettings: `'pnum' on, 'lnum' on`,
        })}
      >
        <LabelSmall color={theme.colors.black}>$ 100</LabelSmall>
        <LabelSmall color={theme.colors.black}>$ 1000</LabelSmall>
      </Block>
    </Block>
  );

  if (isMobile) {
    return (
      <Block display={'flex'} flexDirection={'column'} alignItems={'center'}>
        <Block
          className={css({
            fontWeight: 700,
            fontSize: theme.sizing.scale650,
            fontFamily: 'Raleway',
          })}
          color={theme.colors.black}
          marginBottom={'scale400'}
        >
          Price your System
        </Block>
        {heading}
        {content}
      </Block>
    );
  }

  return selected ? (
    <Block display={'flex'} flexDirection={'column'} alignItems={'center'}>
      {heading}
      {content}
    </Block>
  ) : (
    heading
  );
};

export default AddBill;
