import { useStyletron } from 'baseui';
import { Block } from 'baseui/block';
import React, { PropsWithChildren, useEffect, useRef } from 'react';
import { FILE_TYPE, getMediatype } from '../../utils/get-media-type';
import VideoPlayer from '../video-player/video-player';

declare type BannerWrapperProps = {
  backgroundUrl: string;
  height?: string;
};

const BannerWrapper: React.FC<PropsWithChildren<BannerWrapperProps>> = ({
  backgroundUrl,
  children,
  height,
}) => {
  const fileType = getMediatype(backgroundUrl);
  const [css] = useStyletron();

  return (
    <>
      {fileType === FILE_TYPE.IMAGE && (
        <Block
          height={height ? height : '100vh'}
          width="100%"
          minHeight={height ? height : '100vh'}
          display="flex"
          justifyContent={'center'}
          alignItems={['start', 'center']}
          position={'relative'}
          backgroundSize="cover"
          backgroundPosition="center"
          backgroundImage={`url(${backgroundUrl})`}
        >
          {children}
        </Block>
      )}
      {fileType === FILE_TYPE.VIDEO && (
        <Block
          width={'100%'}
          height={'100vh'}
          position={'relative'}
          overflow={'hidden'}
          minHeight={'100vh'}
          display="flex"
          justifyContent={'center'}
          alignItems={['start', 'center']}
        >
          <VideoPlayer
            url={backgroundUrl}
            className={css({
              minWidth: '100%',
              position: 'absolute',
              minHeight: '100%',
            })}
          />
          {children}
        </Block>
      )}
    </>
  );
};

export default BannerWrapper;
