import { Block } from 'baseui/block';
import React, { useState } from 'react';

interface LazyBackgroundImageProps {
  img: string;
  children?: JSX.Element[] | JSX.Element;
  style?: React.CSSProperties;
}

const LazyBackgroundImage: React.FC<LazyBackgroundImageProps> = ({
  img,
  children,
  style,
}) => {
  const [loaded, setLoaded] = useState(false);

  const handleLoad = () => {
    setLoaded(true);
  };

  return (
    <Block
      style={{
        backgroundImage: `url(${img})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        ...style,
      }}
    >
      <img src={img} alt="" onLoad={handleLoad} style={{ display: 'none' }} />
      {loaded && children}
    </Block>
  );
};

export default LazyBackgroundImage;
