import { Block } from 'baseui/block';
import { LabelLarge, ParagraphMedium } from 'baseui/typography';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import theme from '../../page-layouts/theme';
import { useStyletron } from 'baseui';

const Error: React.FC = () => {
  const [css] = useStyletron();
  
  return (
    <Block
      height={['100vh', '100vh', '70vh', '90vh']}
      width="100%"
      display="flex"
      flexDirection={'column'}
      justifyContent={'center'}
      alignItems="center"
      position={'relative'}
      backgroundSize="cover"
      backgroundPosition="center"
      color={'white'}
    >
      <Block display={['none', 'none', 'flex', 'flex']}>
        <StaticImage
          src="../../images/404.png"
          alt="404-img"
          placeholder="none"
        />
      </Block>
      <Block display={['flex', 'flex', 'none', 'none']}>
        <StaticImage
          src="../../images/404-mobile.png"
          alt="404-img"
          placeholder="none"
        />
      </Block>
      <Block
        font={['HeadingXSmall', 'HeadingXSmall', 'HeadingXLarge']}
        marginBottom="inherit"
        marginTop={'scale850'}
      >
        We’ve lost this page
      </Block>
      <ParagraphMedium
        width={['90%', '80%', '30%']}
        className={css({ textAlign: 'center' })}
      >
        We apologise. The page you are looking for may have been moved or the
        address may have been misspelled
      </ParagraphMedium>
      <Block
        display={'flex'}
        alignItems={'center'}
        flexDirection={['column', 'column', 'row']}
      >
        <a
          href={'/'}
          style={{
            color: theme.colors.accent,
            textDecoration: 'none',
            fontFamily: 'Raleway',
            fontSize: theme.sizing.scale650,
          }}
          rel="noopener noreferrer"
        >
          Go to Sunny Energy Home Page
        </a>
        <LabelLarge marginLeft={'scale400'}>or check the spelling</LabelLarge>
      </Block>
    </Block>
  );
};

export default Error;
