import { useStyletron } from 'baseui';
import { Block } from 'baseui/block';
import { Tab, Tabs } from 'baseui/tabs';
import { LabelMedium } from 'baseui/typography';
import React from 'react';
import { BlogsByCategory } from '../../../types/blog';
import { getsubArray } from '../../../utils/array-utils';
import SubSection from './sub-section';

declare type NewsSectionProps = {
  data: BlogsByCategory[];
};

const NewsSection: React.FC<NewsSectionProps> = ({ data }) => {
  const blogs = [...data].reverse();
  const [css, theme] = useStyletron();
  const [activeKey, setActiveKey] = React.useState<React.Key>('0');

  return (
    <>
      <Block
        display={'flex'}
        justifyContent={'center'}
        marginTop={['scale600', 'scale600', 'scale600', 'scale1600']}
      >
        <Tabs
          overrides={{
            Root: {
              style: {
                width: '100%',
              },
            },
            TabBar: {
              style: {
                marginRight: theme.sizing.scale1400,
                backgroundColor: theme.colors.backgroundPrimary,
                display: 'flex',
                flexWrap: 'wrap',
                flexDirection: 'row',
                justifyContent: 'center',
              },
            },
            Tab: {
              style: {
                borderBottom: `0px`,
              },
            },
          }}
          onChange={({ activeKey }) => {
            setActiveKey(activeKey);
          }}
          activeKey={activeKey}
        >
          {blogs.map((currBlog, index) => {
            if (!currBlog) return <></>;
            const isActive = activeKey.toString() === `${index}`;
            return (
              <Tab
                key={index}
                title={
                  <Block display={'flex'}>
                    <LabelMedium
                      paddingBottom={'scale300'}
                      color={isActive ? '' : theme.colors.mono200}
                      className={css({
                        borderBottom: isActive
                          ? `${theme.sizing.scale0} solid ${theme.colors.contentAccent}`
                          : '0px',
                      })}
                    >
                      {currBlog.Category.toUpperCase()}
                    </LabelMedium>
                    <Block
                      marginLeft={'scale800'}
                      width={'scale0'}
                      height="100%"
                      backgroundColor={theme.colors.mono300}
                      display={blogs.length - 1 > index ? 'block' : 'none'}
                    >
                      &nbsp;
                    </Block>
                  </Block>
                }
              >
                {getsubArray(currBlog.Blogs, 5).map(item => (
                  <SubSection data={item} key={item.index} />
                ))}
              </Tab>
            );
          })}
        </Tabs>
      </Block>
    </>
  );
};

export default NewsSection;
