import React from 'react';
import { IconBaseProps } from './icon-base-props';

export const HiArrowUpRight: React.FC<IconBaseProps> = ({ color = '#F26C1B', size="24px", stroke = '#fff', ...props}) => {
  return (
    <svg
      stroke={stroke}
      fill={color}
      strokeWidth="0"
      viewBox="0 0 24 24"
      aria-hidden="true"
      height={size}
      width={size}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        d="M8.25 3.75H19.5a.75.75 0 01.75.75v11.25a.75.75 0 01-1.5 0V6.31L5.03 20.03a.75.75 0 01-1.06-1.06L17.69 5.25H8.25a.75.75 0 010-1.5z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

export default HiArrowUpRight;
