import { Block } from 'baseui/block';
import React from 'react';
import { useWindow } from '../../context/use-window';
import { Option } from '../../types/contact-us';
import SendMessageForm from './send-message-form';
import Shortcuts from './shortcuts';

type ExistingCustomerProps = {
  options: Option[];
};

const ExistingCustomer: React.FC<ExistingCustomerProps> = ({ options }) => {
  const { windowDimensions } = useWindow();
  const margins =
    windowDimensions.width && windowDimensions.width < 1400
      ? ['scale800', 'scale800', 'scale800', 'scale2400']
      : ['scale800', 'scale800', 'scale800', 'scale4800'];

  return (
    <>
      <Block
        width={'100%'}
        display={['none', 'none', 'none', 'flex']}
        marginTop={'scale3200'}
        marginBottom="scale1000"
        alignItems="center"
        justifyContent="center"
      >
        <Block
          flex={1}
          paddingLeft={['scale800', 'scale800', 'scale800', 'scale1200']}
          marginLeft={['scale800', 'scale800', 'scale800', 'scale1200']}
        >
          <Shortcuts options={options} />
        </Block>
        <Block
          flex={1}
          marginLeft={margins}
          marginRight={margins}
        >
          <SendMessageForm />
        </Block>
      </Block>

      <Block
        width={'100%'}
        display={['flex', 'flex', 'flex', 'none']}
        flexDirection="column-reverse"
        marginTop={'scale1000'}
        marginBottom="scale1000"
      >
        <Block flex={1} marginTop="scale1600">
          <Shortcuts options={options} />
        </Block>
        <Block flex={1}>
          <SendMessageForm />
        </Block>
      </Block>
    </>
  );
};

export default ExistingCustomer;
