import { Block } from 'baseui/block';
import { HeadingLarge, LabelSmall } from 'baseui/typography';
import React from 'react';

const ReadMoreHeadings = () => {
  return (
    <Block
      marginBottom={'scale650'}
      paddingLeft={['scale800', 'scale800', 'scale1200', 'scale2400']}
      paddingRight={['scale800', 'scale800', 'scale1200', 'scale2400']}
    >
      <HeadingLarge marginBottom={'scale600'}>Read More</HeadingLarge>
      <LabelSmall marginBottom={'scale800'}>
        The latest industry news, interviews, technologies, and resources.
      </LabelSmall>
    </Block>
  );
};

export default ReadMoreHeadings;

