import { Link, Media, MediaData } from '.';
import { Video } from './about-us';
import { Banner, Meta, Quote, Seo } from './homepage';

export class ProjectsPageData {
  data: Data;
  meta: Meta;

  constructor(json: any) {
    this.data = json.data;
    this.meta = json.meta;
  }
}

export interface Data {
  attributes: Attributes;
  id: number;
}

export interface Attributes {
  Awards: Awards[];
  Banner: Banner;
  createdAt: string;
  Installation: Installation;
  publishedAt: string;
  Work: Work;
  Quote: Quote;
  Reviews: Reviews;
  seo: Seo;
  updatedAt: string;
}

export interface Project {
  City: string;
  Media: Media;
  State: string;
}

export interface Work {
  H1: string;
  H2: string;
  Projects: Project[];
}

export interface Reviews {
  H1: string;
  H2: string;
  Reviews: {
    data: MediaData[];
  };
}

export interface Awards {
  id: number;
  Logo: Media;
  Name: string;
}

export interface Installation {
  Background: Media;
  Description: string;
  H1: string;
  H2: string;
  H3: string;
  H4: string;
  Video: Video;
  VideoName?: string;
  vimeoId?: number;
  VideoThumbnail?: Media;
  Link: Link;
}

export interface CityProjects {
  projects: Project[];
  city: string;
  state: string;
}

export interface SolarInstallation {
  H1: string;
  H2: string;
  Description: string;
}
