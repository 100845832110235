import React, { useState } from 'react';
import {
  CityProjects as CityProjectsType,
  Project,
} from '../../../types/projects';
import Carousel from '../../carousel/carousel';
import { fullScreenCarouselResponsiveBreakPoints } from '../../../utils/carousel-util';
import ProjectsSlide from './projects-slide';
import PreviewModal from './preview-modal';
import { Block } from 'baseui/block';
import { useStyletron } from 'baseui';
import theme from '../../../page-layouts/theme';
import { useWindow } from '../../../context/use-window';

export type CityProjectsProps = {
  data: CityProjectsType;
};

const CityProjects: React.FC<CityProjectsProps> = ({ data }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [projectsToShow, setProjectsToShow] = useState<Project[]>([]);
  const [css] = useStyletron();
  const { deviceType } = useWindow();
  const isDesktop = deviceType === 'desktop';
  const projectsCountPerPage = isDesktop ? 9 : 6;

  const setProjects = (projects: Project[]) => {
    setProjectsToShow(projects);
    setIsOpen(true);
  };

  let slidesData: Project[][] = [];

  for (let i = 0; i < data.projects.length; i += projectsCountPerPage) {
    slidesData[i] = [];
    for (
      let j = i;
      j < i + projectsCountPerPage && j < data.projects.length;
      j++
    ) {
      slidesData[i].push(data.projects[j]);
    }
  }

  const CustomDot = ({ index, onClick, active }: any) => {
    return (
      <Block
        position={'relative'}
        onClick={(e: any) => {
          onClick();
          e.preventDefault();
        }}
        marginBottom={`-${theme.sizing.scale600}`}
      >
        <Block
          margin={'scale600'}
          height={'scale600'}
          width={'scale600'}
          backgroundColor={theme.colors.backgroundAccent}
          className={css({
            borderRadius: theme.sizing.scale700,
            opacity: active ? 1 : 0.7,
          })}
        />
      </Block>
    );
  };

  return (
    <>
      <Carousel
        arrows={false}
        showDots
        customDot={<CustomDot />}
        responsive={fullScreenCarouselResponsiveBreakPoints}
      >
        {slidesData.map(slide => (
          <ProjectsSlide data={slide} setProjects={setProjects} />
        ))}
      </Carousel>

      <PreviewModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        projects={projectsToShow}
      />
    </>
  );
};

export default CityProjects;
