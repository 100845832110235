import { Block } from 'baseui/block';
import { useFormik } from 'formik';
import { navigate } from 'gatsby';
import React, { useState } from 'react';
import { useQueryParam } from '../../../context/use-query-string';
import { ZohoServiceImpl } from '../../../services/api/zoho.service';
import { Lead } from '../../../types';
import InfoModal, { InfoModalTypes } from '../../modal/info-modal';
import {
  ReferralFormInitialValues,
  ReferralFormvalidationSchema,
} from './form-utils';
import ReferralForm from './referral-form';

declare type ReferralFormSectionProps = {
  description: string;
};

const ReferralFormSection: React.FC<ReferralFormSectionProps> = ({
  description,
}) => {
  const [modal, setModal] = useState<InfoModalTypes>({
    isOpen: false,
  });
  const campaignName = useQueryParam('utm_campaign');
  const campaignMedium = useQueryParam('utm_medium');
  const gclid = useQueryParam('gclid');

  const formik = useFormik({
    initialValues: ReferralFormInitialValues,
    validationSchema: ReferralFormvalidationSchema,
    onSubmit: async values => {
      let message: string;
      let title: string;
      try {
        const lead: Lead = {
          campaignName,
          campaignMedium,
          description: values.message,
          email: values.email,
          firstName: values.firstName,
          lastName: values.lastName,
          phone: values.phoneNumber.toString(),
          city: values.city,
          referredByEmail: values.referredByEmail,
          referredByName: values.referredBy,
          referredByPhone: values.referredByPhone,
          referralCode: values.referralCode,
          zipCode: values.zipcode,
          leadSource: 'Website Form',
          leadSourceDetails: 'Referral',
          GCLID: gclid,
        };
        const res = await new ZohoServiceImpl().createLead(lead);
        title = res ? 'Thank you' : 'Sorry';

        message = res
          ? 'Thank you for sharing your information.'
          : 'Failed to store information, Please try again later.';
      } catch (e) {
        title = 'Sorry';
        message = 'Something went wrong, Please try again later';
      }

      title === 'Thank you'
        ? navigate('/thank-you-referral')
        : setModal({
            isOpen: true,
            title,
            message,
          });
    },
  });

  return (
    <Block
      maxWidth="900px"
      width={['90%', '90%', '100%', '70%']}
      display="flex"
    >
      <ReferralForm formik={formik} description={description} />
      <InfoModal
        message={modal.message}
        title={modal.title}
        isOpen={modal.isOpen}
        setIsOpen={value =>
          setModal({
            isOpen: value,
          })
        }
      />
    </Block>
  );
};

export default ReferralFormSection;
