import { Block } from 'baseui/block';
import React from 'react';
import { SEO } from '../components';
import AwardsLogoCarousel from '../components/awards-logo-carousel/awards-logo-carousel';
import { CommonBanner } from '../components/banner/common-banner/common-banner';
import FooterSection from '../components/footer';
import NavBar from '../components/nav-bar/nav-bar';
import GoSolar from '../components/projects/go-solar';
import Quotation from '../components/projects/quotation';
import Reviews from '../components/projects/reviews';
import Work from '../components/projects/work';
import PageLayout from '../page-layouts';
import theme from '../page-layouts/theme';
import { HomePageServiceImpl } from '../services/page/home-page.service';
import { ProjectsPageServiceImpl } from '../services/page/projects-page.service';
import { Homepage } from '../types/homepage';
import { ProjectsPageData } from '../types/projects';

declare type ProjectsAndReviewsProps = {
  serverData: any;
};

const ProjectsAndReviews: React.FC<ProjectsAndReviewsProps> = ({
  serverData,
}) => {
  const projectsPageData = serverData.projectsPageData as ProjectsPageData;
  const homePageData = serverData.homePageData as Homepage;

  return (
    <PageLayout>
      <Block backgroundColor={theme.colors.backgroundPrimary} overflow="hidden">
        {projectsPageData.data.attributes.seo && <SEO data={projectsPageData.data.attributes.seo} />}
        <NavBar position={'fixed'} data={homePageData.data.attributes.Header} />
        <Block
          display={'flex'}
          flexDirection="column"
          backgroundColor={theme.colors.backgroundPrimary}
          position={'relative'}
        >
          <Block width={'100%'}>
            <CommonBanner banner={projectsPageData.data.attributes.Banner} />
          </Block>
        </Block>
        <Work data={projectsPageData.data.attributes.Work} />
        <Reviews data={projectsPageData.data.attributes.Reviews} />
        <AwardsLogoCarousel data={projectsPageData.data.attributes.Awards} />
        <GoSolar data={projectsPageData.data.attributes.Installation} />
        <Quotation data={homePageData.data.attributes.Quote} />
        <FooterSection data={homePageData.data.attributes.Footer} />
      </Block>
    </PageLayout>
  );
};

export default ProjectsAndReviews;

export const getServerData = async () => {
  const homeServiceObject = new HomePageServiceImpl();
  const serviceObject = new ProjectsPageServiceImpl();

  const res = await Promise.all([
    serviceObject.getProjectsPage(),
    homeServiceObject.getCommonData(),
  ]);

  return {
    props: {
      projectsPageData: res[0].data,
      homePageData: res[1].data,
    },
  };
};

