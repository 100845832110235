import React, { useMemo, useState } from 'react';
import { Modal } from 'baseui/modal';
import { Block } from 'baseui/block';
import {
  HeadingLarge,
  ParagraphMedium,
  ParagraphXSmall,
} from 'baseui/typography';
import { useStyletron } from 'baseui';
import _ from 'lodash';
import { DiscountPopup } from '../../types/homepage';
import theme from '../../page-layouts/theme';
import CustomCloseIcon from './custom-close-icon';
import { formatValueToUS, isEmail, isPhoneNumber } from '../../utils/form-util';
import ErrorMessage from './error-message';
import { ZohoServiceImpl } from '../../services/api/zoho.service';
import InputComponent from '../input-field/input';
import ButtonGradient from '../button/button-gradient';
import { useQueryParam } from '../../context/use-query-string';
import { StaticImage } from 'gatsby-plugin-image';

interface OfferPopupProps {
  closeModal: () => void;
  data: DiscountPopup;
  isOpen: boolean;
  setSuccessPopupOpen: React.Dispatch<React.SetStateAction<boolean>>;
  showEmailInput?: boolean;
  showNameInput?: boolean;
  showPhoneInput?: boolean;
}

const OfferPopup: React.FC<OfferPopupProps> = props => {
  const [css] = useStyletron();
  const { data, closeModal, setSuccessPopupOpen, isOpen } = props;
  const [prevPhoneNumber, setPrevPhoneNumber] = useState('');
  const [prevEmail, setPrevEmail] = useState('');
  const [prevName, setPrevName] = useState('');
  const [errorNumber, setErrorNumber] = useState(false);
  const [errorMail, setErrorMail] = useState(false);
  const [errorName, setErrorName] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [componentState, setComponentState] = useState('DEFAULT');
  const leadSource = useQueryParam('utm_source') || 'Direct';

  const renderDescription = useMemo(
    () => () => {
      const discountDescription = data?.SubHeading || '';
      const descriptions = discountDescription.split('${break}');

      return descriptions.map((html, index) => (
        <div
          key={index}
          className="custom-paragraph"
          dangerouslySetInnerHTML={{ __html: html }}
          style={{
            margin: '3px',
            fontVariantNumeric: 'lining-nums proportional-nums',
          }}
        ></div>
      ));
    },
    [data],
  );

  const description1 = renderDescription();
  const description2 = data.Text;

  const close = () => {
    closeModal();
  };

  const handleEmail = (e: any) => {
    setComponentState('DEFAULT');
    const inputValue = e.target.value;

    if (inputValue) {
      setPrevEmail(inputValue);
      sessionStorage.setItem('prevEmail', inputValue);
    } else {
      setPrevEmail('');
    }
  };

  const invalidFormInputError = () => {
    setErrorMessage('Please enter only letters as a name.');
    setComponentState('ERROR');
  };

  const handleName = (e: any) => {
    setComponentState('DEFAULT');
    setPrevName(e.target.value.slice(0, 30));
    sessionStorage.setItem('prevName', e.target.value.slice(0, 30));
    if (!/^[a-zA-Z\s]*$/.test(e.target.value)) {
      invalidFormInputError();
    }
  };

  const handleMobile = (e: any) => {
    setComponentState('DEFAULT');
    const inputValue = e.target.value;

    if (inputValue.length >= 15) return;
    if (prevPhoneNumber || inputValue) {
      const formattedPhoneNumber = formatValueToUS(
        inputValue,
        prevPhoneNumber || '',
      );
      setPrevPhoneNumber(formattedPhoneNumber);
      sessionStorage.setItem('prevPhone', formattedPhoneNumber);
    } else {
      setPrevPhoneNumber('');
    }
  };

  const ShowOfferPopupFlag = () => {
    sessionStorage.setItem('ShowOfferPopup', 'true');
  };

  const handleSubmit = async () => {
    if (
      props.showPhoneInput &&
      (!prevPhoneNumber || !isPhoneNumber(prevPhoneNumber))
    ) {
      setErrorNumber(true);
      setComponentState('ERROR');
      setErrorMessage('Please enter a valid Arizona phone number');
      return;
    } else if (props.showEmailInput && (!prevEmail || !isEmail(prevEmail))) {
      setErrorMail(true);
      setComponentState('ERROR');
      setErrorMessage('Enter a valid Email');
      return;
    } else if (
      props.showNameInput &&
      (_.isEmpty(prevName) || prevName.trim().length === 0)
    ) {
      setErrorName(true);
      setComponentState('ERROR');
      setErrorMessage('Enter a valid name');
      return;
    } else {
      setErrorNumber(false);
      setErrorMail(false);
      setErrorMessage('');
      setComponentState('LOADING');
      setPrevPhoneNumber('');
      closeModal();
      setSuccessPopupOpen(true);

      const payload = {
        couponCode: data.CouponCode,
        email: prevEmail,
        name: prevName,
        phone: prevPhoneNumber,
        leadSource,
      };
      try {
        await new ZohoServiceImpl().createPreLead(payload);
        ShowOfferPopupFlag();
      } catch (error) {
        setErrorMessage('something_went_wrong');
        setComponentState('ERROR');
      }
    }
  };

  return (
    <Modal
      onClose={close}
      isOpen={isOpen}
      overrides={{
        Dialog: {
          style: {
            borderRadius: theme.sizing.scale800,
            backgroundColor: theme.colors.white,
            [theme.mediaQuery.large]: {
              position: 'fixed',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }
          },
        },
        Root: {
          style: {
            zIndex: 999,
          }
        },
        Close: {
          style: ({ $theme }) => ({
            outline: `${$theme.colors.warning200} solid`,
            backgroundColor: $theme.colors.warning200,
            display: 'none',
          }),
        },
      }}
    >
      <Block display={'flex'} flexDirection={'column'} alignItems={'center'}>
        <StaticImage
          src="../../images/discount_image.png"
          alt="404-img"
          placeholder="none"
          className={css({height: '100%', width: '100%'})}
        />
        <CustomCloseIcon onClick={close} />
        <Block marginTop={'scale600'}>
          <StaticImage
            src="../../images/$1000.png"
            alt="404-img"
            placeholder="none"
            height={90}
            width={320}
          />
        </Block>
        <HeadingLarge
          color={theme.colors.mono600}
          marginTop={'scale200'}
          marginBottom={'scale500'}
          overrides={{
            Block: {
              style: {
                fontFamily: 'Bitter',
                color: 'rgba(18, 18, 18, 0.75)',
                alignItem: 'center',
              },
            },
          }}
        >
          {data.Heading}
        </HeadingLarge>
        <Block
          display={'flex'}
          flexDirection={'column'}
          marginLeft={'scale900'}
          marginRight={'scale900'}
          className={css({ textAlign: 'center' })}
        >
          {description1}
        </Block>
        <Block marginTop={['scale500', 'scale500', 'scale500']} width={'80%'}>
          {props.showNameInput && (
            <InputComponent
              placeholder="Your Name *"
              type="string"
              name="name"
              onChange={handleName}
              value={prevName}
              error={errorName}
            />
          )}
          {props.showPhoneInput && (
            <InputComponent
              onChange={handleMobile}
              placeholder="Confirm Phone Number"
              type="tel"
              name="mobile"
              error={errorNumber}
              value={prevPhoneNumber}
            />
          )}
          {props.showEmailInput && (
            <InputComponent
              onChange={handleEmail}
              placeholder={'Your Email'}
              type="email"
              name="email"
              error={errorMail}
              value={prevEmail}
            />
          )}
        </Block>
        <Block position="relative" marginBottom="1rem">
          <ErrorMessage
            display={componentState === 'DEFAULT' ? false : true}
            errorMessage={errorMessage}
            top="0.5rem"
            bottom="0"
          />
        </Block>
        <ButtonGradient text={data.Button.Text} handleSubmit={handleSubmit}/>
        <Block display={['none', 'none', 'block']}>
          <ParagraphMedium
            className={css({
              textAlign: 'center',
              color: theme.colors.primary400,
            })}
          >
            {description2}
          </ParagraphMedium>
        </Block>

        <Block display={['block', 'block', 'none']}>
          <ParagraphXSmall
            className={css({
              textAlign: 'center',
              color: theme.colors.primary400,
            })}
          >
            {description2}
          </ParagraphXSmall>
        </Block>
      </Block>
    </Modal>
  );
};

export default OfferPopup;
