import { useStyletron } from 'baseui';
import { Block } from 'baseui/block';
import { LabelMedium } from 'baseui/typography';
import React from 'react';
import { HiArrowUpRight } from '../../../icons/hi-arrow-up-right';
import { Leader as LeaderType } from '../../../../types/about-us';
import { getMediaUrl } from '../../../../utils/get-media-url';
import CardDetails from './card-details';

declare type LeaderProps = {
  leader: LeaderType;
  handleClose: (isOpen: boolean) => void;
  handleCardSelect: (currLeader: LeaderType) => void;
  isOpen: boolean;
};

const Leader: React.FC<LeaderProps> = ({
  handleClose,
  handleCardSelect,
  leader,
  isOpen,
}) => {
  const [css, theme] = useStyletron();
  
  return (
    <Block
      className={css({
        ':hover': {
          backgroundColor: theme.colors.accent100,
          borderRadius: theme.sizing.scale600,
        },
      })}
      paddingBottom="scale200"
    >
      <CardDetails
        url={getMediaUrl(leader.Photo.data.attributes.url)}
        name={leader.Name}
        designation={leader.Designation}
        location={leader.Location}
        isLeader={true}
      />

      <Block
        onClick={() => {
          handleClose(!isOpen);
          handleCardSelect(leader);
        }}
        marginTop="0"
      >
        <Block
          display={'flex'}
          flexDirection="row"
          alignItems="center"
          className={css({
            opacity: isOpen ? 0 : 1,
          })}
          marginLeft="scale650"
          marginTop={'scale200'}
        >
          <LabelMedium color={theme.colors.accent}> ReadMore</LabelMedium>
          <HiArrowUpRight
            className={css({ marginLeft: theme.sizing.scale200 })}
            color={theme.colors.accent}
          />
        </Block>
      </Block>
    </Block>
  );
};

export default Leader;
