import { Block } from 'baseui/block';
import { HeadingXSmall, ParagraphSmall } from 'baseui/typography';
import React from 'react';
import theme from '../../page-layouts/theme';
import { formatCountDown } from '../../utils/timer-utils';

interface CountDownBlockProps {
  label: string;
  value: number;
}

const circleStyle = {
  display: 'flex',
  margin: theme.sizing.scale400,
  fontVariantNumeric: 'lining-nums proportional-nums',
};

const CountDownBlock: React.FC<CountDownBlockProps> = ({ label, value }) => {
  return (
    <Block style={circleStyle}>
      <Block
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        flexDirection={'column'}
      >
        <HeadingXSmall color={theme.colors.accent} margin={'auto'}>
          {formatCountDown(value)}
        </HeadingXSmall>
        <ParagraphSmall margin={'auto'}>{label}</ParagraphSmall>
      </Block>
      <span
        color={theme.colors.accent}
        style={{
          fontSize: '20px',
          color: theme.colors.accent,
          marginLeft: '10px',
        }}
      >
        :
      </span>
    </Block>
  );
};

export default CountDownBlock;
