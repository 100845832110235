import { Block } from 'baseui/block';
import React from 'react';
import { Illustration } from '../../../types/referral';
import { getMediaUrl } from '../../../utils/get-media-url';
import Imagecard from './image-card';

declare type ImageSectionProps = {
  data: Illustration[];
};

const ImageSection: React.FC<ImageSectionProps> = ({ data }) => {
  return (
    <Block
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      {data.map(item => (
        <Block
          key={item.Background.data.id}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Imagecard url={getMediaUrl(item.Background.data.attributes.url)} />
        </Block>
      ))}
    </Block>
  );
};

export default ImageSection;
