import { Block } from 'baseui/block';
import React from 'react';
import { useStyletron } from 'baseui';
import { HeadingSmall } from 'baseui/typography';
import theme from '../../page-layouts/theme';
import { Animation } from '../../types/homepage';
import { getMediaUrl } from '../../utils/get-media-url';

export type VideoAnimationDesktopProps = {
  data: Animation;
  key: string;
};

export const VideoAnimationDesktop: React.FC<VideoAnimationDesktopProps> = ({
  data,
  key,
}) => {
  const videoUrl = getMediaUrl(data.Media.data.attributes.url);
  const [css] = useStyletron();

  return (
    <Block
      key={key}
      position={'relative'}
      height={'100%'}
      display={['none', 'none', 'none', 'block']}
    >
      <Block
        width={'100%'}
        height={'100%'}
        position={'relative'}
        overflow={'hidden'}
      >
        <video
          autoPlay
          muted
          loop
          className={css({
            minWidth: '100%',
            minHeight: '100%',
          })}
        >
          <source src={videoUrl} type="video/mp4" />
        </video>
      </Block>
      <Block
        position={'absolute'}
        width="100%"
        className={css({
          textAlign: 'center',
          textDecorationColor: theme.colors.contentAccent,
          textDecoration: 'underline',
          textUnderlinePosition: 'under',
          textUnderlineOffset: '8px',
        })}
        top="0"
      >
        <HeadingSmall color={theme.colors.primary}>{data.Heading}</HeadingSmall>
      </Block>
    </Block>
  );
};
