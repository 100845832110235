import React from 'react';
import { KeyFeature } from '../../types/solar-tech';
import CategoryFeature from './category-feature';

export type CategoryDataProps = {
  buttonText: string;
  buttonLink: string;
  description: string;
  feature: KeyFeature[];
  heading: string;
  image: string;
  title: string;
};

export const CategoryData: React.FC<CategoryDataProps> = ({
  buttonText,
  buttonLink,
  description,
  image,
  title,
  heading,
  feature,
}) => {
  const CategoryFeatureData = {
    buttonText,
    buttonLink,
    description,
    heading,
    title,
    feature,
    image,
  };

  return (
    <>
      <CategoryFeature data={CategoryFeatureData} />
    </>
  );
};
