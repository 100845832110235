import { Block } from 'baseui/block';
import React, { useEffect, useState } from 'react';
import PageLayout from '../page-layouts';
import { Homepage } from '../types/homepage';
import NavBar from '../components/nav-bar/nav-bar';
import FooterSection from '../components/footer';
import theme from '../page-layouts/theme';
import { HomePageServiceImpl } from '../services/page/home-page.service';
import Error from '../components/404-page';

declare type IndexPageProps = {
  serverData: any;
};

const ErrorPage: React.FC<IndexPageProps> = () => {
  const [homepage, setHomepage] = useState<Homepage | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const homePageServiceObject = new HomePageServiceImpl();
        const res = await homePageServiceObject.getHomePage();
        setHomepage(res.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  if (!homepage) {
    return (
      <Block
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        minHeight={['100vh', '100vh', '70vh', '90vh']}
      >
        Loading...
      </Block>
    );
  }

  return (
    <PageLayout>
      <Block
        backgroundColor={theme.colors.backgroundPrimary}
        width="100%"
        overflow={'hidden'}
      >
        <NavBar data={homepage.data.attributes.Header} position="fixed" />
        <Error />
        <FooterSection data={homepage.data.attributes.Footer} />
      </Block>
    </PageLayout>
  );
};

export default ErrorPage;
