import { useStyletron } from 'baseui';
import { BlockProps } from 'baseui/block';
import { MonoHeadingXLarge } from 'baseui/typography';
import React from 'react';

const MonoHeadingXlargeMobile: React.FC<React.PropsWithChildren<
  BlockProps & {
    className?: string;
  }
>> = ({ children, className, ...props }) => {
  const [css] = useStyletron();
  return (
    <MonoHeadingXLarge
      {...props}
      className={css({
        fontSize: '24px !important',
        fontWeight: 400,
        fontFamily: 'Raleway !important',
        lineHeight: '36px',
      })}
    >
      {children}
    </MonoHeadingXLarge>
  );
};

export default MonoHeadingXlargeMobile;
