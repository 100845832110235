import React from 'react';
import { IconBaseProps } from './icon-base-props';

export const BiChevronDown: React.FC<IconBaseProps> = ({ color = '#fff', size="24px", stroke = '#fff', ...props}) => {
  return (
    <svg
      stroke={stroke}
      fill={color}
      strokeWidth="0"
      viewBox="0 0 24 24"
      aria-hidden="true"
      height={size}
      width={size}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M16.293 9.293 12 13.586 7.707 9.293l-1.414 1.414L12 16.414l5.707-5.707z"></path>
    </svg>
  );
};

export default BiChevronDown;
