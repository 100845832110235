import { Block } from 'baseui/block';
import React from 'react';
import { SEO } from '../components';
import GoSolar from '../components/about-us/go-solar/go-solar';
import OurPartners from '../components/about-us/partners/our-partners';
import OurTeam from '../components/about-us/our-team/our-team';
import Story from '../components/about-us/story/story';
import WhySunnyEnergy from '../components/about-us/why-sunny-energy/why-sunny-energy';
import AwardsLogoCarousel from '../components/awards-logo-carousel/awards-logo-carousel';
import FooterSection from '../components/footer';
import NavBar from '../components/nav-bar/nav-bar';
import PageLayout from '../page-layouts';
import theme from '../page-layouts/theme';
import { AboutUsPageServiceImpl } from '../services/page/about-us-page.service';
import { HomePageServiceImpl } from '../services/page/home-page.service';
import { AboutUsPage } from '../types/about-us';
import { Homepage } from '../types/homepage';
import { CommonBanner } from '../components/banner/common-banner/common-banner';
import ToolFeatures from '../components/tool-features/tool-features';
import Quotation from '../components/projects/quotation';

declare type IndexPageProps = {
  serverData: any;
};

const AboutUs: React.FC<IndexPageProps> = ({ serverData }) => {
  const aboutUsPage = serverData.aboutUsPageData as AboutUsPage;
  const homePage = serverData.homePageData as Homepage;

  return (
    <PageLayout>
      {aboutUsPage.data.attributes.seo && <SEO data={aboutUsPage.data.attributes.seo} />}
      <NavBar data={homePage.data.attributes.Header} position={'fixed'} />
      <CommonBanner banner={aboutUsPage.data.attributes.Banner} />
      <Block
        backgroundColor={theme.colors.backgroundPrimary}
        width="100%"
        overflow="hidden"
      >
        <Story data={aboutUsPage.data.attributes.Story} />
        <WhySunnyEnergy
          h1={aboutUsPage.data.attributes.WhySunnyEnergy.H1}
          description={aboutUsPage.data.attributes.WhySunnyEnergy.Description}
          link={aboutUsPage.data.attributes.WhySunnyEnergy.Link}
          video={aboutUsPage.data.attributes.WhySunnyEnergy.Video}
        />
        <Block
          marginLeft={['0', '0', '0', 'scale2400']}
          marginRight={['0', '0', '0', 'scale2400']}
          marginBottom={['scale1200']}
        >
          <ToolFeatures data={aboutUsPage.data.attributes.Pillars} />
        </Block>
        <AwardsLogoCarousel data={homePage.data.attributes.Awards} />
        <OurTeam ourTeam={aboutUsPage.data.attributes.Team} />
        <OurPartners
          h1={aboutUsPage.data.attributes.Partners.H1}
          description={aboutUsPage.data.attributes.Partners.Description}
          logos={aboutUsPage.data.attributes.Partners.Partner}
        />
        <GoSolar
          h1={aboutUsPage.data.attributes.GoSolar.H1}
          description={aboutUsPage.data.attributes.GoSolar.Description}
          h2={aboutUsPage.data.attributes.GoSolar.H2}
        />
        <Quotation data={homePage.data.attributes.Quote} />
        <FooterSection data={homePage.data.attributes.Footer} />
      </Block>
    </PageLayout>
  );
};
export const getServerData = async () => {
  const aboutUsPageserviceObject = new AboutUsPageServiceImpl();
  const homePageServiceObject = new HomePageServiceImpl();

  const res = await Promise.all([
    aboutUsPageserviceObject.getAboutUs(),
    homePageServiceObject.getHomePage(),
  ]);

  return {
    props: {
      aboutUsPageData: res[0].data,
      homePageData: res[1].data,
    },
  };
};
export default AboutUs;

