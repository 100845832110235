import { useStyletron } from 'baseui';
import { BlockProps } from 'baseui/block';
import { MonoHeadingXLarge } from 'baseui/typography';
import React from 'react';

const BitterMonoHeadingXLarge: React.FC<React.PropsWithChildren<
  BlockProps & {
    className?: string;
  }
>> = ({ children, className, ...props }) => {
  const [css] = useStyletron();
  return (
    <MonoHeadingXLarge
      {...props}
      className={`${className} ${css({
        fontSize: '40px  !important',
        fontWeight: 600,
        fontFamily: 'Bitter !important',
      })}`}
    >
      {children}
    </MonoHeadingXLarge>
  );
};

export default BitterMonoHeadingXLarge;
