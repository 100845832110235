import { BlogsByCategory, News } from '../types/blog';

export const getBogsByCategory = (data: News[]) => {
  let blogsBycategory: BlogsByCategory[] = [];

  data.forEach(blog => {
    let currCategories: string[] = [];
    if (!blog.attributes.Category) blog.attributes.Category = 'ALL';
    if (blog.attributes.Category.includes(',')) {
      currCategories = blog.attributes.Category.split(',');
    } else {
      currCategories = [...currCategories, blog.attributes.Category];
    }

    currCategories.forEach(currCategory => {
      let index = blogsBycategory.findIndex(item => {
        return (
          item.Category &&
          item.Category.toLocaleLowerCase() === currCategory.toLocaleLowerCase()
        );
      });
      if (index !== -1) {
        blogsBycategory[index].Blogs.push(blog.attributes);
      } else {
        blogsBycategory.push({
          Blogs: [blog.attributes],
          Category: currCategory,
        });
      }
    });
  });

  const allIndex = blogsBycategory.findIndex(item => item.Category === 'ALL');

  if (allIndex !== -1) {
    blogsBycategory.forEach(item => {
      if (item.Category !== 'ALL') {
        let allBlogs = item.Blogs.map(item => item);
        allBlogs.forEach(item => {
          blogsBycategory[allIndex].Blogs.push(item);
        });
      }
    });
  } else {
    blogsBycategory.push({
      Blogs: data.map(item => item.attributes),
      Category: 'ALL',
    });
  }

  blogsBycategory.forEach(blogByIndvCategory => {
    blogByIndvCategory.Blogs.sort((a, b) => {
      return a.PublishedOn < b.PublishedOn
        ? 1
        : b.PublishedOn < a.PublishedOn
        ? -1
        : 0;
    });
  });

  blogsBycategory = blogsBycategory.sort((a, b) => {
    return a.Category < b.Category ? 1 : b.Category < a.Category ? -1 : 0;
  });

  return blogsBycategory;
};
