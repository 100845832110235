import React from 'react';
import { SEO } from '../components';
import FooterSection from '../components/footer';
import NavBar from '../components/nav-bar/nav-bar';
import ThankYouPageContent from '../components/thank-you';
import PageLayout from '../page-layouts';
import { HomePageServiceImpl } from '../services/page/home-page.service';
import { ThankYouPageImpl } from '../services/page/thank-you-page.service';
import { Homepage } from '../types/homepage';
import { ThankYouPage } from '../types/thank-you';

declare type ThankYouReferralPageProps = {
  serverData: any;
};

const ThankYouReferralPage: React.FC<ThankYouReferralPageProps> = ({
  serverData,
}) => {
  const homepage = serverData.homePageData as Homepage;
  const combinedData: ThankYouPage[] = serverData.thankYouCombinedData.data;
  const thankYouReferralPage = combinedData.find(
    item => item.url.includes('thank-you-referral') && item.data,
  );

  return (
    <>
      {thankYouReferralPage && (
        <PageLayout>
          {thankYouReferralPage.data.attributes.seo && <SEO data={thankYouReferralPage.data.attributes.seo} />}
          <NavBar data={homepage.data.attributes.Header} position="fixed" />
          <ThankYouPageContent
            data={thankYouReferralPage.data.attributes.Content}
          />
          <FooterSection data={homepage.data.attributes.Footer} />
        </PageLayout>
      )}
    </>
  );
};

export default ThankYouReferralPage;

export const getServerData = async () => {
  const homeServiceObject = new HomePageServiceImpl();
  const thankYouPage = new ThankYouPageImpl();

  const res = await Promise.all([
    thankYouPage.getThankYouPageData(),
    homeServiceObject.getCommonData(),
  ]);
  return {
    props: {
      thankYouCombinedData: res[0],
      homePageData: res[1].data,
    },
  };
};
