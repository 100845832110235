import { useStyletron } from 'baseui';
import { Block } from 'baseui/block';
import { ParagraphMedium } from 'baseui/typography';
import React from 'react';
import { Card, StyledBody, StyledAction } from 'baseui/card';
import { getMediaUrl } from '../../utils/get-media-url';
import Markdown from '../mark-down/mark-down';
import { Card as CardType } from '../../types/solar-tech';
import PrimaryLink from '../link/primary-link';
import { useWindow } from '../../context/use-window';

export type CardDataProps = {
  data: CardType;
};

const CategoryCard: React.FC<CardDataProps> = ({ data }) => {
  const [css, theme] = useStyletron();
  const { deviceType } = useWindow();

  return (
    <Card
      headerImage={getMediaUrl(data.Picture.data.attributes.url)}
      title="Example card"
      overrides={{
        HeaderImage: {
          style: () => ({
            height: '300px',
            objectFit: 'contain',
            AspectRatioBox: 4 / 3,
            width: '100%',
            background:
              deviceType === 'desktop'
                ? 'linear-gradient(to bottom, #f5a71c 50%,  #fac311 50%)'
                : 'linear-gradient(to bottom, #f5a71c 90%,  #fac311 10%)',
          }),
        },
        Root: {
          style: {
            width:
              deviceType === 'mobile'
                ? '100%'
                : deviceType === 'tablet'
                ? '350px'
                : '380px',
            height: '750px',
            border: 'none',
            backgroundColor: 'white',
            marginBottom: theme.sizing.scale2400,
            marginRight: '100px',
          },
        },
        Contents: {
          style: {
            height: '500px',
            width: '90%',
          },
        },
      }}
    >
      <StyledBody>
        <ParagraphMedium color={theme.colors.buttonDisabledText} height="40px">
          {data.H1.toUpperCase()}
        </ParagraphMedium>
        <Block
          height=" 200px"
          className={css({
            '::-webkit-scrollbar': {
              '-webkit-appearance': 'none',
              width: '4px',
            },
            overflowY: 'auto',
            '::-webkit-scrollbar-thumb': {
              borderRadius: '4px',
              backgroundColor: 'rgba(0, 0, 0, .5)',
              boxShadow: '0 0 1px rgba(255, 255, 255, .5)',
            },
          })}
        >
          <ParagraphMedium color={theme.colors.contentTertiary}>
            <Markdown
              data={data.Discription}
              skipHtml={false}
              color={theme.colors.accent200}
            />
          </ParagraphMedium>
        </Block>
      </StyledBody>
      <StyledAction>
        <Block
          width="100%"
          display="flex"
          justifyContent={data.LinkUrlSecond ? "space-between" : 'center'}
          alignItems="center"
        >
          {data.LinkUrlSecond ? (
            <>
            <Block marginRight={'scale400'}>
            <PrimaryLink
            target="_blank"
            label={data.LinkLabel}
            download={true}
            link={data.LinkUrl}
            />
            </Block>
            <PrimaryLink
            target="_blank"
            label={data.LinkLabelSecond || ''}
            download={true}
            link={data.LinkUrlSecond}
            />
            </>
          ): (
            <PrimaryLink
            target="_blank"
            label={data.LinkLabel}
            download={true}
            link={data.LinkUrl}
            />
          )
          }
        </Block>
      </StyledAction>
    </Card>
  );
};

export default CategoryCard;

