import React from 'react'
import DesktopView from './desktop-view'
import MobileView from './mobile-view'
import { Block } from 'baseui/block'

const BannerQuote = () => {
  return (
   <Block display={'flex'} justifyContent={'center'}>
    <DesktopView />
    <MobileView />
   </Block>
  )
}

export default BannerQuote
