import React, { Dispatch } from 'react';
import { Location } from '../../../types/homepage';
import { Block } from 'baseui/block';
import { LabelMedium } from 'baseui/typography';
import theme from '../../../page-layouts/theme';
import { Tag, VARIANT } from 'baseui/tag';

type LocationsUtilityCompaniesProps = {
  location?: Location;
  setLocation: Dispatch<Location>;
  list: Location[];
  title: string;
  isMobile?: boolean;
};

const LocationsUtilityCompanies: React.FC<LocationsUtilityCompaniesProps> = ({
  title,
  location,
  setLocation,
  list,
  isMobile
}) => {
    console.log(isMobile)
  return (
    <>
      <Block display="flex" alignItems={'center'} marginTop={isMobile ? '0px': 'scale600'}>
        <LabelMedium color={theme.colors.black} marginRight={'scale400'}>{title}</LabelMedium>
        <Block
          margin={'scale0'}
          backgroundColor={theme.colors.primary}
          flex={'auto'}
          height={'scale0'}
        ></Block>
      </Block>
      <Block marginBottom={'scale600'} alignItems={'center'} paddingRight={'scale100'} display={'flex'} flexWrap>
        {list.map(l => (
          <Tag
            overrides={{
              Root: {
                style: {
                  width: 'auto',
                  justifyContent: 'center',
                  height: theme.sizing.scale900,
                  backgroundColor:
                    location?.utilityCompanyId === l.utilityCompanyId
                      ? theme.colors.accent
                      : theme.colors.backgroundPrimary,
                  borderRadius: theme.sizing.scale100,
                },
              },
            }}
            closeable={false}
            onClick={() => {
              setLocation(l);
            }}
            variant={VARIANT.solid}
          >
            {l.utilityCompany}
          </Tag>
        ))}
      </Block>
    </>
  );
};

export default LocationsUtilityCompanies;
