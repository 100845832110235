import theme from '../../../../page-layouts/theme';

export const popoverStyles = {
  backgroundColor: theme.colors.accent100,
  borderTopLeftRadius: theme.sizing.scale650,
  borderTopRightRadius: theme.sizing.scale650,
  borderBottomRightRadius: theme.sizing.scale650,
  borderBottomLeftRadius: theme.sizing.scale650,
};

export const closeButtonStyles = {
  marginRight: theme.sizing.scale650,
  marginTop: theme.sizing.scale400,
  color: theme.colors.accent,
  fontSize: theme.sizing.scale1000,
};
