import { Block } from 'baseui/block';
import React from 'react';
import Headings from '../headings';

export declare type GoSolarProps = {
  description: string;
  h1: string;
  h2: string;
};

const GoSolar: React.FC<GoSolarProps> = ({ description, h1, h2 }) => {
  return (
    <Block
      paddingTop="scale1200"
      paddingBottom="scale1200"
      paddingLeft={['scale800', 'scale1600', 'scale2400', 'scale4800']}
      paddingRight={['scale800', 'scale1600', 'scale2400', 'scale4800']}
    >
      <Headings description={description} h1={h1} h2={h2} />
    </Block>
  );
};

export default GoSolar;

