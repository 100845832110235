import { useStyletron } from 'baseui';
import { Block } from 'baseui/block';
import { HeadingSmall, LabelSmall } from 'baseui/typography';
import React from 'react';
import { getFormattedDate } from '../../../utils/date-util';
import CommonBannerH1 from '../../headings/common-banner-h1';
import CommonBannerH2 from '../../headings/common-banner-h2';

declare type HeadingsProps = {
  mainHeading: string;
  publishedOn: Date;
  secondaryHeading: string;
};

const Headings: React.FC<HeadingsProps> = ({
  mainHeading,
  publishedOn,
  secondaryHeading,
}) => {
  const [css, theme] = useStyletron();
  return (
    <>
      <Block
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        backgroundColor={theme.colors.backgroundPrimary}
        height="40vh"
        width="100%"
        paddingTop="scale2400"
      >
        <CommonBannerH1
          color={theme.colors.contentAccent}
          className={css({
            textAlign: 'center',
          })}
        >
          {mainHeading?.toUpperCase()}
        </CommonBannerH1>
        <CommonBannerH2
          className={css({
            textAlign: 'center',
            maxWidth: '70%',
          })}
        >
          {secondaryHeading}
        </CommonBannerH2>
        <HeadingSmall
          display={['none', 'none', 'none', 'block']}
          className={css({
            fontFeatureSettings: `'pnum' on, 'lnum' on`,
          })}
        >
          {`Published ${getFormattedDate(publishedOn)}`}
        </HeadingSmall>
        <LabelSmall
          display={['block', 'block', 'block', 'none']}
          className={css({
            fontFeatureSettings: `'pnum' on, 'lnum' on`,
          })}
        >{`Published ${getFormattedDate(publishedOn)}`}</LabelSmall>
      </Block>
    </>
  );
};

export default Headings;
